import React, { forwardRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { SnackbarContent, useSnackbar } from 'notistack'
import classNames from 'classnames'

import CloseSnackbarIcon from 'components/Icons/CloseSnackbarIcon'
import useConfirmation from 'hooks/useConfirmation'

const useStyles = makeStyles(() => ({
  root: ({ background }) => ({
    color: '#fff',
    backgroundColor: background,
    fontSize: '0.875rem',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    fontWeight: 400,
    lineHeight: 1.43,
    borderRadius: 4,
    display: 'flex',
    flexWrap: 'nowrap'
  }),
  iconWrapper: {
    width: 24,
    height: 24,
    display: 'flex',
    marginRight: 16,

    '& svg': {
      width: '24px !important',
      height: '24px !important',
      marginRight: '9px !important',
      alignSelf: 'flex-start'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  hasAction: {
    gap: 10,
    flexDirection: 'column'
  },
  messageWrapper: {
    alignItems: 'center',
    display: 'flex'
  },
  closeBtnWrapper: {
    marginLeft: 'auto'
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  warningAction: {
    '& button': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    }
  }
}))

const colors = {
  success: '#43a047',
  warning: '#ff9800',
  error: '#d32f2f',
  default: 'rgb(49, 49, 50)'
}

const getBackgroundColor = variant => {
  switch (variant) {
    case 'success':
      return colors.success
    case 'warning':
      return colors.warning
    case 'error':
      return colors.error
    default:
      return colors.default
  }
}

const NotificationWithCloseBtn = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar()
  const { closeConfirmation } = useConfirmation()

  const { message, style, variant, iconVariant, action } = props

  const classes = useStyles({
    background: getBackgroundColor(variant)
  })

  const handleDismiss = useCallback(() => {
    closeSnackbar(id)
    closeConfirmation(id)
  }, [id, closeSnackbar, closeConfirmation])

  return (
    <SnackbarContent ref={ref} style={style} className={classes.root}>
      <div className={classes.iconWrapper}>{iconVariant[variant]}</div>

      <div
        className={classNames(classes.content, {
          [classes.hasAction]: !!action
        })}
      >
        <div className={classes.messageWrapper}>{message}</div>

        {action && (
          <div
            className={classNames(classes.actionWrapper, {
              [classes.warningAction]: variant === 'warning'
            })}
          >
            {action()}
          </div>
        )}
      </div>

      <div className={classes.closeBtnWrapper}>
        <CloseSnackbarIcon onClick={handleDismiss} />
      </div>
    </SnackbarContent>
  )
})

export default NotificationWithCloseBtn
