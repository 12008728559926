import { call, put } from '@redux-saga/core/effects'
import { contentAppHelpService } from '../services'
import * as types from '../actions'
import { transformMeta } from 'utils/tableUtils'
import apiTags from 'constants/apiTags'
import { contentAppHelpPagesApi } from 'api/contentAppHelpPagesApi'

function* getHelps({ params }) {
  try {
    const { meta, data } = yield call(contentAppHelpService.getHelps, params)

    //Set the end of the download
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_CONTENT_APP_HELPS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_CONTENT_APP_HELPS_ERROR, payload: error })
  }
}

function* getHelp({ featureId }) {
  try {
    const response = yield call(contentAppHelpService.getHelp, featureId)
    yield put({ type: types.GET_CONTENT_APP_HELP_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_CONTENT_APP_HELP_ERROR, payload: error })
  }
}

function* putHelp({ id, data }) {
  try {
    yield call(contentAppHelpService.putHelp, id, data)
    yield put({
      type: types.PUT_CONTENT_APP_HELP_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_CONTENT_APP_HELP_ERROR, payload: error })
  }
}

function* invalidateContentAppHelpTags() {
  yield put(
    contentAppHelpPagesApi.util.invalidateTags([apiTags.contentAppHelpPages])
  )
}

const contentAppHelpSaga = {
  getHelps,
  getHelp,
  putHelp,
  invalidateContentAppHelpTags
}

export default contentAppHelpSaga
