import { colors } from 'theme'

export const scheduleTypes = {
  Timed: {
    color: colors.scheduleAccent,
    iconHelperClass: 'fa-regular fa-calendar-clock',
    title: 'Timed Schedule',
    name: 'Timed'
  },
  Failover: {
    color: 'rgb(253, 73, 70)',
    iconHelperClass: 'fa-regular fa-calendar-star',
    title: 'Failover Schedule',
    name: 'Failover'
  },
  SmartSchedule: {
    color: 'rgba(76, 175, 80, 1)',
    iconHelperClass: 'fa-regular fa-calendar-range',
    title: 'Smart Schedule',
    name: 'SmartSchedule'
  }
}

export const SCHEDULE_MIN_TIME = '00:00:00'
export const SCHEDULE_MAX_TIME = '23:59:59'
export const SCHEDULE_DEFAULT_END_DATE = null
export const NULL_DATE_BE_STRING = '0000-00-00'
export const NULL_DATE_FE_STRING = null
export const SPECIFIC_DATE_FORMAT = 'YYYY-MM-DD'

export const scheduleAssignTypes = {
  DEVICE: 'device',
  TAG: 'tag',
  GROUP: 'group',
  LOCATION: 'location'
}

export const recurrenceTypes = {
  custom: 'custom',
  none: 'none',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
  weekday: 'weekday',
  weekend: 'weekend'
}
export const workingDays = {
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun'
}

export const allWorkingDays = [
  workingDays.Mon,
  workingDays.Tue,
  workingDays.Wed,
  workingDays.Thu,
  workingDays.Fri,
  workingDays.Sat,
  workingDays.Sun
]

export const recurrenceIntervals = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year'
}

export const recurrenceMonthlyIntervals = {
  day: 'day',
  week: 'week'
}

export const recurrenceEndTypes = {
  never: 'never',
  on: 'on',
  after: 'after'
}

export const scheduleFields = {
  workingDays: 'workingDays',
  startDate: 'startDate',
  endDate: 'endDate'
}

export const recurrenceTabs = {
  recurring: 'Recurring',
  specific: 'Specific'
}

export const playbackContentType = {
  media: 'Media',
  playlist: 'Playlist',
  template: 'Template'
}

export const publishTabType = {
  media: 'media',
  playlist: 'playlist',
  template: 'template'
}
