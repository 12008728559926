import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef
} from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import { _debounce, _uniqBy, _differenceWith } from 'utils/lodash'

import FormControlReactSelect from './FormControlReactSelect'
import { simulateEvent } from 'utils/formik'
import { sortByLabel } from 'utils/libraryUtils'
import useLazyLoad from 'hooks/useLazyLoad'
import { transformDataToOptions } from 'utils/transformToOptionsUtils'

const ClearIndicator = ({ onClick, innerValue, innerProps, ...props }) => {
  return (
    <>
      {innerValue && (
        <components.ClearIndicator
          innerProps={{
            ...innerProps,
            onMouseDown: onClick,
            onTouchEnd: onClick
          }}
          {...props}
        />
      )}
    </>
  )
}

const styles = ({ colors }) => ({
  errorText: {
    margin: 0,
    marginTop: -15,
    color: colors.error,
    fontSize: 10,
    lineHeight: 1.5
  }
})

const FormControlAutocompleteNew = forwardRef(
  (
    {
      useLazyQuery,
      responseParser: responseParserProp,
      searchField = 'name',
      initialSearchField = 'id',
      sort = 'name',
      order = 'asc',
      validationFunc = () => true,
      validationErrorMessage = '',
      limit = 10,
      withResetValue = false,
      selectComponent: SelectComponent = FormControlReactSelect,
      isSearchable = true,
      isResettable = false,
      value,
      components,
      role = '',
      initialResponse,
      initialFetchValue,
      classes,
      name,
      onChange,
      uniqueOptions = false,
      isClearable = false,
      isSort = false,
      staticRequestParams = {},
      onFocus,
      createdValue,
      hideOptions = [],
      hideOptionsStrict = [],
      optionsDependency,
      setResponseData,
      staticOptions = [],
      keepValueOnBlur = false,
      keepValueOnSelect = false,
      placeholder,
      isLoading,
      staticOptionsUniqueByPath = 'value',
      setResetSearchCb,
      ...props
    },
    ref
  ) => {
    const [innerValue, setInnerValue] = useState('')
    const [lastValue, setLastValue] = useState('')
    const [validationError, setValidationError] = useState(false)

    const [getItems, { data: queryData, isFetching, error }] = useLazyQuery(
      name
    )
    const [getInitialItems, { data: initialFetchData }] = useLazyQuery(
      `${name}_initial`
    )

    const responseParser = useMemo(
      () => responseParserProp || transformDataToOptions(searchField),
      [responseParserProp, searchField]
    )

    const response = useMemo(
      () =>
        responseParser(
          (Array.isArray(queryData) ? queryData : queryData?.data) ||
            initialResponse?.data ||
            []
        ),
      [queryData, initialResponse, responseParser]
    )
    const meta = queryData?.meta || initialResponse?.meta || {}
    const initialValueResponse = useMemo(
      () => responseParser(initialFetchData?.data || []),
      [initialFetchData, responseParser]
    )

    //TODO fix last value
    const fetchData = useCallback(
      (params = {}) => {
        const { value, ...restParams } = params

        if (!restParams.page || restParams.page === 1) {
          setLastValue(value)
        }
        const searchValue =
          value || (restParams?.page && restParams.page !== 1 && lastValue)

        getItems({
          sort,
          order,
          ...(searchValue && { [searchField]: searchValue }),
          ...restParams,
          limit,
          ...staticRequestParams
        })
      },
      [
        searchField,
        limit,
        staticRequestParams,
        sort,
        order,
        getItems,
        lastValue
      ]
    )

    const { data, handleLoadMore, setData } = useLazyLoad({
      isFetching,
      meta,
      response,
      fetcher: fetchData,
      initialFetch: false
    })

    const isLastPage = useMemo(() => meta.lastPage === meta.currentPage, [
      meta.lastPage,
      meta.currentPage
    ])

    useEffect(() => {
      if (data && setResponseData) {
        setResponseData(data)
      }
      //eslint-disable-next-line
    }, [data])

    const onInputChangeHandler = useMemo(
      () =>
        _debounce((value, { action }) => {
          if (!validationFunc(value || '')) {
            setValidationError(true)
          } else {
            setValidationError(false)
            if (action === 'input-change') {
              if (value === '') {
                setInnerValue('')
                fetchData()
              } else {
                setInnerValue(value)
                fetchData({ value })
              }
            }
          }
        }, 300),
      [fetchData, validationFunc]
    )

    const handleSearchReset = useCallback(() => {
      setInnerValue('')
      fetchData()
      if (withResetValue) {
        onChange(simulateEvent(props.name, ''))
      }
    }, [fetchData, onChange, props, withResetValue])

    useEffect(() => {
      if (setResetSearchCb) {
        setResetSearchCb(() => handleSearchReset)
      }
      // eslint-disable-next-line
    }, [])

    const handleChange = useCallback(
      e => {
        onChange(e)
      },
      [onChange]
    )

    const handleFocus = useCallback(
      e => {
        if (!data.length || innerValue) {
          if (innerValue) {
            setInnerValue('')
          }
          fetchData()
        }
        if (onFocus) {
          onFocus(e)
        }
      },
      [data, fetchData, onFocus, innerValue]
    )

    useEffect(
      () => {
        if (initialFetchValue) {
          getInitialItems({
            [initialSearchField]: initialFetchValue,
            exact: true,
            ...staticRequestParams
          })
        }
      },
      // eslint-disable-next-line
      [initialFetchValue]
    )

    useEffect(() => {
      if (createdValue && createdValue.data) {
        handleSearchReset()
      }
      // eslint-disable-next-line
    }, [createdValue])

    useEffect(() => {
      if (optionsDependency) {
        setData([])
      }
      // eslint-disable-next-line
    }, [optionsDependency])

    const options = useMemo(() => {
      const _options =
        initialValueResponse.length || uniqueOptions
          ? sortByLabel(
              _uniqBy(
                [...staticOptions, ...data, ...initialValueResponse],
                staticOptionsUniqueByPath
              )
            )
          : [...staticOptions, ...data]

      if (hideOptionsStrict?.length) {
        return _differenceWith(
          _options,
          hideOptionsStrict,
          (a, b) => a.value === b.value || a.label === b.label
        )
      } else {
        return _differenceWith(_options, hideOptions, (a, b) => a.value === b)
      }
    }, [
      data,
      initialValueResponse,
      uniqueOptions,
      hideOptions,
      hideOptionsStrict,
      staticOptions,
      staticOptionsUniqueByPath
    ])

    return (
      <>
        <SelectComponent
          ref={ref}
          {...props}
          placeholder={lastValue || placeholder}
          keepValueOnBlur={keepValueOnBlur}
          keepValueOnSelect={keepValueOnSelect}
          createdValue={createdValue}
          name={name}
          onFocus={handleFocus}
          onChange={handleChange}
          isLoading={isFetching || isLoading}
          handleInputChange={onInputChangeHandler}
          isSearchable={isSearchable}
          handleMenuScrollToBottom={handleLoadMore}
          options={options}
          error={validationError || error?.message || props.error}
          isClearable={isClearable}
          isSort={isSort}
          components={
            isResettable
              ? {
                  ...components,
                  ClearIndicator: props => (
                    <ClearIndicator
                      innerValue={innerValue}
                      onClick={handleSearchReset}
                      {...props}
                    />
                  )
                }
              : components
          }
          value={value}
          isLastPage={isLastPage}
        />
        {validationError && (
          <div className={classes.errorText}>{validationError}</div>
        )}
      </>
    )
  }
)

FormControlAutocompleteNew.propTypes = {
  useLazyQuery: PropTypes.func.isRequired,
  responseParser: PropTypes.func,
  searchField: PropTypes.string,
  initialSearchField: PropTypes.string,
  sort: PropTypes.string,
  order: PropTypes.string,
  initialResponse: PropTypes.shape({
    data: PropTypes.array,
    meta: PropTypes.object,
    error: PropTypes.string
  }),
  selectComponent: PropTypes.elementType,
  isSearchable: PropTypes.bool,
  isResettable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSort: PropTypes.bool,
  isCreatable: PropTypes.bool,
  withResetValue: PropTypes.bool,
  role: PropTypes.string,
  validationFunc: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  staticOptionsUniqueByPath: PropTypes.string,
  limit: PropTypes.number,
  initialFetchValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  uniqueOptions: PropTypes.bool,
  staticRequestParams: PropTypes.object,
  keepValueOnBlur: PropTypes.bool
}

export default withStyles(styles)(FormControlAutocompleteNew)
