import React, { memo, useCallback, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { _get } from 'utils/lodash'
import classNames from 'classnames'

import DeviceCardScheduleContent from './DeviceCardScheduleContent'
import Tooltip from 'components/Tooltip'
import Spacing from 'components/Containers/Spacing'
import DeviceCard from 'components/Card/DeviceCard'
import { TextWithTooltip } from 'components/Typography'
import MaterialPopup from 'components/Popup/MaterialPopup'
import useTimeout from 'hooks/useTimeout'
import { DARK } from 'theme'
import { deviceStatus } from 'constants/deviceConstants'

const styles = ({ palette, type, spacing }) => {
  return {
    rowWrapper: {
      position: 'relative',
      padding: spacing(1, 2, 1, 1),
      height: 60,
      borderRadius: '8px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette[type].formControls.input.border,
      cursor: 'pointer',
      transition: 'all 0.3s ease-out',
      boxShadow: palette[type].scheduleDeviceCard.boxShadow,
      background: palette[type].scheduleDeviceCard.background,
      '&:hover': {
        borderColor: palette[type].scheduleDeviceCard.hover.borderColor,
        '& $hoverElement': {
          opacity: 1
        },
        '& $statusCircle': {
          backgroundColor: '#3B86F8'
        },
        '& $image': {
          borderColor: '#3B86F8'
        }
      }
    },
    activeDeviceTextWrapper: {
      width: 'calc(100% - 18px)'
    },
    hoverElement: {
      content: "''",
      position: 'absolute',
      inset: 0,
      borderRadius: '8px',
      background: palette[type].scheduleDeviceCard.hover.background,
      opacity: 0,
      transition: 'all 0.3s ease-out'
    },
    cardWrapper: {
      width: 320
    },
    aliasText: {
      lineHeight: '17px',
      zIndex: 1,
      ...(type === DARK && { color: '#fff' })
    },
    titleText: {
      lineHeight: '15px',
      zIndex: 1,
      ...(type === DARK && { color: '#fff', opacity: 0.6 })
    },
    selected: {
      transition: 'all 0.3s ease-in-out',
      borderColor: palette[type].scheduleDeviceCard.selected.borderColor,
      background: palette[type].scheduleDeviceCard.selected.background,
      boxShadow: palette[type].scheduleDeviceCard.selected.boxShadow,
      '& $statusCircle': {
        backgroundColor: '#fff !important'
      },
      '& $image': {
        borderColor: '#1F7CE7 !important'
      }
    },
    disabled: {
      cursor: 'auto'
    },
    selectedAliasText: {
      color: '#fff',
      transition: 'all 0.3s ease-out'
    },
    selectedTitleText: {
      color: '#fff',
      opacity: 0.6,
      transition: 'all 0.3s ease-out'
    },
    image: {
      position: 'relative',
      zIndex: 1,
      height: 42,
      width: 42,
      objectFit: 'cover',
      borderRadius: '4px',
      border: `1px solid ${palette[type].formControls.input.border}`,
      marginRight: spacing(1.5),
      transition: 'all 0.3s ease-out'
    },
    imageError: {
      '&::after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        backgroundColor: '#FFF',
        ...(type === DARK && {
          backgroundColor: '#0D1E33'
        })
      }
    },
    statusCircle: {
      zIndex: 1,
      content: "''",
      width: 10,
      minWidth: 10,
      height: 10,
      background: palette[type].formControls.input.border,
      borderRadius: '50%',
      transition: 'all 0.3s ease-out',
      animation: '$deviceStatusPulse 5000ms infinite'
    },
    '@keyframes deviceStatusPulse': {
      '0%, 40%, 60%, 100%': {
        opacity: 1
      },
      '50%': {
        opacity: 0.25
      }
    },
    textWrapper: {
      maxWidth: 220,
      minWidth: 10
    }
  }
}

const ScheduleDeviceRow = ({
  item,
  checkboxValue,
  disabled,
  disabledReason,
  onChange,
  classes
}) => {
  const [setPopupTimeout, clearPopupTimeout] = useTimeout()
  const [popupHidden, setPopupHidden] = useState(true)
  const [imageError, setImageError] = useState(false)

  const handleChange = useCallback(() => {
    if (disabled) {
      return
    }
    onChange(item)
  }, [onChange, item, disabled])

  const handleDisplayPopup = useCallback(() => {
    setTimeout(() => {
      const popupContent = document.querySelector('.popup-content')
      const left = _get(popupContent, 'style.left', '0px')
      const leftValue = Number(left.replace('px', ''))
      const top = _get(popupContent, 'style.top', '0px')
      const topValue = Number(top.replace('px', ''))

      if (leftValue < 0 || leftValue > 40) {
        popupContent.style.left = '25px'
      }
      if (topValue < 0) {
        popupContent.style.top = '10px'
      }
    }, 5)
  }, [])

  const isActiveDevice = item.status === deviceStatus.active

  return (
    <Tooltip
      title={disabledReason || ''}
      disableHoverListener={!disabledReason}
      single
    >
      <div
        onMouseEnter={() => setPopupTimeout(() => setPopupHidden(false), 1000)}
        onMouseLeave={() => {
          clearPopupTimeout()
          setPopupHidden(true)
        }}
        onClick={handleChange}
      >
        <MaterialPopup
          style={popupHidden ? { opacity: 0, display: 'none' } : undefined}
          trigger={
            <Grid
              container
              className={classNames(classes.rowWrapper, {
                [classes.selected]: checkboxValue,
                [classes.disabled]: disabled
              })}
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <div
                className={classNames(classes.hoverElement, {
                  hidden: checkboxValue
                })}
              />
              <Grid
                item
                container
                className={classNames({
                  [classes.activeDeviceTextWrapper]: isActiveDevice
                })}
                wrap="nowrap"
              >
                <img
                  alt="location"
                  src={item.locationImage?.deviceLocationImage}
                  onError={() => setImageError(true)}
                  className={classNames(classes.image, {
                    [classes.imageError]:
                      !item.locationImage?.deviceLocationImage || imageError
                  })}
                />
                <Grid
                  container
                  item
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  className={classes.textWrapper}
                >
                  <TextWithTooltip
                    maxWidth="100%"
                    color="title.primary"
                    weight="bold"
                    rootClassName={classNames(classes.aliasText, {
                      [classes.selectedAliasText]: checkboxValue
                    })}
                  >
                    {item.alias}
                  </TextWithTooltip>
                  <TextWithTooltip
                    maxWidth="100%"
                    variant="smaller"
                    rootClassName={classNames(classes.titleText, {
                      [classes.selectedTitleText]: checkboxValue
                    })}
                  >
                    {item.name}
                  </TextWithTooltip>
                </Grid>
              </Grid>
              {isActiveDevice && <div className={classes.statusCircle} />}
            </Grid>
          }
          onOpen={handleDisplayPopup}
        >
          <div className={classes.cardWrapper}>
            {!popupHidden && (
              <DeviceCard
                device={item}
                withFooter={false}
                infoIconsPosition="top"
                variant="auto"
                disableHoverEffect
                bottomContentComponent={
                  <Spacing variant={0} paddingHor={2} paddingTop={2}>
                    <DeviceCardScheduleContent device={item} />
                  </Spacing>
                }
              />
            )}
          </div>
        </MaterialPopup>
      </div>
    </Tooltip>
  )
}

export default withStyles(styles)(memo(ScheduleDeviceRow))
