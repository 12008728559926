import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import menuMakerSaga from 'sagas/menuMakerSaga'

const actions = [types.POST_MENU_ITEM_SUCCESS, types.PUT_MENU_ITEM_SUCCESS]

const menuItemsInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, menuMakerSaga.invalidateMenuItemsTags)
  )

export default menuItemsInvalidateCache
