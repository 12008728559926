import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from '@reduxjs/toolkit'
import { withStyles, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { compose } from '@reduxjs/toolkit'
import { useSnackbar as _useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { _cloneDeep, _get, _isEmpty } from 'utils/lodash'

import { SideModal } from 'components/Modal'
import { permissionNames, routeByName } from 'constants/index'
import { getUrlPrefix, queryParamsHelper } from 'utils'
import { scheduleAssignTypes } from 'constants/schedule'
import DeviceSection from 'components/Pages/Schedule/SchedulePublish/AssignSection/DeviceSection'
import Actions from 'components/Pages/Schedule/SchedulePublish/Actions'
import AlarmAlertSection from './AlarmSections/AlarmAlertSection'
import AlertRuleSection from './AlarmSections/AlertRuleSection'
import SendAlertViaSection from './AlarmSections/SendAlertViaSection'
import {
  alarmConditionInitialValue,
  alarmDetailsInitialValue,
  alertAlarmValidationSchema,
  getRandomColor
} from 'utils/deviceNocUtils'
import {
  getDeviceAlarmItems,
  postDeviceAlarm,
  putDeviceAlarm,
  deleteDeviceAlarm,
  clearDeviceAlarmResponseInfo,
  getDeviceAlarmColumnList,
  getDeviceAlarmById
} from 'actions/deviceNocActions'
import { useNotifyAnalyzer } from 'hooks/tableLibrary'
import SaveDialog from './SaveDialog'
import useConfirmation from 'hooks/useConfirmation'
import { getDeleteConfirmationMessage } from 'utils/generalUtils'
import { alertAlarmViaType } from 'constants/deviceNoc'
import useFormErrorHandler from 'hooks/useFormErrorHandler'
import { formToTouched } from 'utils/formik'
import usePermissions from 'hooks/api/usePermissions'

const styles = () => {
  return {
    circleButton: {
      color: '#afb7c7',
      '&:hover, &.active': {
        color: '#1c5dca'
      }
    },
    root: {
      padding: '0 10px',
      height: '100%'
    },
    container: {
      padding: 20
    },
    alertSettingsContent: {
      height: 'calc(100% - 96px)',
      position: 'relative',
      padding: '0 10px'
    },
    actionWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 35
    },
    sideModalWrap: {
      zIndex: 102
    }
  }
}

const initialValues = {
  name: '',
  status: 'Active',
  frequency: 0,
  deviceIds: [],
  rules: [],
  conditions: [
    {
      ...alarmConditionInitialValue
    }
  ],
  details: {
    sms: {
      toggle: false,
      data: [
        {
          ...alarmDetailsInitialValue
        }
      ]
    },
    email: {
      toggle: false,
      data: [
        {
          ...alarmDetailsInitialValue
        }
      ]
    },
    noc: {
      toggle: false,
      data: [
        {
          ...alarmDetailsInitialValue
        }
      ]
    }
  }
}

const itemsLimit = Math.floor((window.innerHeight - 250) / 40)

const AlarmSettingsModal = ({
  t,
  classes,
  match: { params: { viewType } = {} },
  history,
  getDeviceAlarmItems,
  postDeviceAlarm,
  putDeviceAlarm,
  clearDeviceAlarmResponseInfo,
  deleteDeviceAlarm,
  getDeviceAlarmColumnList,
  getDeviceAlarmById,
  post,
  put,
  del,
  item
}) => {
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const [selectedId, setSelectedId] = useState()
  const { getPermissionByName } = usePermissions()
  const initialFormValues = useRef(initialValues)
  const { showConfirmation } = useConfirmation()
  const { enqueueSnackbar, closeSnackbar } = _useSnackbar()

  const permissions = useMemo(
    () => ({
      create: getPermissionByName(
        permissionNames.DEVICE_ALARM_NOTIFICATION_STORE
      ),
      update: getPermissionByName(
        permissionNames.DEVICE_ALARM_NOTIFICATION_UPDATE
      ),
      delete: getPermissionByName(
        permissionNames.DEVICE_ALARM_NOTIFICATION_DELETE
      )
    }),
    [getPermissionByName]
  )

  const isEdit = useMemo(() => !!selectedId, [selectedId])

  const onSubmit = useCallback(() => {
    setSaveDialogOpen(true)
  }, [])

  const {
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    handleSubmit,
    handleReset,
    setValues,
    validateForm,
    setTouched,
    setFieldError
  } = useFormik({
    initialValues: initialFormValues.current,
    validationSchema: alertAlarmValidationSchema(t),
    onSubmit
  })

  const fetcher = useCallback(
    (params = {}) => {
      getDeviceAlarmItems({
        limit: itemsLimit,
        ...params
      })
    },
    [getDeviceAlarmItems]
  )

  useEffect(() => {
    fetcher({
      page: 1
    })
    getDeviceAlarmColumnList()
    validateForm()
    // eslint-disable-next-line
  }, [])

  useNotifyAnalyzer(
    fetcher,
    clearDeviceAlarmResponseInfo,
    enqueueSnackbar,
    closeSnackbar,
    'Alert Alarm',
    [post, put, del]
  )

  const handleAdd = useCallback(() => {
    setSelectedId(null)
    initialFormValues.current = _cloneDeep(initialValues)
    setValues(initialFormValues.current)
    setTouched(formToTouched(initialValues))
    setSaveDialogOpen(false)
  }, [setValues, setTouched])

  useEffect(() => {
    if (
      _get(
        post,
        'response.status',
        _get(put, 'response.status', _get(del, 'response.status'))
      ) === 'success'
    ) {
      handleAdd()
    }
    // eslint-disable-next-line
  }, [post, put, del])

  useEffect(() => {
    if (!_isEmpty(item.response)) {
      const { details, devices, conditions, ...rest } = item.response
      initialFormValues.current = {
        ...initialValues,
        ...rest,
        conditions: conditions.map(c => ({
          ...c,
          color: getRandomColor()
        })),
        deviceIds: devices?.map(({ id }) => id),
        details: Object.values(alertAlarmViaType).reduce((a, key) => {
          a[key] = {
            toggle: !!details[key],
            data: details[key]
              ? details[key]
              : [
                  {
                    ...alarmDetailsInitialValue
                  }
                ]
          }
          return a
        }, {})
      }

      setValues(initialFormValues.current)
    }
    // eslint-disable-next-line
  }, [item])

  useFormErrorHandler({
    setFieldError,
    initialFormValues: initialFormValues.current,
    t,
    errorFields: _get(post, 'error.errorFields', _get(put, 'error.errorFields'))
  })

  const handleSelectedDevicesChange = useCallback(
    value => {
      setFieldValue(
        'deviceIds',
        values.deviceIds.includes(value.id)
          ? values.deviceIds.filter(id => id !== value.id)
          : [...values.deviceIds, value.id]
      )
    },
    [setFieldValue, values]
  )

  const handleCloseModal = useCallback(() => {
    history.push(getUrlPrefix(routeByName.deviceNOC[viewType]))
  }, [history, viewType])

  const handleSaveClick = useCallback(
    values => {
      const data = queryParamsHelper(
        {
          ...values
        },
        [],
        ['frequency']
      )

      data.conditions = data.conditions.map(condition => ({
        ...condition,
        value: String(condition.value)
      }))

      data.details = Object.entries(data.details).reduce((a, b) => {
        if (b?.[1]?.toggle) {
          a = a.concat(
            _get(b, '1.data', []).map(d => ({
              ...(b[0] === alertAlarmViaType.NOC ? {} : d),
              type: b[0]
            }))
          )
        }
        return a
      }, [])

      isEdit ? putDeviceAlarm(selectedId, data) : postDeviceAlarm(data)
    },
    [isEdit, postDeviceAlarm, putDeviceAlarm, selectedId]
  )

  const handleCloseSaveDialog = useCallback(() => {
    setSaveDialogOpen(false)
  }, [])

  const handleDelete = useCallback(
    (id, name) => {
      showConfirmation(getDeleteConfirmationMessage(name, t), () =>
        deleteDeviceAlarm(id)
      )
    },
    [showConfirmation, deleteDeviceAlarm, t]
  )

  const handleEdit = useCallback(
    id => {
      setSelectedId(id)
      if (id) {
        getDeviceAlarmById(id)
      }
    },
    [getDeviceAlarmById]
  )

  return (
    <>
      <SideModal
        alarmSideModal
        width="100%"
        title={t('Alerting-Alarm')}
        handleClose={handleCloseModal}
        wrapClassName={classes.sideModalWrap}
      >
        <Grid container wrap="nowrap" className={classes.alertSettingsContent}>
          <Grid item xs={3}>
            <AlarmAlertSection
              fetcher={fetcher}
              permissions={permissions}
              onAdd={handleAdd}
              onDelete={handleDelete}
              onEdit={handleEdit}
              selectedId={selectedId}
            />
          </Grid>
          <Grid item xs={3}>
            <DeviceSection
              scheduledDevices={[]}
              selectedDevices={values.deviceIds}
              handleSelectedDevicesChange={handleSelectedDevicesChange}
              assignType={scheduleAssignTypes.DEVICE}
              onChange={f => f}
              disableTypeSelection
              hideSchedulePopup
              rootClassName={classes.root}
              error={touched.deviceIds && errors.deviceIds}
            />
          </Grid>
          <Grid item xs={3}>
            <AlertRuleSection
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Grid>

          <Grid item xs={3}>
            <SendAlertViaSection
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </Grid>
        <Grid className={classes.actionWrap}>
          <Actions
            opaque={!isValid}
            saveBtnText="Save"
            onSave={handleSubmit}
            onCancel={handleReset}
            disabled={isEdit ? !permissions.update : !permissions.create}
          />
        </Grid>
      </SideModal>
      <SaveDialog
        t={t}
        open={saveDialogOpen}
        onClose={handleCloseSaveDialog}
        onSave={handleSaveClick}
        values={values}
        isEdit={isEdit}
        errors={errors}
      />
    </>
  )
}

AlarmSettingsModal.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string
  }).isRequired
}

const mapStateToProps = ({ deviceNoc: { alarm } }) => ({
  post: alarm.post,
  put: alarm.put,
  del: alarm.del,
  item: alarm.item
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceAlarmItems,
      postDeviceAlarm,
      putDeviceAlarm,
      clearDeviceAlarmResponseInfo,
      getDeviceAlarmColumnList,
      deleteDeviceAlarm,
      getDeviceAlarmById
    },
    dispatch
  )

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AlarmSettingsModal)
