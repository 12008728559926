import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useSnackbar as _useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { _has, _isEmpty, _uniqBy } from 'utils/lodash'

import customSnackbar from 'hooks/useCustomSnackbar'
import {
  clearMediaDependency,
  addMedia,
  editMedia,
  deleteSelectedMedia,
  postMediaBulk,
  cloneMedia
} from 'actions/mediaActions'
import {
  mediaDependencySourceResolver,
  mediaDependencyDataResolver,
  mediaDependencyTypes,
  contentMap
} from 'utils/mediaDependencyResolver'
import { mediaService } from 'services/index'
import Text from 'components/Typography/Text'
import DependencyModal from '../DependencyModal'
import {
  postDesignGallery,
  putDesignGallery
} from 'actions/designGalleryActions'
import {
  postMenuBoardMedia,
  putMenuBoardMedia
} from 'actions/menuDesignActions'
import { approveContent } from 'services/approveService'
import ConflictsModal from '../ConflictsModal'
import { conflictSources, exceptionsToConflictTypes } from 'constants/api'
import { createTab } from 'components/Tabs/TabsGroup'
import AlertNotification from 'components/AlertNotification'

const styles = ({ typography, type }) => ({
  content: {
    ...typography.darkText[type],
    fontWeight: 'bold',
    fontSize: '15px'
  },
  table: {
    marginBottom: '6px'
  },
  titleCell: {
    verticalAlign: 'middle',
    paddingBottom: '0',
    fontWeight: 'bold'
  },
  combinedRow: {
    width: '41%'
  },
  notificationWrapper: {
    paddingTop: 0
  },
  messageCardClass: {
    marginTop: 6
  }
})

const dialogConfigTypes = {
  [mediaDependencyTypes.ADD_MEDIA]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: addMedia,
    paramTransformer: ({ body, answer, meta }) => {
      let data = {
        ...body,
        confirmationToSave: answer
      }
      if (body instanceof FormData) {
        data = body
        data.append('confirmationToSave', answer ? 1 : 0)
      }
      return {
        mediaName: meta?.mediaName,
        tabName: meta?.tabName,
        data
      }
    }
  },
  [mediaDependencyTypes.EDIT_MEDIA]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: editMedia,
    paramTransformer: ({ id, body, answer }) => {
      let method = body._method ? 'POST' : 'PUT'
      let data = {
        ...body,
        confirmationToSave: answer
      }

      if (body instanceof FormData) {
        method = body.get('_method') ? 'POST' : 'PUT'
        data = body
        data.append('confirmationToSave', answer ? 1 : 0)
      }
      return {
        id,
        data,
        method
      }
    }
  },
  [mediaDependencyTypes.ADD_DESIGN_GALLERY]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: postDesignGallery,
    paramTransformer: ({ body, answer }) => ({
      data: {
        ...body,
        confirmationToSave: answer
      }
    })
  },
  [mediaDependencyTypes.EDIT_DESIGN_GALLERY]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: putDesignGallery,
    paramTransformer: ({ id, body, answer }) => ({
      id,
      data: {
        ...body,
        confirmationToSave: answer
      }
    })
  },
  [mediaDependencyTypes.DELETE_MEDIA]: {
    cancelOnNegativeAnswer: true,
    action: mediaService.deleteMediaLibraryItem,
    paramTransformer: ({ id, answer }) => ({
      mediaId: id,
      data: { confirmationToSave: answer }
    })
  },
  [mediaDependencyTypes.ADD_MEDIA_TAGS_BULK]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: postMediaBulk,
    paramTransformer: ({ id, body, answer }) => ({
      mediaIds: id.join(','),
      data: { ...body, confirmationToSave: answer }
    })
  },
  [mediaDependencyTypes.DELETE_MEDIA_BULK]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: deleteSelectedMedia,
    paramTransformer: ({ params, answer }) => ({
      ids: params.ids.split(','),
      data: { confirmationToSave: answer }
    })
  },
  [mediaDependencyTypes.CLONE_MEDIA]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: cloneMedia,
    paramTransformer: ({ body, answer }) => ({
      ...body,
      confirmationToSave: answer
    })
  },
  [mediaDependencyTypes.APPROVE_MEDIA]: {
    cancelOnNegativeAnswer: true,
    action: approveContent,
    noParamPass: true,
    paramTransformer: ({ id, answer }, action) => {
      return action('media', id, { confirmationToSave: answer })
    }
  },
  [mediaDependencyTypes.ADD_MENU_MAKER]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: postMenuBoardMedia,
    paramTransformer: ({ body, answer }) => {
      let data = {
        ...body,
        confirmationToSave: answer
      }
      if (body instanceof FormData) {
        data = body
        data.append('confirmationToSave', answer ? 1 : 0)
      }

      return data
    }
  },
  [mediaDependencyTypes.EDIT_MENU_MAKER]: {
    cancelOnNegativeAnswer: true,
    dispatchResult: true,
    action: putMenuBoardMedia,
    paramTransformer: ({ id, body, answer }) => {
      let data = {
        ...body,
        confirmationToSave: answer
      }

      if (body instanceof FormData) {
        data = body
        data.append('confirmationToSave', answer ? 1 : 0)
      }
      return {
        id,
        data
      }
    }
  }
}

const transformDependencyData = (obj, key, value) => {
  if (obj[key]) {
    obj[key] = _uniqBy([...obj[key], ...value], 'id')
  } else {
    obj[key] = [...value]
  }
}

const tabs = {
  attachPlaylist: 'Attach Playlist',
  detachPlaylist: 'Detach Playlist',
  attachSchedule: 'Attach Schedule',
  detachSchedule: 'Detach Schedule'
}

const sortOrder = ['Media', 'Playlist', 'Template', 'Schedule', 'Entities']

const MediaDependencyDialog = ({
  err,
  handleNegativeAction,
  handlePositiveAction,
  modalTitle,
  alertNotificationMessage,
  modalConfirmBtnText,
  titleHasQuestionMark,
  classes,
  t
}) => {
  const { enqueueSnackbar, closeSnackbar } = _useSnackbar()

  const [answer, setAnswer] = useState(null)

  const dispatch = useDispatch()

  const {
    error: mediaError,
    onPositiveAction,
    onNegativeAction: onMediaNegativeAction,
    name: mediaName
  } = useSelector(({ media }) => media.dependency)

  const { error, onNegativeAction } = useMemo(
    () => ({
      error: err ? err : mediaError,
      onNegativeAction: handleNegativeAction
        ? handleNegativeAction
        : onMediaNegativeAction
    }),
    [err, mediaError, handleNegativeAction, onMediaNegativeAction]
  )

  const open = useMemo(() => !!error && !!error.data, [error])

  const dialogMeta = useMemo(() => mediaDependencySourceResolver(error), [
    error
  ])

  const dialogContent = useCallback(
    tab => {
      const content = mediaDependencyDataResolver(error, dialogMeta, t)
      return content?.[tab] || content
    },
    [error, dialogMeta, t]
  )

  const isDeleteAction = useMemo(
    () =>
      [
        mediaDependencyTypes.DELETE_MEDIA,
        mediaDependencyTypes.DELETE_MEDIA_BULK
      ].includes(dialogMeta.type),
    [dialogMeta.type]
  )

  useEffect(() => {
    // Clear answer when error is resolved
    if (_isEmpty(error)) {
      setAnswer(null)
    }
  }, [error])

  const showSnackbar = useMemo(
    () => customSnackbar(t, enqueueSnackbar, closeSnackbar),
    [t, enqueueSnackbar, closeSnackbar]
  )

  const onCancel = useCallback(() => {
    dispatch(clearMediaDependency())
  }, [dispatch])

  const handleNegativeAnswer = useCallback(() => {
    setAnswer(false)
  }, [])

  const handlePositiveAnswer = useCallback(() => {
    setAnswer(true)
  }, [])

  const handleAction = useCallback(
    async (
      params,
      { dispatchResult, action, paramTransformer, noParamPass }
    ) => {
      // repeat failed action with confirmationToSave flag
      try {
        const actionResult = noParamPass
          ? await paramTransformer(params, action)
          : await action(paramTransformer(params))
        if (dispatchResult && actionResult) {
          dispatch(actionResult)
        } else {
          if (onPositiveAction && params.answer === true) {
            onPositiveAction()
          }

          if (onNegativeAction && params.answer === false) {
            onNegativeAction()
          }
          onCancel()
        }
      } catch (e) {
        if (e && e.message) {
          showSnackbar(e.message, 'error')
        }
        onCancel()
        return
      }
    },
    [onCancel, dispatch, onPositiveAction, onNegativeAction, showSnackbar]
  )

  useEffect(() => {
    if (!error || answer === null) {
      return
    }
    // handle dialog result
    const { type, id, body, params, meta } = dialogMeta
    if (!type) {
      onCancel()
      return
    }

    const {
      action,
      dispatchResult,
      cancelOnNegativeAnswer,
      paramTransformer,
      noParamPass
    } = dialogConfigTypes[type]

    if (cancelOnNegativeAnswer && answer === false) {
      if (onNegativeAction) {
        onNegativeAction()
      }
      onCancel()
      return
    }

    handlePositiveAction
      ? handlePositiveAction()
      : handleAction(
          { id, body, params, answer, meta },
          { dispatchResult, action, paramTransformer, noParamPass }
        )
  }, [
    answer,
    error,
    onCancel,
    dialogMeta,
    handleAction,
    onNegativeAction,
    handlePositiveAction
  ])

  const errorData = useMemo(() => {
    if (isDeleteAction) {
      return error?.data
    }

    if (error?.data) {
      const {
        toAttachPlaylists,
        toDetachPlaylists,
        toAttachSchedules,
        toDetachSchedules
      } = error?.data

      if (error.data?.length && error.data[0].data) {
        const res = {}

        error?.data?.forEach(item => {
          const {
            toAttachPlaylists,
            toDetachPlaylists,
            toAttachSchedules,
            toDetachSchedules
          } = item?.data

          toAttachPlaylists?.length &&
            transformDependencyData(res, tabs.attachPlaylist, toAttachPlaylists)
          toDetachPlaylists?.length &&
            transformDependencyData(res, tabs.detachPlaylist, toDetachPlaylists)
          toAttachSchedules?.length &&
            transformDependencyData(res, tabs.attachSchedule, toAttachSchedules)
          toDetachSchedules?.length &&
            transformDependencyData(res, tabs.detachSchedule, toDetachSchedules)
        })

        return res
      }

      return {
        ...(toAttachPlaylists?.length
          ? { [tabs.attachPlaylist]: toAttachPlaylists }
          : {}),
        ...(toDetachPlaylists?.length
          ? { [tabs.detachPlaylist]: toDetachPlaylists }
          : {}),
        ...(toAttachSchedules?.length
          ? { [tabs.attachSchedule]: toAttachSchedules }
          : {}),
        ...(toDetachSchedules?.length
          ? { [tabs.detachSchedule]: toDetachSchedules }
          : {})
      }
    }
    return null
  }, [error?.data, isDeleteAction])

  return (
    <>
      {exceptionsToConflictTypes.hasOwnProperty(error?.exception) ? (
        <ConflictsModal
          errors={[error]}
          onClose={handleNegativeAnswer}
          conflictSource={conflictSources.media}
        />
      ) : (
        errorData && (
          <DependencyModal
            type="media"
            open={open}
            isBulk={dialogMeta.type === mediaDependencyTypes.DELETE_MEDIA_BULK}
            data={errorData}
            onClose={handleNegativeAnswer}
            onConfirm={handlePositiveAnswer}
            name={mediaName || ''}
            modalTitle={
              modalTitle
                ? modalTitle
                : contentMap(t)[dialogMeta.type]?.modalTitle
            }
            titleHasQuestionMark={titleHasQuestionMark}
            noTabs={!(isDeleteAction || Object.keys(errorData).length > 1)}
            headerComponent={
              !isDeleteAction &&
              (({ selectedTab }) => (
                <Text rootClassName={classes.content}>
                  {!!selectedTab && dialogContent(selectedTab)}
                </Text>
              ))
            }
            modalConfirmBtnText={
              modalConfirmBtnText
                ? modalConfirmBtnText
                : isDeleteAction
                ? t('Delete')
                : t('Confirm')
            }
            {...(isDeleteAction && {
              alertNotificationComponent: (
                <AlertNotification
                  message={
                    alertNotificationMessage
                      ? alertNotificationMessage
                      : t(
                          'Deleting media will remove it from all associated Playlists, Templates, and Schedules. Device playback will be adjusted accordingly. Proceed?'
                        )
                  }
                  notificationWrapperClass={classes.notificationWrapper}
                  messageCardClass={classes.messageCardClass}
                />
              ),
              customTabs: [
                createTab(t('Media'), 'Media'),
                createTab(t('Playlist'), 'Playlist'),
                createTab(t('Template'), 'Template'),
                createTab(t('Schedule'), 'Schedule'),
                createTab(t('Entities'), 'Entities'),
                ...(_has(errorData, 'Modules')
                  ? [createTab(t('Others'), 'Others')]
                  : [])
              ]
            })}
            sortOrder={sortOrder}
          />
        )
      )}
    </>
  )
}
export default withTranslation('translations')(
  withStyles(styles)(MediaDependencyDialog)
)
