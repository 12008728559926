import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Typography, withStyles, Button, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import classNames from 'classnames'
import * as Yup from 'yup'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

import { FormControlInput } from 'components/Form'
import Spacing from 'components/Containers/Spacing'
import { requiredField, emailField } from 'constants/validationMessages'
import { isProduction, ORG_ROLE, SYSTEM_ROLE } from 'constants/api'
import { simulateEvent } from 'utils'
import PasswordVisibilityButton from './PasswordVisibilityButton'
import SocialPanel from '../SocialLogin/SocialPanel'
import SubmitButton from './SubmitButton'
import useSnackbar from 'hooks/useSnackbar'
import { useCheckLoginMutation } from 'api/authenticationApi'
import { WhiteButton } from 'components/Buttons'

function styles({ palette, type }) {
  return {
    inputWrap: {
      position: 'relative'
    },
    inputWrapSystem: {
      position: 'relative',
      '& > div': {
        margin: '0 0 10px 0'
      }
    },
    formControlInput: {
      height: '48px',
      fontSize: '15px'
    },
    formLink: {
      margin: '0 14px 0 3px',
      display: 'inline-block',
      textDecoration: 'none'
    },
    formLinkText: {
      fontSize: '13px',
      color: '#0076b9'
    },
    marginTopReq: {
      marginTop: '8px'
    },
    forgotPassLink: {
      top: '16px',
      right: '12px'
    },
    submitWrap: {
      marginBottom: 10
    },
    submitWrapWithIp: {
      marginBottom: '20px'
    },
    dontHaveAccountText: {
      color: palette[type].header.account.color,
      textAlign: 'center',
      marginTop: 45
    },
    dontHaveAccountLink: {
      color: '#0076b9'
    },
    eyeContentWrap: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '-10px'
    },
    eyeContentSystem: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      height: '48px',
      right: 0
    },
    signInContainer: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    },
    submitButtonRoot: {
      width: '100%',
      maxWidth: 320,
      marginRight: 10
    },
    dividerContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '14px 0',
      textAlign: 'center'
    },
    dividerLine: {
      flex: 1,
      borderBottom: '1px solid #ccc',
      margin: '0 10px'
    },
    dividerText: {
      fontSize: '14px',
      color: '#5f6368'
    },
    signInWithEmailButton: {
      width: '100%',
      height: '48px',
      marginTop: 4,
      backgroundColor: '#0076b9',
      '&:hover': {
        backgroundColor: '#006198'
      },
      '& span': {
        padding: '4px 0 0 0'
      },
      '&.Mui-disabled': {
        backgroundColor: '#0076b9 !important',
        color: '#fff !important',
        opacity: '0.5'
      }
    },
    disabled: {
      width: '100%',
      height: '48px',
      color: '#fff !important',
      background: '#0076b9 !important',
      opacity: 0.5,
      marginTop: 4,
      '& span': {
        padding: '4px 0 0 0'
      },
      '&.Mui-disabled': {
        backgroundColor: '#0076b9 !important',
        color: '#fff !important',
        opacity: '0.5'
      }
    },
    magiclink: {
      maxWidth: '400px'
    },
    magicLinkText: {
      color: palette[type].header.account.color,
      textAlign: 'center',
      marginTop: '12px'
    },
    formControlLabel: {
      fontWeight: 'bold'
    },
    formControlWrapper: {
      display: 'grid',
      alignItems: 'center',
      gap: '16px',
      gridTemplateColumns: '3fr 1fr'
    },
    inputFieldWrapper: {
      position: 'relative',
      display: 'flex',
      flex: 1
    },
    pwdWrapEye: {
      position: 'absolute',
      top: 'calc(50% + 3px)',
      transform: 'translateY(-50%)',
      right: '5px'
    },
    magicSignInButton: {
      width: '100%',
      height: '48px',
      '& span': {
        padding: '4px 0 0 0'
      }
    },
    magicLinkDisabled: {
      opacity: 0.5,
      width: '100%',
      height: '48px',
      '& span': {
        padding: '4px 0 0 0'
      }
    }
  }
}

const formConfig = {
  initialValues: {
    username: '',
    password: ''
  },
  validationSchema: Yup.object().shape({
    username: Yup.string().required(requiredField).email(emailField),
    password: Yup.string().required(requiredField)
  })
}

const { initialValues, validationSchema } = formConfig

const FormWrapper = props => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
    >
      <Form {...props} />
    </GoogleReCaptchaProvider>
  )
}

function Form({ t, role, sso, classes, isIpVisible, isLoading, onSubmit }) {
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [loginWithAnotherMethod, setLoginWithAnotherMethod] = useState(false)

  const { showSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [checkLogin, loginResult] = useCheckLoginMutation()

  const {
    isValid,
    values,
    touched,
    errors,
    handleChange,
    validateForm,
    setTouched,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ username, password, rememberMe }) => {
      onSubmit(username, password, rememberMe, role)
    }
  })

  useEffect(
    () => {
      validateForm()
    },
    //eslint-disable-next-line
    []
  )

  useEffect(() => {
    if (loginResult.isSuccess) {
      showSnackbar(
        'You will receive a Magic Link email if your email address is registered. This link will expire in 15 minutes',
        'success'
      )
    } else if (loginResult.isError && loginResult.error?.message) {
      showSnackbar(loginResult.error?.message, 'error')
    }
    // eslint-disable-next-line
  }, [loginResult.isSuccess, loginResult.isError])

  const handleEmailSignIn = useCallback(
    async e => {
      e.preventDefault()

      const errors = await validateForm(values)

      if (errors.username) {
        setTouched({ username: true })
        return
      }

      if (!executeRecaptcha) {
        showSnackbar(t('Recaptcha is not yet available'), 'error')
        return
      }

      const recaptchaToken = await executeRecaptcha('checkLogin')
      checkLogin({
        email: values.username,
        recaptchaToken
      })
    },
    [
      validateForm,
      values,
      executeRecaptcha,
      setTouched,
      showSnackbar,
      t,
      checkLogin
    ]
  )

  const handleInputChange = useCallback(
    e => {
      const { value, name } = e.target
      if (value) {
        handleChange(simulateEvent(name, value.replaceAll(' ', '')))
      } else {
        handleChange(e)
      }
    },
    [handleChange]
  )

  const signInButton = useMemo(
    () => (
      <div class>
        <SubmitButton
          opaque={!isValid}
          isLoading={isLoading}
          rootClass={role === SYSTEM_ROLE && classes.submitButtonRoot}
        >
          {t('Sign In')}
        </SubmitButton>
      </div>
    ),
    [isValid, isLoading, role, t, classes.submitButtonRoot]
  )

  return role === ORG_ROLE ? (
    <div>
      <Spacing
        variant={0}
        rootClassName={classNames({
          [classes.submitWrap]: !isIpVisible,
          [classes.submitWrapWithIp]: isIpVisible
        })}
      >
        <SocialPanel
          renderOptions={sso}
          role={role}
          loginWithAnotherMethod={loginWithAnotherMethod}
          setLoginWithAnotherMethod={setLoginWithAnotherMethod}
        />
        <div className={classes.dividerContainer}>
          <div className={classes.dividerLine}></div>
          <Typography className={classes.dividerText}>{t('OR')}</Typography>
          <div className={classes.dividerLine}></div>
        </div>
        <form onSubmit={handleSubmit} className={classes.magiclink}>
          <div className={classes.inputWrap}>
            <FormControlInput
              id="username"
              type="text"
              autocomplete="email"
              label={t('Email')}
              name="username"
              value={values.username}
              handleChange={handleInputChange}
              error={errors.username}
              handleBlur={handleBlur}
              touched={touched.username}
              placeholder={'you@example.com'}
              formControlInputClass={classes.formControlInput}
              formControlContainerClass={classes.formControlContainer}
              formControlLabelClass={classes.formControlLabel}
              fullWidth
            />
          </div>
          <div className={classes.inputWrap}>
            <div className={classes.formControlWrapper}>
              <div className={classes.inputFieldWrapper}>
                <FormControlInput
                  id="password"
                  type={passwordVisibility ? 'text' : 'password'}
                  label={t('Password')}
                  name="password"
                  value={values.password}
                  handleChange={handleInputChange}
                  error={errors.password}
                  handleBlur={handleBlur}
                  touched={touched.password}
                  placeholder={t('Your password')}
                  formControlInputClass={classes.formControlInput}
                  formControlContainerClass={classes.formControlContainer}
                  formControlLabelClass={classes.formControlLabel}
                  fullWidth
                />
                <div className={classes.pwdWrapEye}>
                  <PasswordVisibilityButton
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    isVisible={passwordVisibility}
                  />
                </div>
              </div>
              <Button
                className={`${
                  !isValid ? classes.disabled : classes.signInWithEmailButton
                }`}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                {t('Login')}
              </Button>
            </div>
            <div className={classes.eyeContentWrap}>
              <Link
                to={'/forgot-password'}
                component={RouterLink}
                className={classNames(classes.formLink, classes.forgotPassLink)}
              >
                <Typography
                  className={classNames(classes.formLinkText, {
                    [classes.marginTopReq]: touched.password && errors.password
                  })}
                >
                  {t('Reset Password')}?
                </Typography>
              </Link>
            </div>
          </div>
          <div className={classes.dividerContainer}>
            <div className={classes.dividerLine}></div>
            <Typography className={classes.dividerText}>{t('OR')}</Typography>
            <div className={classes.dividerLine}></div>
          </div>
          <WhiteButton
            className={classNames({
              [classes.magicLinkDisabled]: errors.username,
              [classes.magicSignInButton]: !errors.username
            })}
            onClick={handleEmailSignIn}
            disabled={loginResult?.isLoading}
          >
            {t('Get Login Code Over Email')}
          </WhiteButton>

          <Typography className={classes.magicLinkText}>
            {t("We'll email you a magic link for a password-free sign-in.")}
          </Typography>
        </form>
        {isProduction && role === ORG_ROLE && (
          <Spacing>
            <Typography className={classes.dontHaveAccountText}>
              {t('Want to try?')}{' '}
              <Link
                to="/sign-up"
                component={RouterLink}
                className={classes.dontHaveAccountLink}
              >
                {t('Sign up for a free trial!')}
              </Link>
            </Typography>
          </Spacing>
        )}
      </Spacing>
    </div>
  ) : (
    <form onSubmit={handleSubmit}>
      <div className={classes.inputWrapSystem}>
        <FormControlInput
          id="username"
          type="text"
          autocomplete="email"
          name="username"
          value={values.username}
          handleChange={handleInputChange}
          error={errors.username}
          handleBlur={handleBlur}
          touched={touched.username}
          placeholder={t('Email')}
          formControlInputClass={classes.formControlInput}
          formControlContainerClass={classes.formControlContainer}
          fullWidth
        />
      </div>
      <div className={classes.inputWrapSystem}>
        <FormControlInput
          id="password"
          type={passwordVisibility ? 'text' : 'password'}
          name="password"
          value={values.password}
          handleChange={handleInputChange}
          error={errors.password}
          handleBlur={handleBlur}
          touched={touched.password}
          placeholder={t('Password')}
          formControlInputClass={classes.formControlInput}
          formControlContainerClass={classes.formControlContainer}
          fullWidth
        />
        <div className={classes.eyeContentSystem}>
          <PasswordVisibilityButton
            onClick={() => setPasswordVisibility(!passwordVisibility)}
            isVisible={passwordVisibility}
          />
          <Link
            to={'/system/forgot-password'}
            component={RouterLink}
            className={classNames(classes.formLink, classes.forgotPassLink)}
          >
            <Typography className={classes.formLinkText}>
              {t('Reset Password')}
            </Typography>
          </Link>
        </div>
      </div>
      <Spacing
        variant={0}
        rootClassName={classNames({
          [classes.submitWrap]: !isIpVisible,
          [classes.submitWrapWithIp]: isIpVisible
        })}
      >
        <Spacing variant={0}>
          <Grid container className={classNames(classes.signInContainer, {})}>
            <SocialPanel
              renderOptions={sso}
              role={role}
              loginWithAnotherMethod={loginWithAnotherMethod}
              setLoginWithAnotherMethod={setLoginWithAnotherMethod}
              signInButton={signInButton}
            />
          </Grid>
        </Spacing>
      </Spacing>
    </form>
  )
}

Form.propTypes = {
  role: PropTypes.string,
  sso: PropTypes.object,
  isIpVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
}

export default withTranslation('translations')(withStyles(styles)(FormWrapper))
