import { transformTimezoneNamesToOptions } from 'utils/transformToOptionsUtils'
import { approveStatuses } from './library'
import { playlistTypes } from './playlist'
import { templateTypes } from './template'
import {
  EXPRESS_SUPPORT,
  SIGNATURE_CARE,
  STANDARD_SUPPORT
} from './clientsConstants'
import { timeZones } from './timezones'

export const statusOptions = t => [
  { value: 'any', label: t('Any') },
  { value: 'Active', label: t('Active') },
  { value: 'Inactive', label: t('Inactive') }
]

export const clientStatusOptions = t => [
  { value: 'any', label: t('Any') },
  { value: 'Active', label: t('Active') },
  { value: 'Inactive', label: t('Inactive') },
  { value: 'Dormant', label: t('Dormant') }
]

export const billingOptions = t => [
  { value: '1', label: t('Enabled') },
  { value: '0', label: t('Disabled') }
]

export const posterLibraryStatusOptions = t => [
  { value: 'Active', label: t('Active') },
  { value: 'Inactive', label: t('Inactive') }
]

export const approvalOptions = t => [
  { value: 'any', label: t('Any') },
  { value: 'true', label: t('Approved') },
  { value: 'false', label: t('Awaiting Approval') },
  { value: 'rejected', label: t('Rejected') }
]

export const newApprovalOptions = t => [
  { value: approveStatuses.approve, label: t('Approved') },
  { value: approveStatuses.disapprove, label: t('Rejected') },
  {
    value: approveStatuses.waitingForApproval,
    label: t('Awaiting Approval')
  }
]

export const playlistTypeOptions = [
  {
    value: 'Standard',
    label: 'Standard',
    meta: {
      color: playlistTypes.Standard.color,
      icon: playlistTypes.Standard.icon
    }
  },
  {
    value: 'Interactive',
    label: 'Interactive',
    meta: {
      color: playlistTypes.Interactive.color,
      icon: playlistTypes.Interactive.icon
    }
  },
  {
    value: 'Smart',
    label: 'Smart',
    meta: {
      color: playlistTypes.Smart.color,
      icon: playlistTypes.Smart.iconHelperClass
    }
  },
  {
    value: 'Folder',
    label: 'Magic Folders',
    meta: {
      color: playlistTypes.Folder.color,
      icon: playlistTypes.Folder.iconHelperClass
    }
  }
]

export const templateTypeOptions = t => [
  {
    value: 'Standard',
    label: t('Standard'),
    meta: {
      icon: templateTypes.Standard.iconHelperClass,
      color: templateTypes.Standard.color
    }
  },
  {
    value: 'Interactive',
    label: t('Interactive'),
    meta: {
      icon: templateTypes.Interactive.iconComponent,
      color: templateTypes.Interactive.color
    }
  },
  {
    value: 'Videowall',
    label: t('Videowall'),
    meta: {
      icon: templateTypes.Videowall.iconHelperClass,
      color: templateTypes.Videowall.color
    }
  }
]

export const scheduleTypeOptions = [
  { value: 'Timed', label: 'Timed Schedule' },
  { value: 'Failover', label: 'Failover Schedule' },
  { value: 'SmartSchedule', label: 'Smart Schedule' }
]

export const refreshEveryTooltip =
  'Frequency, in minutes, to fetch updated content from Web Feeds, URLs, 3rd Party sources (like Google docs) etc'

export const scrollDurationTooltip = 'Duration per page (in seconds)'

export const status = {
  Active: 'Active',
  Inactive: 'Inactive',
  Disabled: 'Disabled',
  Expired: 'Expired'
}

const getTimeZones = () => {
  try {
    return Intl.supportedValuesOf('timeZone')
  } catch (e) {
    return timeZones
  }
}

export const timezoneOptions = transformTimezoneNamesToOptions(getTimeZones())

export const donorWallsCategoryOptions = [
  {
    label: 'BRONZE',
    value: 'BRONZE'
  },
  {
    label: 'GOLD',
    value: 'GOLD'
  },
  {
    label: 'SILVER',
    value: 'SILVER'
  },
  {
    label: 'SAPPHIRE',
    value: 'SAPPHIRE'
  },
  {
    label: 'DIAMOND',
    value: 'DIAMOND'
  },
  {
    label: 'PLATINUM',
    value: 'PLATINUM'
  }
]

export const AS_IT_HAPPENS_FREQUENCY_VALUE = 4

export const frequencyOptions = (t = f => f) => [
  { label: t('Once an hour'), value: 1 },
  { label: t('Once a day'), value: 2 },
  { label: t('Once a week'), value: 3 },
  { label: t('As it happens'), value: AS_IT_HAPPENS_FREQUENCY_VALUE },
  { label: t('Never'), value: 5 }
]

export const sortByOptions = t => [
  {
    value: 'duration_desc',
    label: t('Duration: Highest-Lowest')
  },
  {
    value: 'duration_asc',
    label: t('Duration: Lowest-Highest')
  },
  {
    value: 'title_asc',
    label: t('Name: A-Z')
  },
  {
    value: 'title_desc',
    label: t('Name: Z-A')
  },
  {
    value: 'updatedAt_desc',
    label: t('Updated: Newest')
  },
  {
    value: 'updatedAt_asc',
    label: t('Updated: Oldest')
  }
]

export const dateFormatOptions = [
  {
    label: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY'
  },
  {
    label: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY'
  }
]

export const brightSignDefaultOption = {
  label: 'Auto',
  value: 'auto'
}

export const brightSignTimezoneOptions = [
  brightSignDefaultOption,
  {
    label: 'US Eastern Time',
    value: 'EST'
  },
  {
    label: 'US Central Time',
    value: 'CST'
  },
  {
    label: 'US Mountain Time',
    value: 'MST'
  },
  {
    label: 'US Pacific Time',
    value: 'PST'
  },
  {
    label: 'Alaska Time',
    value: 'AKST'
  },
  {
    label: 'Hawaii-Aleutian Time with no Daylight Saving (Hawaii)',
    value: 'HST'
  },
  {
    label: 'Hawaii-Aleutian Time with Daylight Saving',
    value: 'HST1'
  },
  {
    label: 'US MT without Daylight Saving Time (Arizona)',
    value: 'MST1'
  },
  {
    label: 'US ET without Daylight Saving Time (East Indiana)',
    value: 'EST1'
  },
  {
    label: 'Atlantic Time',
    value: 'AST'
  },
  {
    label: 'Mexico (Mexico City)',
    value: 'CST2'
  },
  {
    label: 'Mexico (Chihuahua)',
    value: 'MST2'
  },
  {
    label: 'Mexico (Tijuana)',
    value: 'PST2'
  },
  {
    label: 'Brazil Time (Sao Paulo)',
    value: 'BRT'
  },
  {
    label: 'Newfoundland Time',
    value: 'NST'
  },
  {
    label: 'Azores Time',
    value: 'AZOT'
  },
  {
    label: 'London/Dublin Time',
    value: 'GMTBST'
  },
  {
    label: 'Western European Time',
    value: 'WET'
  },
  {
    label: 'Central European Time',
    value: 'CET'
  },
  {
    label: 'Eastern European Time',
    value: 'EET'
  },
  {
    label: 'Moscow Time',
    value: 'MSK'
  },
  {
    label: 'Delta Time Zone (Samara)',
    value: 'SAMT'
  },
  {
    label: 'Echo Time Zone (Yekaterinburg)',
    value: 'YEKT'
  },
  {
    label: 'Indian Standard Time',
    value: 'IST'
  },
  {
    label: 'Nepal Time',
    value: 'NPT'
  },
  {
    label: 'Foxtrot Time Zone (Omsk)',
    value: 'OMST'
  },
  {
    label: 'Japanese Standard Time',
    value: 'JST'
  },
  {
    label: 'Christmas Island Time (Australia)',
    value: 'CXT'
  },
  {
    label: 'Australian Western Time',
    value: 'AWST'
  },
  {
    label: 'Australian Western Time without Daylight Saving Time',
    value: 'AWST1'
  },
  {
    label: 'Australian Eastern Time',
    value: 'AEST'
  },
  {
    label: 'Australian Eastern Time without Daylight Saving Time (Brisbane)',
    value: 'AEST1'
  },
  {
    label: 'Norfolk (Island) Time (Australia)',
    value: 'NFT'
  },
  {
    label: 'New Zealand Time (Auckland)',
    value: 'NZST'
  },
  {
    label: 'X-ray Time Zone (Pago Pago)',
    value: 'SST'
  },
  {
    label: 'Greenwich Mean Time',
    value: 'GMT'
  },
  {
    label: '1 hour ahead of Greenwich Mean Time',
    value: 'GMT-1'
  },
  {
    label: '2 hours ahead of Greenwich Mean Time',
    value: 'GMT-2'
  },
  {
    label: '3 hours ahead of Greenwich Mean Time',
    value: 'GMT-3'
  },
  {
    label: '4 hours ahead of Greenwich Mean Time',
    value: 'GMT-4'
  },
  {
    label: '5 hours ahead of Greenwich Mean Time',
    value: 'GMT-5'
  },
  {
    label: '6 hours ahead of Greenwich Mean Time',
    value: 'GMT-6'
  },
  {
    label: '7 hours ahead of Greenwich Mean Time',
    value: 'GMT-7'
  },
  {
    label: '8 hours ahead of Greenwich Mean Time',
    value: 'GMT-8'
  },
  {
    label: '9 hours ahead of Greenwich Mean Time',
    value: 'GMT-9'
  },
  {
    label: '10 hours ahead of Greenwich Mean Time',
    value: 'GMT-10'
  },
  {
    label: '11 hours ahead of Greenwich Mean Time',
    value: 'GMT-11'
  },
  {
    label: '12 hours ahead of Greenwich Mean Time',
    value: 'GMT-12'
  },
  {
    label: '13 hours ahead of Greenwich Mean Time',
    value: 'GMT-13'
  },
  {
    label: '14 hours ahead of Greenwich Mean Time',
    value: 'GMT-14'
  },
  {
    label: '1 hour behind Greenwich Mean Time',
    value: 'GMT+1'
  },
  {
    label: '2 hours behind Greenwich Mean Time',
    value: 'GMT+2'
  },
  {
    label: '3 hours behind Greenwich Mean Time',
    value: 'GMT+3'
  },
  {
    label: '4 hours behind Greenwich Mean Time',
    value: 'GMT+4'
  },
  {
    label: '5 hours behind Greenwich Mean Time',
    value: 'GMT+5'
  },
  {
    label: '6 hours behind Greenwich Mean Time',
    value: 'GMT+6'
  },
  {
    label: '7 hours behind Greenwich Mean Time',
    value: 'GMT+7'
  },
  {
    label: '8 hours behind Greenwich Mean Time',
    value: 'GMT+8'
  },
  {
    label: '9 hours behind Greenwich Mean Time',
    value: 'GMT+9'
  },
  {
    label: '10 hours behind Greenwich Mean Time',
    value: 'GMT+10'
  },
  {
    label: '11 hours behind Greenwich Mean Time',
    value: 'GMT+11'
  },
  {
    label: '12 hours behind Greenwich Mean Time',
    value: 'GMT+12'
  },
  {
    label: '13 hours behind Greenwich Mean Time',
    value: 'GMT+13'
  },
  {
    label: '14 hours behind Greenwich Mean Time',
    value: 'GMT+14'
  }
]

export const serviceLevelOptions = [
  {
    value: STANDARD_SUPPORT,
    label: STANDARD_SUPPORT
  },
  {
    value: EXPRESS_SUPPORT,
    label: EXPRESS_SUPPORT
  },
  {
    value: SIGNATURE_CARE,
    label: SIGNATURE_CARE
  }
]

export const amadeusOptionTypes = {
  location: 'location',
  roomGroup: 'roomGroup',
  room: 'room'
}
