import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { GridOn, List } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Popup from 'components/Popup'
import Tooltip from 'components/Tooltip'
import Scrollbars from 'components/Scrollbars'
import DefaultModal from 'components/Modal/DefaultModal'
import {
  BlueButton,
  CircleIconButton,
  CircleLibraryIconButton,
  WhiteButton
} from 'components/Buttons'
import { useLazyDeviceListQuery } from 'api/deviceApi'
import useViewSetting from 'hooks/useViewSetting'
import { _concat, _uniqBy } from 'utils/lodash'
import handleBottomScroll from 'utils/handleBottomScroll'
import { deviceQueryParamsHelper } from 'utils/queryParamsHelpers'
import { libraryViews } from 'constants/library'
import { SCHEDULE_PUBLISH_DEVICES } from 'constants/viewSettingKeys'

const useStyles = makeStyles(({ palette, type }) => ({
  modalItemsWrap: {
    height: 'calc(100vh - 185px)',
    background: palette[type].modal.background,
    padding: '20px 20px'
  },
  modalLoaderBackgroundWrap: {
    height: '100%',
    background: palette[type].loader.background
  },
  modalRoot: {
    height: '100%'
  },
  dialogFooter: {
    borderTop: `1px solid ${palette[type].sideModal.content.border}`
  },
  loadMoreButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  modalContentListWrap: {
    height: ' max-content',
    width: '95%',
    margin: '0 auto'
  },
  modalFlipMode: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(260px, 1fr) )',
    gap: '15px'
  },
  gridViewWrapper: {
    gridTemplateColumns: 'repeat(7, minmax(120px, 1fr) )'
  }
}))

const dropdownStyle = {
  borderRadius: 6,
  width: 315,
  animation: 'fade-in 200ms'
}

const deviceFetchParams = {
  sort: 'alias',
  order: 'asc'
}

const LIMIT = 112 //closest to 100 values, that must fill the 4 and 7 cols row completely

const DeviceSectionModal = ({
  titleSearchComponent,
  renderFilterComponent,
  open,
  onCloseModal,
  loader: Loader,
  selectedDevices,
  filterParams,
  backendSelectedDevices,
  isFilterActive,
  deviceRowComponent: DeviceRowComponent,
  onChange,
  hideSchedulePopup,
  isDeviceAutoAdded,
  isDeviceDisabled
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const currentScrollPosition = useRef(0)
  const scrollBarRef = useRef()

  const handleScrollUpdate = useCallback(({ scrollTop }) => {
    currentScrollPosition.current = scrollTop
  }, [])

  const [modalDeviceList, setModalDeviceList] = useState([])

  const { view, changeView } = useViewSetting(SCHEDULE_PUBLISH_DEVICES, '')

  const [
    fetchBasicDevices,
    {
      data: { data: response = [], meta = {} } = {},
      isFetching,
      isUninitialized
    }
  ] = useLazyDeviceListQuery()

  const isLoading = isFetching || isUninitialized

  const fetchDevices = useCallback(
    (params = {}) => {
      fetchBasicDevices(
        deviceQueryParamsHelper({
          limit: LIMIT,
          ...params,
          ...deviceFetchParams,
          include: ['locationImage']
        })
      )
    },
    [fetchBasicDevices]
  )

  const isLastPage = useMemo(() => meta.currentPage >= meta.lastPage, [
    meta.currentPage,
    meta.lastPage
  ])

  const handleFetchMoreModal = useCallback(() => {
    if (!isLastPage) {
      fetchDevices({
        ...filterParams,
        page: meta.currentPage + 1,
        limit: LIMIT
      })
    }
  }, [isLastPage, filterParams, fetchDevices, meta])

  const closeHandler = () => {
    const devices = []
    selectedDevices.forEach(id => {
      const device = modalDeviceList.find(device => device.id === id)
      devices.push({ ...device, selected: true })
    })
    handleScrollUpdate({ scrollTop: 0 })
    onCloseModal(devices)
  }

  useEffect(() => {
    if (!response.length || isFetching) return
    if (meta.currentPage > 1) {
      setModalDeviceList(prevState => prevState.concat(response))
      scrollBarRef.current?.scrollToBottom(currentScrollPosition.current)
    } else {
      setModalDeviceList(response)
    }
  }, [isFetching, meta, response])

  useEffect(() => {
    if (open) {
      fetchDevices({ limit: LIMIT, ...filterParams })
    }
    // eslint-disable-next-line
  }, [open, filterParams])

  const modalOrderedDeviceList = useMemo(() => {
    const data = modalDeviceList.map(device => {
      if (selectedDevices.includes(device.id)) {
        return { ...device, selected: true }
      } else {
        return device
      }
    })

    return data
  }, [modalDeviceList, selectedDevices])

  const modalUniqueDevices = useMemo(
    () =>
      isFilterActive
        ? modalOrderedDeviceList
        : _uniqBy(
            _concat(backendSelectedDevices, modalOrderedDeviceList),
            'id'
          ),
    [isFilterActive, modalOrderedDeviceList, backendSelectedDevices]
  )

  const allSelected = useMemo(
    () =>
      modalUniqueDevices.length &&
      modalUniqueDevices.every(
        device => isDeviceAutoAdded(device) || device.selected
      ),
    [modalUniqueDevices, isDeviceAutoAdded]
  )

  const handleToggleAllDevices = () => {
    if (allSelected) {
      onChange(modalUniqueDevices.filter(device => !isDeviceDisabled(device)))
    } else {
      onChange(
        modalUniqueDevices.filter(
          device => !isDeviceDisabled(device) && !device.selected
        )
      )
    }
  }

  return (
    <DefaultModal
      titleComponent={
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={6}>
            {titleSearchComponent}
          </Grid>

          <Tooltip
            title={t('Select/Unselect All')}
            onClick={handleToggleAllDevices}
          >
            <CircleIconButton
              className={classNames('hvr-grow', {
                active: allSelected
              })}
            >
              <i className="fa-regular fa-list-check" />
            </CircleIconButton>
          </Tooltip>
          <CircleLibraryIconButton
            active={view === libraryViews.list}
            onClick={() => changeView(libraryViews.list)}
          >
            <List />
          </CircleLibraryIconButton>
          <CircleLibraryIconButton
            active={view === libraryViews.grid}
            onClick={() => changeView(libraryViews.grid)}
          >
            <GridOn />
          </CircleLibraryIconButton>
          <Popup
            on="click"
            position="bottom right"
            contentStyle={dropdownStyle}
            trigger={
              <CircleIconButton className="hvr-grow">
                <i className="fa-regular fa-sliders" />
              </CircleIconButton>
            }
          >
            {renderFilterComponent}
          </Popup>
        </Grid>
      }
      hasCancelBtn={false}
      hasSaveBtn={false}
      overflowVisible
      modalTitle={'Select Devices'}
      open={open}
      maxWidth={'xl'}
      onCloseModal={closeHandler}
      contentClass={classNames(
        {
          [classes.modalItemsWrap]: !isLoading
        },
        {
          [classes.modalLoaderBackgroundWrap]: isLoading
        }
      )}
      rootClassName={classes.modalRoot}
      footerClassName={classes.dialogFooter}
      actions={<WhiteButton onClick={closeHandler}>{t('Select')}</WhiteButton>}
    >
      <>
        {isLoading ? (
          <div className={classes.modalLoaderBackgroundWrap}>
            <Loader />
          </div>
        ) : (
          <Scrollbars
            ref={scrollBarRef}
            onUpdate={handleBottomScroll(handleScrollUpdate)}
          >
            <div className={classes.modalContentListWrap}>
              <div
                className={classNames(classes.modalFlipMode, {
                  [classes.gridViewWrapper]: view === libraryViews.grid
                })}
              >
                {modalUniqueDevices.map(item => {
                  const autoAdded = isDeviceAutoAdded(item)
                  const disabled = isDeviceDisabled(item)

                  return (
                    <div key={item.id}>
                      <DeviceRowComponent
                        {...(disabled && {
                          disabledReason: t(
                            'Auto added via Group / Tag / Location'
                          )
                        })}
                        disabled={disabled}
                        gridSize={12}
                        item={item}
                        key={item.id}
                        name={item.alias || item.name}
                        checkboxValue={
                          !!autoAdded || selectedDevices?.includes(item.id)
                        }
                        onChange={onChange}
                        hideSchedulePopup={hideSchedulePopup}
                        modalView
                      />
                    </div>
                  )
                })}
              </div>
              {!isLastPage && (
                <div className={classes.loadMoreButton}>
                  <BlueButton
                    disabled={false}
                    onClick={handleFetchMoreModal}
                    iconClassName="fa-solid fa-angles-down"
                  >
                    {t('Show More')}
                  </BlueButton>
                </div>
              )}
            </div>
          </Scrollbars>
        )}
      </>
    </DefaultModal>
  )
}

export default DeviceSectionModal
