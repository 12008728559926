import React, { useCallback, useEffect, useState } from 'react'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'
import { withStyles, withTheme, Typography, Link } from '@material-ui/core'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { requiredField } from 'constants/validationMessages'
import {
  passwordConfirmValidateSchema,
  passwordValidateSchema,
  phoneValidationSchema
} from 'constants/validations'
import { register } from 'actions/new/auth'
import { clearSSOData } from 'actions/authenticationActions'
import AccountModal from 'components/Account/AccountModal'
import SocialPanel from 'components/Account/SocialLogin/SocialPanel'
import Spacing from 'components/Containers/Spacing'
import Container from 'components/Containers/Container'
import useWhiteLabel from 'hooks/useWhiteLabel'
import useSnackbar from 'hooks/useSnackbar'
import Form from './Form'
import LogoImage from '../LogoImage'
import SubmitButton from '../SignIn/SubmitButton'
import { ssoSelector } from 'selectors/new/auth'
import { errorArrayToObj } from 'utils/formik'

const styles = ({ palette, type, mixins }) => ({
  wrapperHeight: {
    maxHeight: '98vh'
  },
  formContent: {
    paddingTop: 45
  },
  systemView: {
    backgroundColor: '#121212'
  },
  container: {
    width: '569px',
    padding: '0 65px'
  },
  logoImage: {
    ...mixins.loginPageLogo,
    marginBottom: '20px'
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 30,
    color: palette[type].pages.singIn.color,
    fontSize: '3.3rem'
  },
  clientDetailCardRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '16px'
  },
  stretch: {
    gridColumnStart: 1,
    gridColumnEnd: 3
  },
  formControlLabel: {
    fontSize: 15
  },
  formControlInput: {
    height: '48px !important',
    fontSize: '15px !important',
    '& input': {
      fontSize: '15px !important'
    }
  },
  selectFieldError: {
    top: 69
  },
  passwordIcon: {
    padding: 12,
    bottom: 2
  },
  captchaWrapper: {
    marginTop: 15
  },
  checkBoxWrapper: {
    color: palette[type].formControls.label.color,
    marginTop: 6
  },
  checkBox: {
    marginRight: 12,
    marginLeft: 10
  },
  formSuccessMessage: {
    fontSize: '20px',
    marginTop: '74px',
    marginBottom: '15px',
    textAlign: 'center',
    color: palette[type].pages.singIn.color
  },
  backToText: {
    padding: '60px 0',
    color: '#888996',
    textAlign: 'center'
  },
  backToLink: {
    color: '#0076b9'
  },
  socialPanel: {
    marginTop: 26,
    marginBottom: 26
  },
  submitButton: {
    paddingLeft: 46,
    paddingRight: 46
  },
  alreadyHaveAccountWrapper: {
    marginTop: 20
  },
  alreadyHaveAccountText: {
    color: '#888996',
    textAlign: 'center'
  },
  alreadyHaveAccountLink: {
    color: '#0076b9'
  }
})

const initialFormValues = {
  name: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  phoneNo1: '',
  recaptchaToken: '',
  user: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    phone: ''
  }
}

function SignUp({ t, classes, theme }) {
  const { userData, error: SSOError } = useSelector(ssoSelector)
  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { showSnackbar } = useSnackbar()
  const { darkHeaderLogo, sso, headerLogo } = useWhiteLabel()
  const { state } = useLocation()

  const [loading, setLoading] = useState(false)
  const [agreedToPolicies, setAgreed] = useState(false)
  const [successResponse, setSuccessResponse] = useState(false)
  const [hasSSOData, setHasSSOData] = useState(false)
  const [BEErrors, setBEErrors] = useState({})
  const [fullAddress, setFullAddress] = useState('')
  const [disabledSSOFields, setDisabledSSOFields] = useState({
    firstName: false,
    lastName: false,
    email: false
  })

  const isLightLogo = theme.type === 'dark' && darkHeaderLogo

  const onSubmit = useCallback(
    async values => {
      try {
        const recaptchaToken = await executeRecaptcha('signUp')

        setLoading(true)
        dispatch(
          register({
            data: { ...values, recaptchaToken },
            onSuccess: () => {
              setLoading(false)
              setSuccessResponse(true)
            },
            onFailure: res => {
              setLoading(false)
              const initialFieldsArray = Object.keys(initialFormValues)
                .map(key => [
                  typeof initialFormValues[key] === 'object'
                    ? Object.keys(initialFormValues[key]).map(
                        el => `${key}.${el}`
                      )
                    : key
                ])
                .flat(2)
              if (res.errors.length) {
                res.errorFields.forEach(errorField => {
                  if (
                    !initialFieldsArray.includes(errorField.name) ||
                    errorField.name === 'recaptchaToken'
                  ) {
                    errorField.value.forEach(error => {
                      showSnackbar(error, 'error')
                    })
                  }
                })
                setBEErrors(res.errorFields)
              } else {
                showSnackbar(res.message, 'error')
              }
            }
          })
        )
      } catch (e) {
        setLoading(false)
        showSnackbar(t('Oops.. Something went wrong'), 'error')
        console.error('Unable to initialize ReCaptcha')
      }
    },
    [dispatch, showSnackbar, executeRecaptcha, t]
  )

  const form = useFormik({
    initialValues: initialFormValues,
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().restrictedCharacters().required(requiredField),
      address1: Yup.string().restrictedCharacters().required(requiredField),
      city: Yup.string().restrictedCharacters().required(requiredField),
      state: Yup.string().restrictedCharacters().required(requiredField),
      country: Yup.string().restrictedCharacters().required(requiredField),
      phoneNo1: phoneValidationSchema.required(requiredField),
      zipCode: Yup.string().restrictedCharacters().required(requiredField),
      user: Yup.object().shape({
        firstName: Yup.string().restrictedCharacters().required(requiredField),
        lastName: Yup.string().restrictedCharacters().required(requiredField),
        email: Yup.string().email().required(requiredField),
        password: passwordValidateSchema,
        passwordConfirmation: passwordConfirmValidateSchema,
        phone: phoneValidationSchema
      })
    }),
    onSubmit
  })

  const { isValid, setFieldValue, handleSubmit, setErrors } = form

  useEffect(() => {
    if (userData) {
      const { name, email } = userData
      const [firstName, lastName] = name?.split(' ')
      setFieldValue('user.firstName', firstName)
      setFieldValue('user.lastName', lastName || '')
      setFieldValue('user.email', email)
      setHasSSOData(true)
      setDisabledSSOFields({
        firstName: !!firstName,
        lastName: !!lastName,
        email: !!email
      })
    }
    // eslint-disable-next-line
  }, [userData])

  useEffect(() => {
    if (SSOError) {
      showSnackbar(SSOError, 'error')
    }
    // eslint-disable-next-line
  }, [SSOError])

  useEffect(() => {
    if (Object.keys(BEErrors)?.length) {
      setErrors(errorArrayToObj(BEErrors))
    }
    // eslint-disable-next-line
  }, [BEErrors])

  useEffect(() => {
    return () => {
      dispatch(clearSSOData())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <AccountModal
      formWrapClassName={classNames({
        [classes.wrapperHeight]: !successResponse
      })}
      formContentClassName={classes.formContent}
      denseFooter={true}
    >
      <div className={classes.container}>
        <header>
          <LogoImage
            isLightLogo={isLightLogo}
            darkHeaderLogo={darkHeaderLogo}
            headerLogo={headerLogo}
            logoImageClassName={classes.logoImage}
          />
          <Typography className={classes.formTitle} variant="h2" gutterBottom>
            {t('Free Trial Signup')}
          </Typography>
        </header>
        {successResponse ? (
          <>
            <Typography className={classes.formSuccessMessage} gutterBottom>
              {t('Registration Success Text')}
            </Typography>
            <Typography className={classes.backToText}>
              {t('Back to')}{' '}
              <Link
                to={{ pathname: '/sign-in', state }}
                component={RouterLink}
                className={classes.backToLink}
              >
                {t('Sign In')}
              </Link>
            </Typography>
          </>
        ) : (
          <>
            <Spacing>
              <Form
                classes={classes}
                agreedToPolicies={agreedToPolicies}
                fullAddress={fullAddress}
                setFullAddress={setFullAddress}
                setAgreed={setAgreed}
                hasSSOData={hasSSOData}
                disabledSSOFields={disabledSSOFields}
                {...form}
              />
            </Spacing>
            <Spacing variant={0} justifyContent="center">
              <Container cols="3-4">
                <SubmitButton
                  opaque={!isValid}
                  disabled={!agreedToPolicies}
                  onClick={handleSubmit}
                  isLoading={loading}
                >
                  {t('Sign Up')}
                </SubmitButton>
                <SocialPanel isSignUp renderOptions={sso} />
              </Container>
            </Spacing>
            <Spacing
              variant={2}
              rootClassName={classes.alreadyHaveAccountWrapper}
            >
              <Typography className={classes.alreadyHaveAccountText}>
                {t('Already Have An Account?')}{' '}
                <Link
                  to="/sign-in"
                  component={RouterLink}
                  className={classes.alreadyHaveAccountLink}
                >
                  {t('Login')}
                </Link>
              </Typography>
            </Spacing>
          </>
        )}
      </div>
    </AccountModal>
  )
}

const SignUpWithGoogleReCaptchaProvider = props => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
  >
    <SignUp {...props} />
  </GoogleReCaptchaProvider>
)

export default withTranslation('translations')(
  withStyles(styles)(withTheme(SignUpWithGoogleReCaptchaProvider))
)
