import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import deviceSaga from 'sagas/deviceSaga'

const actions = [types.POST_DEVICE_TYPE_SUCCESS, types.PUT_DEVICE_TYPE_SUCCESS]

const deviceTypeInvalidateCache = () => {
  return actions.map(action => {
    return takeLatest(action, deviceSaga.invalidateDeviceTypeTags)
  })
}

export default deviceTypeInvalidateCache
