import { createAppApi } from 'services/api'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { createSuccessInvalidator, getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'

export const schedulesApi = createAppApi('schedulesQuery', {
  tagTypes: [apiTags.schedule],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      url: '/schedule',
      name: 'Schedule',
      tags: [apiTags.schedule]
    }),
    copySchedules: builder.mutation({
      query: (data = {}) => {
        const { impersonated, ...restData } = data
        return {
          url: '/schedule/bulkCopy',
          method: 'POST',
          data: restData,
          ...(impersonated && getApiParamsByOriginalUser('/schedule/bulkCopy'))
        }
      }
    }),
    postSmartSchedule: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.schedule]),
      query: data => ({
        method: 'POST',
        url: '/schedule/smart',
        data
      })
    }),
    putSmartSchedule: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.schedule]),
      query: ({ id, data }) => ({
        method: 'PUT',
        url: `/schedule/smart/${id}`,
        data
      })
    }),
    buildSmartSchedule: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.schedule]),
      query: data => ({
        method: 'POST',
        url: '/schedule/smart/build',
        data
      })
    })
  })
})

export const {
  useCopySchedulesMutation,
  useLazyGetSchedulesQuery,
  useGetSchedulesQuery,
  useDeleteScheduleMutation,
  usePostSmartScheduleMutation,
  usePutSmartScheduleMutation,
  useBuildSmartScheduleMutation
} = schedulesApi
