import React, {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { withTranslation } from 'react-i18next'
import { _concat, _uniqBy } from 'utils/lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, withStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import classNames from 'classnames'
import FlipMove from 'react-flip-move'
import moment from 'moment'

import { DeviceRow } from './Rows'
import BaseSection from './BaseSection'
import { DeviceFilter } from './FilterForms'
import DeviceSectionModal from './DeviceSectionModal'
import { Text } from 'components/Typography'
import Scrollbars from 'components/Scrollbars'
import { Spacing } from 'components/Containers'
import Container from 'components/Containers/Container'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import FormControlSelectDevice from 'components/Form/FormControlSelectDevice'
import { CircleIconButton } from 'components/Buttons'
import { ScheduleDeviceFeaturesLoader } from 'components/Loaders/DeviceFeaturesLoader'
import { deviceLibraryInitialFilter } from 'reducers/filters'
import {
  clearGetDeviceItemsAction,
  getScheduledDeviceIds,
  resetDeviceFetchParamsAction
} from 'actions/deviceActions'
import { scheduledDeviceIdsSelector } from 'selectors/deviceSelectors'
import { scheduleItemSelector } from 'selectors/scheduleSelectors'
import useFilter from 'hooks/useFilter'
import { useLazyDeviceListQuery } from 'api/deviceApi'
import _sortBy from 'lodash/sortBy'
import { isNumber } from 'utils/generalUtils'
import { queryParamsHelper } from 'utils/index'
import { deviceQueryParamsHelper } from 'utils/queryParamsHelpers'
import { anyParamsModifier, hasActiveValues } from 'utils/filterUtils'
import { isAutoAddedDevice, maxDevicesFitInSection } from 'utils/scheduleUtils'
import { libraryViews } from 'constants/library'
import { scheduleTypes } from 'constants/schedule'
import entityConstants from 'constants/entityConstants'
import { MEDIA_DATE_FORMAT } from 'constants/dateTimeFormats'

const styles = ({ palette, type, spacing }) => ({
  bodyWrapperContent: {
    flex: 1
  },
  scheduleInfoWrap: {
    padding: '20px 20px 0',
    height: '100%'
  },
  mediaItemsWrap: {
    margin: 0,
    height: '100%',
    position: 'relative'
  },
  contentListWrap: {
    height: ' max-content'
  },
  loaderBackgroundWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    background: palette[type].card.greyHeader.background,
    overflow: 'hidden'
  },
  flipMode: {
    display: 'grid',
    width: 'inherit'
  },
  gridColumns2: {
    gridTemplateColumns: `repeat(2, calc(50% - ${spacing(1)}px))`
  },
  gridColumns3: {
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  gridGap2: {
    gap: spacing(2)
  },
  circleIcon: {
    height: 'fit-content',
    width: 'fit-content'
  }
})

const deviceFetchParams = {
  sort: 'alias',
  order: 'asc'
}

const DeviceSection = ({
  startDate,
  endDate,
  selectedDevices,
  handleSelectedDevicesChange,
  assignType,
  disableTypeSelection,
  fullWidth,
  withHeaderCard,
  cols = 2,
  colGridSize = 6,
  maxTitleWidth,
  loader: Loader = ScheduleDeviceFeaturesLoader,
  onChange,
  hideSchedulePopup,
  classes,
  emptyPlaceholderRootClass = '',
  rootClassName = '',
  error,
  t,
  hasSelectedItems,
  scheduleType,
  deviceRowComponent: DeviceRowComponent = DeviceRow,
  listContainerSpacing = 3,
  gridGap = 0,
  headerTitleComponent,
  view
}) => {
  const dispatch = useDispatch()
  const scheduleItem = useSelector(scheduleItemSelector)
  const { response: scheduledDeviceIds } = useSelector(
    scheduledDeviceIdsSelector
  )

  const [filterParams, updateFilters, resetFilters] = useFilter(
    entityConstants.DeviceLibrary + entityConstants.ScheduleLibrary
  )

  const [deviceList, setDeviceList] = useState([])
  const [deviceModal, setDeviceModal] = useState(false)
  const [modalSelectedDevices, setModalSelectedDevices] = useState([])
  const modifiedQueryParams = useMemo(
    () => anyParamsModifier(filterParams, ['status'], true),
    [filterParams]
  )

  const itemsLimit = useMemo(
    () =>
      maxDevicesFitInSection(view === libraryViews.list ? 60 : 48, 16) * cols,
    [cols, view]
  )

  const [
    fetchBasicDevices,
    { data: { data: response = [], meta = {} } = {}, isFetching }
  ] = useLazyDeviceListQuery()

  const fetchDevices = useCallback(
    (params = {}) => {
      fetchBasicDevices(
        deviceQueryParamsHelper({
          limit: itemsLimit,
          ...params,
          ...deviceFetchParams,
          include: ['locationImage']
        })
      )
    },
    [fetchBasicDevices, itemsLimit]
  )

  const fetchScheduledDevices = (params = {}) => {
    if (moment(startDate, MEDIA_DATE_FORMAT, true).isValid()) {
      dispatch(
        getScheduledDeviceIds(
          queryParamsHelper(
            {
              ...deviceFetchParams,
              ...filterParams,
              'startDate-from': startDate.format('YYYY-MM-DD'),
              ...(endDate &&
                moment(endDate, MEDIA_DATE_FORMAT, true).isValid() && {
                  'endDate-to': endDate
                }),
              ...params,
              mediaFeature: null
            },
            ['group', 'tag']
          )
        )
      )
    }
  }

  const handleSubmitFilter = useCallback(
    values => {
      const modifiedValues = anyParamsModifier(
        { ...deviceLibraryInitialFilter, ...values },
        ['status'],
        false
      )

      const updateValues = { ...modifiedValues }
      if (!isNumber(updateValues?.clientId)) {
        updateValues.clientName = updateValues?.clientId
        updateValues.clientId = ''
      } else {
        updateValues.clientName = ''
      }

      setDeviceList([])
      updateFilters(updateValues)
      fetchDevices(updateValues)
    },
    [updateFilters, fetchDevices]
  )

  const handleResetFilter = useCallback(() => {
    setDeviceList([])
    resetFilters()
    fetchDevices()
  }, [resetFilters, fetchDevices])

  const {
    values: filterValues,
    setFieldValue,
    handleSubmit,
    setValues,
    handleChange,
    handleReset
  } = useFormik({
    initialValues: modifiedQueryParams,
    onSubmit: handleSubmitFilter,
    onReset: handleResetFilter
  })

  const handleNameChange = useCallback(
    ({ target: { value, __isNew__, label } }) => {
      setFieldValue('id', __isNew__ ? '' : value)
      setFieldValue('name', label)
      handleSubmit()
    },
    [setFieldValue, handleSubmit]
  )

  useEffect(
    () => {
      resetFilters()
      if (view) {
        fetchDevices()
      }
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    return () => {
      dispatch(resetDeviceFetchParamsAction())
      dispatch(clearGetDeviceItemsAction())
      resetFilters()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!response.length || isFetching) return
    if (meta.currentPage > 1) {
      setDeviceList(prevState => prevState.concat(response))
    } else {
      if (modalSelectedDevices.length === 0) setDeviceList(response)
      else {
        const list = _uniqBy(_concat(response, modalSelectedDevices), 'id')
        setDeviceList(list)
      }
    }
  }, [isFetching, meta, modalSelectedDevices, response])

  useEffect(() => {
    setValues(anyParamsModifier(filterParams, ['status'], true))
    // eslint-disable-next-line
  }, [filterParams])

  useEffect(
    () => {
      if (deviceList.length && !isFetching) {
        fetchScheduledDevices({
          limit: meta.currentPage * meta.perPage
        })
      }
    },
    // eslint-disable-next-line
    [startDate, endDate]
  )

  useEffect(() => {
    if (response && !isFetching) {
      fetchScheduledDevices({
        page: meta.currentPage,
        limit: meta.perPage
      })
    }
    // eslint-disable-next-line
  }, [response])

  const isFilterActive = useMemo(() => hasActiveValues(filterValues), [
    filterValues
  ])

  const backendSelectedDevices = useMemo(
    () => scheduleItem?.response?.deviceList || [],
    [scheduleItem]
  )

  const orderedDeviceList = useMemo(() => {
    const data = deviceList.map(device => {
      if (selectedDevices.includes(device.id)) {
        return { ...device, selected: true }
      } else {
        return device
      }
    })

    return _sortBy(data, ['selected'])
  }, [deviceList, selectedDevices])

  const uniqueDevices = useMemo(
    () =>
      isFilterActive
        ? orderedDeviceList
        : _uniqBy(
            _concat(
              backendSelectedDevices,
              orderedDeviceList,
              modalSelectedDevices
            ),
            'id'
          ),
    [
      isFilterActive,
      orderedDeviceList,
      backendSelectedDevices,
      modalSelectedDevices
    ]
  )

  const devicesToRender = useMemo(() => {
    const cutLastRow =
      meta?.currentPage !== meta?.lastPage ||
      backendSelectedDevices.length > itemsLimit
    const numberOfDevicesToShow = itemsLimit - (cutLastRow ? 1 * cols : 0)

    return uniqueDevices.slice(0, numberOfDevicesToShow)
  }, [uniqueDevices, meta, cols, itemsLimit, backendSelectedDevices])

  useEffect(() => {
    if (
      !response.length ||
      (view === libraryViews.grid &&
        response.length < itemsLimit &&
        meta?.currentPage !== meta?.lastPage)
    ) {
      if (devicesToRender.length) {
        setTimeout(() => fetchDevices(), 400) // smooth animation before fetch
      } else {
        fetchDevices()
      }
    }
    // eslint-disable-next-line
  }, [view])

  const isDeviceAutoAdded = useCallback(
    device =>
      isAutoAddedDevice(device.id, scheduleItem?.response?.scheduleDevice),
    [scheduleItem]
  )

  const isDeviceDisabled = useCallback(
    device => {
      return (
        isDeviceAutoAdded(device) &&
        scheduleType !== scheduleTypes.Failover.name
      )
    },
    [scheduleType, isDeviceAutoAdded]
  )

  const allSelected = useMemo(
    () =>
      devicesToRender.length &&
      devicesToRender.every(
        device => isDeviceAutoAdded(device) || device.selected
      ),
    [devicesToRender, isDeviceAutoAdded]
  )

  const handleToggleAllDevices = () => {
    if (allSelected) {
      handleSelectedDevicesChange(
        devicesToRender.filter(device => !isDeviceDisabled(device))
      )
    } else {
      handleSelectedDevicesChange(
        devicesToRender.filter(
          device => !isDeviceDisabled(device) && !device.selected
        )
      )
    }
  }

  const hiddenSelectedDevices = useMemo(
    () =>
      selectedDevices.length -
      devicesToRender.filter(({ id }) => selectedDevices.includes(id)).length,
    [selectedDevices, devicesToRender]
  )

  const renderDevices = useMemo(() => {
    return devicesToRender.map(item => {
      const autoAdded = isAutoAddedDevice(
        item.id,
        scheduleItem?.response?.scheduleDevice
      )
      const disabled = autoAdded && scheduleType !== scheduleTypes.Failover.name

      return (
        <Grid key={item.id}>
          <DeviceRowComponent
            {...(disabled && {
              disabledReason: t('Auto added via Group / Tag / Location')
            })}
            disabled={disabled}
            gridSize={12}
            item={item}
            key={item.id}
            name={item.alias || item.name}
            maxTitleWidth={maxTitleWidth}
            isScheduled={scheduledDeviceIds.includes(item.id)}
            checkboxValue={!!autoAdded || selectedDevices?.includes(item.id)}
            onChange={handleSelectedDevicesChange}
            hideSchedulePopup={hideSchedulePopup}
          />
        </Grid>
      )
    })
  }, [
    scheduleItem?.response?.scheduleDevice,
    scheduleType,
    t,
    maxTitleWidth,
    scheduledDeviceIds,
    selectedDevices,
    handleSelectedDevicesChange,
    hideSchedulePopup,
    devicesToRender
  ])

  const filterComponent = useMemo(
    () => (
      <DeviceFilter
        values={filterValues}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onReset={handleReset}
      />
    ),
    [filterValues, handleChange, handleSubmit, handleReset]
  )

  const titleSearchComponent = useMemo(
    () => (
      <FormControlSelectDevice
        name="name"
        label={''}
        onChange={handleNameChange}
        placeholder={t('Search')}
        value={filterValues.name}
        marginBottom={false}
        isClearable
        hasCopy
        returnId
        useLabel
      />
    ),
    [handleNameChange, filterValues, t]
  )

  const renderFilterComponent = useCallback(
    close => {
      return cloneElement(filterComponent, { close })
    },
    [filterComponent]
  )

  const openDeviceModalHandler = () => {
    setDeviceModal(true)
  }

  const closeDeviceModalHandler = devices => {
    setModalSelectedDevices(devices)
    setDeviceModal(false)
  }

  return (
    <>
      <BaseSection
        filterComponent={filterComponent}
        titleSearchComponent={titleSearchComponent}
        title={t('Select Devices')}
        assignType={assignType}
        disableTypeSelection={disableTypeSelection}
        onChange={onChange}
        fullWidth={fullWidth}
        withHeaderCard={withHeaderCard}
        rootClassName={rootClassName}
        error={error}
        hasSelectedItems={hasSelectedItems}
        scheduleType={scheduleType}
        {...(headerTitleComponent && {
          titleComponent: headerTitleComponent({
            handleToggleAllDevices,
            allSelected
          })
        })}
      >
        <div className={classes.bodyWrapperContent}>
          <div className={classNames(classes.mediaItemsWrap, 'popupBoundary')}>
            {(isFetching || scheduleItem.isFetching) && (
              <div className={classes.loaderBackgroundWrap}>
                <Loader rowsCount={Math.floor(itemsLimit / cols)} />
              </div>
            )}

            {!isFetching && !response.length ? (
              <EmptyPlaceholder
                text={t('No Results Found')}
                rootClassName={emptyPlaceholderRootClass}
              />
            ) : (
              <Scrollbars>
                <Container className={classes.scheduleInfoWrap}>
                  <Grid
                    container
                    className={classes.contentListWrap}
                    spacing={listContainerSpacing}
                  >
                    <FlipMove
                      duration={400}
                      easing="ease-in-out"
                      className={classNames(
                        classes.flipMode,
                        classes[`gridColumns${cols}`],
                        classes[`gridGap${gridGap}`]
                      )}
                    >
                      {renderDevices}
                    </FlipMove>
                    {(meta?.currentPage !== meta?.lastPage ||
                      hiddenSelectedDevices > 0) && (
                      <Spacing
                        variant={0}
                        item
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        paddingTop={2}
                      >
                        {hiddenSelectedDevices > 0 && (
                          <Text>
                            {t('and count more selected', {
                              count: hiddenSelectedDevices
                            })}
                          </Text>
                        )}
                        <CircleIconButton
                          onClick={openDeviceModalHandler}
                          className={classNames(
                            `hvr-grow ${classes.circleIcon}`
                          )}
                        >
                          <i className="icon-add-circle-1" />
                        </CircleIconButton>
                      </Spacing>
                    )}
                  </Grid>
                </Container>
              </Scrollbars>
            )}
          </div>
        </div>
      </BaseSection>
      <DeviceSectionModal
        titleSearchComponent={titleSearchComponent}
        renderFilterComponent={renderFilterComponent}
        open={deviceModal}
        onCloseModal={closeDeviceModalHandler}
        loader={Loader}
        selectedDevices={selectedDevices}
        filterParams={filterParams}
        backendSelectedDevices={backendSelectedDevices}
        isFilterActive={isFilterActive}
        deviceRowComponent={DeviceRowComponent}
        onChange={handleSelectedDevicesChange}
        hideSchedulePopup={hideSchedulePopup}
        isDeviceAutoAdded={isDeviceAutoAdded}
        isDeviceDisabled={isDeviceDisabled}
      />
    </>
  )
}

export default withStyles(styles)(
  withTranslation('translations')(DeviceSection)
)
