import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import contentAppHelpSaga from 'sagas/contentAppHelpSaga'

const actions = [types.PUT_CONTENT_APP_HELP_SUCCESS]

const contentAppHelpInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, contentAppHelpSaga.invalidateContentAppHelpTags)
  )

export default contentAppHelpInvalidateCache
