import React, { useEffect, useMemo } from 'react'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import moment from 'moment'

import {
  billingNotificationMessages,
  billingNotificationTypes
} from 'constants/api'
import useTopBanner from 'hooks/api/useTopBanner'
import { TextWithTooltip } from 'components/Typography'

const styles = () => {
  return {
    root: {
      top: 0,
      width: '100%',
      height: 35,
      display: 'flex',
      zIndex: 112,
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#cb3939',
      overflow: 'hidden'
    },
    twoMessagesRoot: {
      flexDirection: 'column',
      height: 80,
      gap: 10
    },
    text: {
      fontSize: 16,

      '& a': {
        color: '#fff'
      }
    }
  }
}

const TopBanner = ({ classes, t }) => {
  const {
    visible,
    data,
    hidePreview,
    isTrial,
    numberOfMessages
  } = useTopBanner()

  const message = useMemo(() => {
    const { deactivateNotificationType, deactivateMessage } = data

    return deactivateNotificationType === billingNotificationTypes.custom &&
      deactivateMessage
      ? deactivateMessage
      : t(
          billingNotificationMessages[deactivateNotificationType] ||
            billingNotificationMessages[billingNotificationTypes.normal]
        )
  }, [data, t])

  const trialMessage = useMemo(() => {
    if (!isTrial) {
      return null
    }

    const message = `${t('Your Free Trial account will expire', {
      days: data?.expirationDate
        ? moment(data.expirationDate).diff(moment(), 'days')
        : 0
    })}. ${t('To continue service')}, `

    return (
      <>
        {message}
        <a href="https://mvix.com/contact-us/" target="_blank" rel="noreferrer">
          {t('Contact a Solutions Consultant')}
        </a>
      </>
    )
  }, [isTrial, data?.expirationDate, t])

  useEffect(() => {
    hidePreview()
    // eslint-disable-next-line
  }, [data])

  if (!visible) {
    return null
  }

  return (
    <div
      className={classNames(classes.root, {
        [classes.twoMessagesRoot]: numberOfMessages === 2
      })}
    >
      {!!data.deactivateNotificationType && (
        <TextWithTooltip
          maxWidth={1600}
          color="white"
          weight="bold"
          rootClassName={classes.text}
        >
          {message}
        </TextWithTooltip>
      )}

      {isTrial && (
        <TextWithTooltip
          maxWidth={1600}
          color="white"
          weight="bold"
          rootClassName={classes.text}
        >
          {trialMessage}
        </TextWithTooltip>
      )}
    </div>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(TopBanner)
