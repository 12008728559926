import React from 'react'

import {
  DEFAULT_NOTIFICATION_DURATION,
  DEFAULT_TRANSITION_DURATION
} from 'constants/api'
import { Fade } from '@material-ui/core'

export default function useCustomSnackbar(
  t,
  snackbarProvider,
  closeFunction,
  autoHideDuration = DEFAULT_NOTIFICATION_DURATION,
  applyTransitions = false,
  transitionDuration = DEFAULT_TRANSITION_DURATION
) {
  return function (
    title,
    variant = 'default',
    buttonText,
    translateVariables,
    style
  ) {
    return snackbarProvider(
      typeof title === 'string' ? t(title, translateVariables) : title,
      {
        ...(applyTransitions && {
          TransitionComponent: props => (
            <Fade {...props} timeout={transitionDuration} />
          )
        }),
        variant: variant,
        preventDuplicate: true,
        autoHideDuration,
        ...(style && { style: { ...style } })
      }
    )
  }
}
