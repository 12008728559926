import { memo, useEffect } from 'react'
import socket from 'services/socket'
import { useSnackbar as _useSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useCustomSnackbar } from 'hooks'
import { DEVICE_IS_OFFLINE } from 'constants/deviceConstants'
import {
  deleteAlertTriggerDataAction,
  deleteCapAlertDataAction,
  deleteIpawsAlertDataAction,
  updateAlertTriggerDataAction,
  updateCapAlertDataAction,
  updateIpawsAlertDataAction
} from 'actions/alertActions'
import useAlertNotification from 'hooks/useAlertNotification'

const SocketNotification = ({ t }) => {
  const { enqueueSnackbar, closeSnackbar } = _useSnackbar()
  const snackbar = useCustomSnackbar(t, enqueueSnackbar, closeSnackbar)

  const [
    alertDevicesDataReducer,
    postAlertTriggerReducer,
    postCapAlertReducer,
    postIpawsAlertReducer
  ] = useSelector(({ alert }) => [
    alert.alertDevicesData,
    alert.postAlertTrigger,
    alert.associateCapAlert,
    alert.associateIpawsAlert
  ])

  useEffect(() => {
    socket.on('reboot', ({ data: { status, deviceAlias } }) => {
      if (status === 'successfully') {
        snackbar('Device successfully rebooted', 'success', null, {
          deviceName: deviceAlias
        })
      }
      if (status === 'error') {
        snackbar(DEVICE_IS_OFFLINE, 'warning', null, { name: deviceAlias })
      }
    }) // eslint-disable-next-line
  }, [])

  useAlertNotification({
    triggerReducer: postAlertTriggerReducer,
    devicesList: alertDevicesDataReducer.alertTriggerDevices,
    updateDeviceListAction: updateAlertTriggerDataAction,
    clearDeviceListAction: deleteAlertTriggerDataAction,
    showSnackbar: snackbar
  })
  useAlertNotification({
    triggerReducer: postCapAlertReducer,
    devicesList: alertDevicesDataReducer.capAlertDevices,
    updateDeviceListAction: updateCapAlertDataAction,
    clearDeviceListAction: deleteCapAlertDataAction,
    showSnackbar: snackbar
  })
  useAlertNotification({
    triggerReducer: postIpawsAlertReducer,
    devicesList: alertDevicesDataReducer.ipawsAlertDevices,
    updateDeviceListAction: updateIpawsAlertDataAction,
    clearDeviceListAction: deleteIpawsAlertDataAction,
    showSnackbar: snackbar
  })

  return null
}

export default withTranslation('translations')(memo(SocketNotification))
