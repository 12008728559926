import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'

export const bandwidthPackageApi = createAppApi('bandwidthPackageQuery', {
  tagTypes: [apiTags.bandwidthPackage],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      name: 'BandwidthPackage',
      tags: [apiTags.bandwidthPackage],
      url: '/bandwidthPackage'
    })
  })
})

export const {
  useLazyGetBandwidthPackagesQuery,
  useGetBandwidthPackagesQuery,
  useDeleteBandwidthPackageMutation
} = bandwidthPackageApi
