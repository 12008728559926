import React, { useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { _get } from 'utils/lodash'

import { emailToScreenFeatureDisabled, getThumbnail } from 'utils/mediaUtils'
import { mediaConstants, permissionNames } from 'constants/index'
import { useUserRole } from 'hooks/tableLibrary'
import { CLIENT_ADMIN } from 'constants/roles'
import { ENTERPRISE_ROLE, SYSTEM_ROLE } from 'constants/api'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import MediaMoreInfoCard from './MediaMoreInfoCard'
import { isApproved } from 'utils/libraryUtils'
import GridViewCard, { MediaCardBottomInfo } from 'components/Card/GridViewCard'
import permissions from 'constants/permissionsMap'

const MediaCard = ({
  t,
  item,
  isSelected = false,
  onToggleSelect = f => f,
  onDelete = f => f,
  onEdit = f => f,
  onApprove = f => f,
  onClone = f => f,
  onAddToPlaylist = f => f,
  onShare = f => f,
  onCopy = f => f,
  onPreview = f => f,
  getPermissionByName,
  getImpersonatePermissionByName,
  mediaPermission,
  isRootPath
}) => {
  const { id, title } = item

  const role = useUserRole()

  const actions = useMemo(() => {
    const { inactive } = mediaConstants.mediaStatus
    const emailFeatureDisabled = emailToScreenFeatureDisabled({ row: item })
    const featureDisabled =
      item?.feature?.status === inactive || emailFeatureDisabled

    const editData = onEdit(item)

    return [
      {
        label: t('Approve'),
        clickAction: onApprove,
        disabled: featureDisabled,
        icon: 'fa-regular fa-circle-check',
        render:
          mediaPermission.approve &&
          role.level === CLIENT_ADMIN &&
          !isApproved(item)
      },
      {
        label: t('Clone'),
        clickAction: onClone,
        icon: 'fa fa-clone',
        disabled: featureDisabled || !_get(item, 'permission.write', false),
        render: isApproved(item)
      },
      {
        label: t('Add to Playlist Media action'),
        clickAction: onAddToPlaylist,
        icon: 'fa-regular fa-circle-plus',
        disabled:
          featureDisabled ||
          !getPermissionByName(...permissions.playlists.read) ||
          !getPermissionByName(...permissions.playlists.create),
        render: isApproved(item)
      },
      {
        label: t('Edit action'),
        ...(editData.url && { to: { pathname: editData.url } }),
        ...(!!(!editData.url && editData.onClick) && {
          clickAction: editData.onClick
        }),
        icon: 'fa-sharp fa-regular fa-pen-to-square',
        render: mediaPermission.update,
        disabled: featureDisabled || !_get(item, 'permission.write', false)
      },
      {
        label: t('Share'),
        clickAction: onShare,
        icon: 'fa-regular fa-share-nodes',
        disabled: featureDisabled,
        render:
          [ENTERPRISE_ROLE, SYSTEM_ROLE].includes(getUserRoleLevel()) &&
          mediaPermission.other &&
          isApproved(item)
      },
      {
        label: t('Copy'),
        clickAction: onCopy,
        icon: 'fa fa-copy',
        disabled: featureDisabled,
        render: [ENTERPRISE_ROLE, SYSTEM_ROLE].includes(getUserRoleLevel())
          ? getPermissionByName(
              permissionNames.SYSTEM_MEDIA_COPY,
              permissionNames.ENTERPRISE_MEDIA_COPY
            )
          : getImpersonatePermissionByName(
              permissionNames.SYSTEM_MEDIA_COPY,
              permissionNames.ENTERPRISE_MEDIA_COPY
            )
      },
      {
        label: t('Delete action'),
        icon: 'fa-regular fa-trash-can',
        clickAction: onDelete,
        render: mediaPermission.delete,
        disabled: !_get(item, 'permission.delete', false) || featureDisabled
      }
    ]
  }, [
    onEdit,
    onDelete,
    onApprove,
    onClone,
    onAddToPlaylist,
    onShare,
    onCopy,
    getImpersonatePermissionByName,
    getPermissionByName,
    mediaPermission,
    item,
    role.level,
    t
  ])

  const handleSelect = useCallback(
    event => {
      if (!emailToScreenFeatureDisabled({ row: item })) {
        onToggleSelect(event, id)
      }
    },
    [id, onToggleSelect, item]
  )

  return (
    <GridViewCard
      title={title}
      titleHoverComponent={<MediaMoreInfoCard media={item} />}
      thumbnail={getThumbnail(item)}
      thumbnailIcon={item.feature?.icon}
      thumbnailColor={item.feature?.color}
      item={item}
      selected={isSelected}
      onSelect={handleSelect}
      onPreview={onPreview}
      bottomComponent={<MediaCardBottomInfo media={item} />}
      actions={actions}
      animationAllowed={isRootPath}
    />
  )
}

export default withTranslation('translations')(MediaCard)
