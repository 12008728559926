import React from 'react'
import PropTypes from 'prop-types'
import GreyCard from 'components/Card/GreyCard'
import Text from 'components/Typography/Text'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from 'components/Icons/Icon'
import classNames from 'classnames'

function styles() {
  return {
    hover: {
      cursor: 'pointer'
    }
  }
}

function MessageCard({
  message,
  messageComponent,
  icon,
  marginBottom,
  onClick,
  classes,
  iconRootClassName = '',
  textRootClassName = '',
  rootClassName = '',
  textClassName = '',
  whiteSpace
}) {
  return (
    <GreyCard
      header={false}
      marginBottom={marginBottom}
      contentSpacingProps={{
        paddingVert: 1,
        paddingHor: 1
      }}
      rootClassName={rootClassName}
    >
      <Grid
        container
        onClick={onClick}
        className={classNames({
          [classes.hover]: !!onClick
        })}
      >
        <Grid container item xs={1} className={iconRootClassName}>
          <Icon icon={icon} color="blue" />
        </Grid>
        <Grid container item xs={11} className={textRootClassName}>
          {messageComponent ? (
            messageComponent
          ) : (
            <Text
              color="light"
              rootClassName={textClassName}
              whiteSpace={whiteSpace}
            >
              {message}
            </Text>
          )}
        </Grid>
      </Grid>
    </GreyCard>
  )
}

MessageCard.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  marginBottom: PropTypes.bool,
  onClick: PropTypes.func
}

export default withStyles(styles)(MessageCard)
