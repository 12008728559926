import { call, put, all } from 'redux-saga/effects'

import * as types from '../actions'

import { deviceService } from '../services'
import {
  capAlertDevicesError,
  capAlertDevicesSuccess
} from 'actions/deviceActions'
import { transformMeta } from 'utils/tableUtils'
import { UNGROUPED_LOCATION_ID } from 'constants/libraryConstants'
import { shapeOfMeta } from 'constants/initialLibraryState'
import apiTags from 'constants/apiTags'
import { deviceApi } from 'api/deviceApi'
import { configApi } from 'api/configApi'
import { ENTERPRISE_ROLE } from 'constants/api'

function* getItems({ params, silent }) {
  try {
    const response = yield call(deviceService.getItems, params, silent)
    yield put({
      type: types.GET_DEVICE_ITEMS_SUCCESS,
      payload: {
        response: { ...response, meta: transformMeta(response.meta) },
        params: params
      }
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_ITEMS_ERROR, payload: error })
  }
}

function* getPreference() {
  try {
    const response = yield call(deviceService.getPreference)
    yield put({
      type: types.GET_DEVICE_PREFERENCE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_PREFERENCE_ERROR, payload: error })
  }
}

function* putPreference(action) {
  try {
    yield call(deviceService.putPreference, action.payload)
    yield put({ type: types.GET_DEVICE_PREFERENCE })
  } catch (error) {
    yield put({ type: types.PUT_DEVICE_PREFERENCE_ERROR, payload: error })
  }
}

function* getGroups(action) {
  try {
    const response = yield call(deviceService.getGroups, action.payload)
    yield put({ type: types.GET_DEVICE_GROUPS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_GROUPS_ERROR, payload: error })
  }
}

function* putItem(action) {
  try {
    const { impersonated, silent, originalUser } = action.payload.meta
    let response = null

    if (impersonated) {
      response = yield call(deviceService.putItemImpersonated, action.payload)
    } else {
      response = yield call(
        deviceService.putItem,
        action.payload,
        originalUser?.role.level === ENTERPRISE_ROLE
      )
    }

    if (!silent) {
      yield put({ type: types.PUT_DEVICE_ITEM_SUCCESS, payload: response })
    }
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_ITEM_ERROR,
      payload: { ...error, payload: action.payload }
    })
  }
}

function* postItem(action) {
  try {
    const response = yield call(deviceService.postItem, action.payload)
    yield put({ type: types.POST_DEVICE_ITEM_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: types.POST_DEVICE_ITEM_ERROR,
      payload: { ...error, payload: action.payload }
    })
  }
}

function* getItem(action) {
  try {
    const response = yield call(deviceService.getItem, action.payload)
    yield put({ type: types.GET_DEVICE_ITEM_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_ITEMS_ERROR, payload: error })
  }
}

function* deleteItem(action) {
  try {
    yield call(deviceService.deleteItem, action.payload)
    yield put({
      type: types.DELETE_DEVICE_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.DELETE_DEVICE_ITEM_ERROR, payload: error })
  }
}

function* getPreview(action) {
  try {
    const response = yield call(deviceService.getPreview, action.payload)
    yield put({ type: types.GET_DEVICE_PREVIEW_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_PREVIEW_ERROR, payload: error })
  }
}

function* getDeviceLocationCategories() {
  try {
    const response = yield call(deviceService.getDeviceLocationCategories)
    yield put({
      type: types.GET_DEVICE_LOCATION_CATEGORIES_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_LOCATION_CATEGORIES_ERROR,
      payload: error
    })
  }
}

function* getGroupItems(action) {
  try {
    const response = yield call(
      deviceService.getGroupItems,
      action.payload.id,
      action.payload.params
    )
    yield put({ type: types.GET_DEVICE_GROUP_ITEMS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_GROUP_ITEMS_ERROR, payload: error })
  }
}

function* postGroupItem(action) {
  try {
    const response = yield call(deviceService.postGroupItem, action.payload)
    yield put({ type: types.POST_DEVICE_GROUP_ITEM_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.POST_DEVICE_GROUP_ITEM_ERROR, payload: error })
  }
}

function* deleteGroupItem(action) {
  try {
    const response = yield call(deviceService.deleteGroupItem, action.payload)
    yield put({
      type: types.DELETE_DEVICE_GROUP_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.DELETE_DEVICE_GROUP_ITEM_ERROR, payload: error })
  }
}

function* getReboot(action) {
  try {
    const response = yield call(deviceService.getReboot, action.payload)
    yield put({ type: types.GET_DEVICE_REBOOT_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_REBOOT_ERROR, payload: error })
  }
}

function* putReboot(action) {
  try {
    yield call(deviceService.putReboot, action.payload)
    yield put({
      type: types.PUT_DEVICE_REBOOT_SUCCESS,
      payload: { status: 'success', deviceId: action.payload.id }
    })
  } catch (error) {
    yield put({ type: types.PUT_DEVICE_REBOOT_ERROR, payload: error })
  }
}

function* putPeriodicalReboot(action) {
  try {
    yield call(deviceService.putPeriodicalReboot, action.payload)
    yield put({
      type: types.PUT_PERIODICAL_DEVICE_REBOOT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_PERIODICAL_DEVICE_REBOOT_ERROR,
      payload: error
    })
  }
}

function* getScreenPowerSettings(action) {
  try {
    const response = yield call(
      deviceService.getScreenPowerSettings,
      action.payload
    )
    yield put({
      type: types.GET_SCREEN_POWER_SETTINGS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_SCREEN_POWER_SETTINGS_ERROR, payload: error })
  }
}

function* putScreenPowerSettings(action) {
  try {
    yield call(deviceService.putScreenPowerSettings, action.payload)
    yield put({
      type: types.PUT_SCREEN_POWER_SETTINGS_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_SCREEN_POWER_SETTINGS_ERROR,
      payload: error
    })
  }
}

function* putBulkReboot(action) {
  try {
    yield call(deviceService.putBulkReboot, action.payload)
    yield put({
      type: types.PUT_DEVICE_BULK_REBOOT_SUCCESS,
      payload: {
        status: 'success',
        deviceIds: action.payload.data.deviceIds
      }
    })
  } catch (error) {
    yield put({ type: types.PUT_DEVICE_BULK_REBOOT_ERROR, payload: error })
  }
}

function* putBulkPeriodicalReboot(action) {
  try {
    yield call(deviceService.putBulkPeriodicalReboot, action.payload)
    yield put({
      type: types.PUT_DEVICE_BULK_PERIODICAL_REBOOT_SUCCESS,
      payload: {
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_BULK_PERIODICAL_REBOOT_ERROR,
      payload: error
    })
  }
}

function* getDeviceNotes({ id }) {
  try {
    const response = yield call(deviceService.getDeviceNotes, id)
    yield put({ type: types.GET_DEVICE_NOTES_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_NOTES_ERROR, payload: error })
  }
}

function* postDeviceNote({ id, data }) {
  try {
    const response = yield call(deviceService.postDeviceNote, id, data)
    yield put({ type: types.POST_DEVICE_NOTE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.POST_DEVICE_NOTE_ERROR, payload: error })
  }
}

function* getCapAlertDevicesWorker() {
  try {
    const response = yield call(deviceService.getCapAlertDevices)
    yield put(capAlertDevicesSuccess(response))
  } catch (error) {
    yield put(capAlertDevicesError(error))
  }
}

function* getDeviceTypes({ payload: params }) {
  try {
    const response = yield call(deviceService.getDeviceTypes, params)
    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_DEVICE_TYPES_SUCCESS,
      payload: { ...response, meta: modifiedMeta }
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_TYPES_ERROR, payload: error })
  }
}

function* getDeviceType({ payload: id, appendToLibrary }) {
  try {
    const response = yield call(deviceService.getDeviceType, id)
    yield put({
      type: types.GET_DEVICE_TYPE_SUCCESS,
      payload: response,
      appendToLibrary
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_TYPE_ERROR,
      payload: error,
      appendToLibrary
    })
  }
}

function* putDeviceType({ payload }) {
  try {
    const response = yield call(deviceService.putDeviceType, payload)
    yield put({ type: types.PUT_DEVICE_TYPE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.PUT_DEVICE_TYPE_ERROR, payload: error })
  }
}

function* postDeviceType({ payload: data }) {
  try {
    const response = yield call(deviceService.postDeviceType, data)
    yield put({ type: types.POST_DEVICE_TYPE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.POST_DEVICE_TYPE_ERROR, payload: error })
  }
}

function* getDeviceModels({ payload: params }) {
  try {
    const response = yield call(deviceService.getDeviceModels, params)
    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_DEVICE_MODELS_SUCCESS,
      payload: { ...response, meta: modifiedMeta }
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_MODELS_ERROR, payload: error })
  }
}

function* getDeviceModel({ payload: id, appendToLibrary }) {
  try {
    const response = yield call(deviceService.getDeviceModel, id)
    yield put({
      type: types.GET_DEVICE_MODEL_SUCCESS,
      payload: response,
      appendToLibrary
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_MODEL_ERROR,
      payload: error,
      appendToLibrary
    })
  }
}

function* putDeviceModel({ payload }) {
  try {
    const response = yield call(deviceService.putDeviceModel, payload)
    yield put({ type: types.PUT_DEVICE_MODEL_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.PUT_DEVICE_MODEL_ERROR, payload: error })
  }
}

function* postDeviceModel({ payload: data }) {
  try {
    const response = yield call(deviceService.postDeviceModel, data)
    yield put({ type: types.PUT_DEVICE_MODEL_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.PUT_DEVICE_MODEL_ERROR, payload: error })
  }
}

function* getAppVersions({ payload: params }) {
  try {
    const response = yield call(deviceService.getAppVersions, params)
    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_APP_VERSIONS_SUCCESS,
      payload: { ...response, meta: modifiedMeta }
    })
  } catch (error) {
    yield put({ type: types.GET_APP_VERSIONS_ERROR, payload: error })
  }
}

function* getReleaseAppVersions({ payload: params }) {
  try {
    const response = yield call(deviceService.getReleaseAppVersions, params)
    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_RELEASE_APP_VERSIONS_SUCCESS,
      payload: { ...response, meta: modifiedMeta }
    })
  } catch (error) {
    yield put({ type: types.GET_RELEASE_APP_VERSIONS_ERROR, payload: error })
  }
}

function* getAppVersion({ payload: id }) {
  try {
    const response = yield call(deviceService.getAppVersion, id)
    yield put({ type: types.GET_APP_VERSION_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_APP_VERSION_ERROR, payload: error })
  }
}

function* putAppVersion({ payload }) {
  try {
    const response = yield call(deviceService.putAppVersion, payload)
    yield put({ type: types.PUT_APP_VERSION_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.PUT_APP_VERSION_ERROR, payload: error })
  }
}

function* postAppVersion({ payload: data }) {
  try {
    const response = yield call(deviceService.postAppVersion, data)
    yield put({ type: types.POST_APP_VERSION_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.POST_APP_VERSION_ERROR, payload: error })
  }
}

function* deleteAppVersion(action) {
  try {
    const response = yield call(deviceService.deleteAppVersion, action.payload)
    yield put({
      type: types.DELETE_APP_VERSION_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.DELETE_APP_VERSION_ERROR, payload: error })
  }
}

function* deleteSelectedAppVersions({ ids }) {
  try {
    yield all(ids.map(id => call(deviceService.deleteAppVersion, id)))
    yield put({
      type: types.DELETE_SELECTED_APP_VERSIONS_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_APP_VERSIONS_ERROR,
      payload: error
    })
  }
}

function* postAssignAppVersion({ payload: data }) {
  try {
    const response = yield call(deviceService.postAssignAppVersion, data)
    yield put({
      type: types.POST_ASSIGN_APP_VERSION_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.POST_ASSIGN_APP_VERSION_ERROR, payload: error })
  }
}

function* postAssignAppVersionToDeviceType({ payload: data }) {
  try {
    const response = yield call(
      deviceService.postAssignAppVersionToDeviceType,
      data
    )
    yield put({
      type: types.POST_ASSIGN_APP_VERSION_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.POST_ASSIGN_APP_VERSION_ERROR, payload: error })
  }
}

function* getDeviceHealth({ id }) {
  try {
    const data = yield call(deviceService.getDeviceHealth, id)

    yield put({
      type: types.GET_DEVICE_HEALTH_SUCCESS,
      payload: { data }
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_HEALTH_ERROR, payload: { error } })
  }
}

function* getDeviceUpdatesAvailable() {
  try {
    const data = yield call(deviceService.getItems, {
      limit: 1,
      updateAvailable: 1
    })

    yield put({
      type: types.GET_DEVICE_UPDATES_AVAILABLE_SUCCESS,
      payload: data.meta
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_UPDATES_AVAILABLE_ERROR,
      payload: { error }
    })
  }
}

function* getGroupDevices({ params }) {
  try {
    const response = yield call(deviceService.getItems, params)
    yield put({
      type: types.GET_DEVICE_GROUP_DEVICES_SUCCESS,
      payload: {
        response: response.data,
        meta: transformMeta(response.meta)
      }
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_GROUP_DEVICES_ERROR, payload: error })
  }
}

function* getOfflineNotificationDevices({ params }) {
  try {
    const { data, meta } = yield call(
      deviceService.getOfflineNotificationDevices,
      params
    )

    yield put({
      type: types.GET_DEVICES_OFFLINE_NOTIFICATION_SUCCESS,
      payload: { data, meta: transformMeta(meta) }
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICES_OFFLINE_NOTIFICATION_ERROR,
      payload: { error }
    })
  }
}

function* getOfflineNotificationDeviceItem({ id }) {
  try {
    const data = yield call(deviceService.getOfflineNotificationDeviceItem, id)

    yield put({
      type: types.GET_DEVICE_ITEM_OFFLINE_NOTIFICATION_SUCCESS,
      payload: { data }
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_ITEM_OFFLINE_NOTIFICATION_ERROR,
      payload: { error }
    })
  }
}

function* postOfflineNotificationDeviceItem({ payload }) {
  try {
    const { updateItems, addItems = [], deleteItems = [], bulkCreateItems } =
      payload || {}

    if (bulkCreateItems)
      yield call(
        deviceService.postBulkCreateOfflineNotificationDevices,
        bulkCreateItems
      )

    yield all([
      ...deleteItems.map(id =>
        call(deviceService.deleteOfflineNotificationDevice, id)
      ),
      ...addItems.map(item =>
        call(deviceService.postOfflineNotificationDevice, item)
      ),
      ...(updateItems
        ? [
            call(
              deviceService.postBulkUpdateOfflineNotificationDevices,
              updateItems
            )
          ]
        : [])
    ])

    yield put({
      type: types.POST_DEVICES_OFFLINE_NOTIFICATION_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_DEVICES_OFFLINE_NOTIFICATION_ERROR,
      payload: { error }
    })
  }
}

function* postDeviceBulk({ payload }) {
  try {
    yield call(deviceService.postDeviceBulk, payload)
    yield put({
      type: types.POST_DEVICE_BULK_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_DEVICE_BULK_ERROR, payload: error })
  }
}

function* getDeviceLogs({ payload: { id, params }, meta }) {
  try {
    const response = yield call(
      deviceService.getDeviceLogs,
      id,
      params,
      meta.logType
    )

    const currentPage = params.page || 1

    yield put({
      type: types.GET_DEVICE_LOGS_SUCCESS,
      payload: {
        response,
        meta: {
          ...shapeOfMeta.meta,
          currentPage,
          lastPage:
            response.length === params.limit ? currentPage + 1 : currentPage
        }
      },
      meta
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_LOGS_ERROR,
      payload: error,
      meta
    })
  }
}

function* cloneDeviceItem({ deviceId, payload }) {
  try {
    yield call(deviceService.cloneDeviceItem, deviceId, payload)
    yield put({
      type: types.CLONE_DEVICE_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.CLONE_DEVICE_ITEM_ERROR,
      payload: error
    })
  }
}

function* getDevicesByFolder({ payload }) {
  try {
    const response = yield call(deviceService.getItems, payload)
    yield put({
      type: types.GET_DEVICES_BY_FOLDER_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICES_BY_FOLDER_ERROR,
      payload: error
    })
  }
}

function* getDeviceLocations({ payload }) {
  try {
    const response = yield call(deviceService.getDeviceLocations, payload)
    yield put({
      type: types.GET_DEVICE_LOCATIONS_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_LOCATIONS_ERROR,
      payload: error
    })
  }
}

function* postDeviceLocation({ payload }) {
  try {
    yield call(deviceService.postDeviceLocation, payload)
    yield put({
      type: types.POST_DEVICE_LOCATION_SUCCESS,
      payload: {
        response: 'successfully',
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.POST_DEVICE_LOCATION_ERROR,
      payload: { error }
    })
  }
}

function* putDeviceLocation({ payload: { id, ...data } }) {
  try {
    yield call(deviceService.putDeviceLocation, data, id)
    yield put({
      type: types.PUT_DEVICE_LOCATION_SUCCESS,
      payload: {
        response: 'successfully',
        status: 'success',
        label: 'update'
      }
    })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_LOCATION_ERROR,
      payload: {
        error,
        label: 'update'
      }
    })
  }
}

function* deleteDeviceLocation({ payload }) {
  try {
    yield call(deviceService.deleteDeviceLocation, payload)
    yield put({
      type: types.DELETE_DEVICE_LOCATION_SUCCESS,
      payload: {
        response: 'successfully',
        status: 'success',
        label: 'delete'
      }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_DEVICE_LOCATION_ERROR,
      payload: {
        error,
        label: 'delete'
      }
    })
  }
}

function* getDevicesByLocation({ payload: { id, params } }) {
  try {
    const { ignoreLocationId, ...restParams } = params
    const response =
      id !== UNGROUPED_LOCATION_ID && !ignoreLocationId
        ? yield call(deviceService.getDevicesByLocation, id, restParams)
        : yield call(deviceService.getDevicesWithoutLocation, restParams)
    yield put({
      type: types.GET_DEVICES_BY_LOCATION_SUCCESS,
      payload: { response: { ...response, meta: transformMeta(response.meta) } }
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICES_BY_LOCATION_ERROR,
      payload: error
    })
  }
}

function* getDeviceResolutions() {
  try {
    const response = yield call(deviceService.getDeviceResolutions)
    yield put({
      type: types.GET_DEVICE_RESOLUTION_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_RESOLUTION_ERROR,
      payload: error
    })
  }
}

function* updateScheduleDevice({ device1: { deviceId, data }, device2 }) {
  try {
    yield call(deviceService.updateScheduleDevice, deviceId, data)
    if (device2) {
      yield call(
        deviceService.updateScheduleDevice,
        device2?.deviceId,
        device2?.data
      )
    }
    yield put({
      type: types.UPDATE_SCHEDULE_DEVICE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.UPDATE_SCHEDULE_DEVICE_ERROR,
      payload: error
    })
  }
}

function* getScheduledDeviceIds({ payload }) {
  try {
    const data = yield call(deviceService.getScheduledDeviceIds, payload)

    yield put({
      type: types.GET_SCHEDULED_DEVICE_IDS_SUCCESS,
      payload: { response: data, meta: payload }
    })
  } catch (error) {
    yield put({
      type: types.GET_SCHEDULED_DEVICE_IDS_ERROR,
      payload: error
    })
  }
}

function* getDeviceCecSettings({ payload }) {
  try {
    const response = yield call(deviceService.getDeviceCecSettings, payload)

    yield put({
      type: types.GET_DEVICE_CEC_SETTINGS_SUCCESS,
      payload: { response }
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_CEC_SETTINGS_ERROR,
      payload: error
    })
  }
}

function* putDeviceCecSettings({ payload: { id, data } }) {
  try {
    yield call(deviceService.putDeviceCecSettings, id, data)

    yield put({
      type: types.PUT_DEVICE_CEC_SETTINGS_SUCCESS,
      payload: { response: { status: 'success' } }
    })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_CEC_SETTINGS_ERROR,
      payload: error
    })
  }
}

function* getDeviceNetworkConfig({ id }) {
  try {
    const response = yield call(deviceService.getDeviceNetworkConfig, id)

    yield put({
      type: types.GET_DEVICE_NETWORK_CONFIG_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_NETWORK_CONFIG_ERROR, payload: error })
  }
}

function* putDeviceNetworkConfig({ payload }) {
  try {
    yield call(deviceService.putDeviceNetworkConfig, payload)
    yield put({
      type: types.PUT_DEVICE_NETWORK_CONFIG_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_NETWORK_CONFIG_ERROR,
      payload: { error }
    })
  }
}

function* getVirtualPlayerStats(action) {
  try {
    const response = yield call(
      deviceService.getVirtualPlayerStats,
      action.payload
    )
    yield put({
      type: types.GET_DEVICE_VIRTUAL_PLAYER_STATS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_VIRTUAL_PLAYER_STATS_ERROR,
      payload: error
    })
  }
}

function* postIpCIDR({ payload }) {
  try {
    const data = yield call(deviceService.postIpCIDR, payload)

    yield put({
      type: types.POST_IP_CIDR_SUCCESS,
      payload: { response: data }
    })
  } catch (error) {
    yield put({
      type: types.POST_IP_CIDR_ERROR,
      payload: error
    })
  }
}

function* putIpCIDR({ payload }) {
  try {
    const data = yield call(deviceService.putIpCIDR, payload)

    yield put({
      type: types.PUT_IP_CIDR_SUCCESS,
      payload: { response: data }
    })
  } catch (error) {
    yield put({
      type: types.PUT_IP_CIDR_ERROR,
      payload: error
    })
  }
}

function* deleteIpCIDR({ payload }) {
  try {
    yield call(deviceService.deleteIpCIDR, payload)

    yield put({
      type: types.DELETE_IP_CIDR_SUCCESS,
      payload: { response: { status: 'success' } }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_IP_CIDR_ERROR,
      payload: error
    })
  }
}

function* getIpCIDR({ payload }) {
  try {
    const { data, meta } = yield call(deviceService.getIpCIDR, payload)

    yield put({
      type: types.GET_IP_CIDR_SUCCESS,
      payload: { response: data, meta: transformMeta(meta) }
    })
  } catch (error) {
    yield put({
      type: types.GET_IP_CIDR_ERROR,
      payload: error
    })
  }
}

function* getDeviceDependencyPackages({ payload }) {
  try {
    const { data } = yield call(
      deviceService.getDeviceDependencyPackages,
      payload
    )
    yield put({
      type: types.GET_DEVICE_DEPENDENCY_PACKAGES_SUCCESS,
      payload: { response: data }
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_DEPENDENCY_PACKAGES_ERROR,
      payload: error
    })
  }
}

function* getDeviceGlobalConfig({ payload }) {
  try {
    const { data } = yield call(deviceService.getDeviceGlobalConfig, payload)

    yield put({
      type: types.GET_DEVICE_GLOBAL_CONFIG_SUCCESS,
      payload: { response: data }
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_GLOBAL_CONFIG_ERROR,
      payload: error
    })
  }
}

function* putDeviceGlobalConfig({ payload }) {
  try {
    yield call(deviceService.putDeviceGlobalConfig, payload)
    yield put({
      type: types.PUT_DEVICE_GLOBAL_CONFIG_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_GLOBAL_CONFIG_ERROR,
      payload: error
    })
  }
}

function* getProofOfPlayDevices({ params }) {
  try {
    const response = yield call(deviceService.getItems, params)
    yield put({
      type: types.GET_PROOF_OF_PLAY_DEVICES_SUCCESS,
      payload: {
        response
      }
    })
  } catch (error) {
    yield put({ type: types.GET_PROOF_OF_PLAY_DEVICES_ERROR, payload: error })
  }
}

function* featureDeviceAssociate({ payload }) {
  try {
    yield call(deviceService.featureDeviceAssociate, payload)
    yield put({
      type: types.POST_FEATURE_DEVICE_ASSOCIATE_SUCCESS,
      payload: {
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.POST_FEATURE_DEVICE_ASSOCIATE_ERROR,
      payload: error
    })
  }
}

function* getPowerSchedule(action) {
  try {
    const response = yield call(deviceService.getPowerSchedule, action.payload)
    yield put({
      type: types.GET_DEVICE_POWER_SCHEDULE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_POWER_SCHEDULE_ERROR, payload: error })
  }
}

function* putPowerSchedule(action) {
  try {
    const response = yield call(deviceService.putPowerSchedule, action.payload)
    yield put({
      type: types.PUT_DEVICE_POWER_SCHEDULE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.PUT_DEVICE_POWER_SCHEDULE_ERROR, payload: error })
  }
}

// remove after full implementation of RTK Query for Device Library page
function* invalidateDeviceTags() {
  yield put(deviceApi.util.invalidateTags([apiTags.device]))
}

function* invalidateAppVersionTags() {
  yield put(deviceApi.util.invalidateTags([apiTags.appVersion]))
}

function* invalidateDeviceTypeTags() {
  yield put(configApi.util.invalidateTags([apiTags.deviceType]))
}

const deviceSaga = {
  getItems,
  getDeviceLocationCategories,
  getPreference,
  putPreference,
  getGroups,
  putItem,
  postItem,
  deleteItem,
  getItem,
  getPreview,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  getReboot,
  putReboot,
  putPeriodicalReboot,
  getScreenPowerSettings,
  putScreenPowerSettings,
  putBulkReboot,
  putBulkPeriodicalReboot,
  getDeviceNotes,
  postDeviceNote,
  getCapAlertDevicesWorker,
  getDeviceTypes,
  getDeviceType,
  putDeviceType,
  postDeviceType,
  postDeviceBulk,
  getDeviceModels,
  getDeviceModel,
  putDeviceModel,
  postDeviceModel,
  getAppVersions,
  getReleaseAppVersions,
  getAppVersion,
  putAppVersion,
  postAppVersion,
  deleteAppVersion,
  deleteSelectedAppVersions,
  postAssignAppVersion,
  postAssignAppVersionToDeviceType,
  getDeviceUpdatesAvailable,
  getDeviceHealth,
  getGroupDevices,
  getOfflineNotificationDevices,
  getOfflineNotificationDeviceItem,
  postOfflineNotificationDeviceItem,
  getDeviceLogs,
  cloneDeviceItem,
  updateScheduleDevice,
  getDeviceResolutions,
  getDevicesByFolder,
  getDeviceLocations,
  postDeviceLocation,
  putDeviceLocation,
  deleteDeviceLocation,
  getDevicesByLocation,
  getScheduledDeviceIds,
  getDeviceCecSettings,
  putDeviceCecSettings,
  getDeviceNetworkConfig,
  putDeviceNetworkConfig,
  getVirtualPlayerStats,
  postIpCIDR,
  putIpCIDR,
  deleteIpCIDR,
  getIpCIDR,
  getDeviceDependencyPackages,
  getDeviceGlobalConfig,
  getProofOfPlayDevices,
  featureDeviceAssociate,
  putDeviceGlobalConfig,
  getPowerSchedule,
  putPowerSchedule,
  invalidateDeviceTags,
  invalidateAppVersionTags,
  invalidateDeviceTypeTags
}

export default deviceSaga
