import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getViewSetting, getViewSettings } from 'utils/localStorage'
import { setViewSetting } from 'slices/appSlice'
import { viewSettingsSelector } from 'selectors/appSelectors'
import { VIEW_SETTINGS } from 'constants/localStorage'
import { libraryViews } from 'constants/library'

export default function useViewSetting(
  key = 'default',
  fallback = libraryViews.list
) {
  const dispatch = useDispatch()
  const viewSettings = useSelector(viewSettingsSelector)

  useEffect(() => {
    dispatch(setViewSetting({ key, view: getViewSetting(key, fallback) }))
    // eslint-disable-next-line
  }, [])

  const handleViewChange = useCallback(
    view => {
      dispatch(setViewSetting({ key, view }))
      localStorage.setItem(
        VIEW_SETTINGS,
        JSON.stringify({
          ...getViewSettings(),
          [key]: view
        })
      )
    },
    [dispatch, key]
  )

  return {
    view: viewSettings[key] || fallback,
    changeView: handleViewChange
  }
}
