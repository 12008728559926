import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'

export const emailNotificationApi = createAppApi('emailNotificationQuery', {
  tagTypes: [apiTags.emailNotification],
  endpoints: builder => ({
    getEmailTables: builder.query({
      query: (params = {}) => ({
        url: '/emailNotification/table',
        params
      })
    }),
    ...getCRUDEndpoints({
      name: 'Notification',
      url: '/emailNotification',
      builder,
      tags: [apiTags.emailNotification]
    })
  })
})

export const {
  useGetEmailTablesQuery,
  //Notification
  useLazyGetNotificationsQuery,
  useGetNotificationQuery,
  useDeleteNotificationMutation,
  usePutNotificationMutation,
  usePostNotificationMutation,
  useGetNotificationsQuery
} = emailNotificationApi
