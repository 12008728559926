import { useCallback, useEffect } from 'react'
import refiner from 'refiner-js'
import i18next from 'i18next'

import useUserDetails from 'hooks/useUserDetails'
import { deviceService } from 'services'
import { getOriginalUser } from 'utils/localStorage'
import { deviceStatus } from '../../constants/deviceConstants'

const refinerActions = {
  setProject: 'setProject',
  stopPinging: 'stopPinging',
  identifyUser: 'identifyUser',
  resetUser: 'resetUser',
  trackEvent: 'trackEvent',
  showForm: 'showForm',
  dismissForm: 'dismissForm',
  onBeforeShow: 'onBeforeShow',
  setLocale: 'setLocale'
}

export default function useRefiner({ onDevicesReceive }) {
  const userDetails = useUserDetails()

  const handleLanguageChange = useCallback(langCode => {
    refiner(refinerActions.setLocale, langCode)
  }, [])

  const getDevicesCount = useCallback(async () => {
    try {
      const response = await deviceService.getItems(
        {
          limit: 1,
          status: [deviceStatus.active, deviceStatus.inactive].join(',')
        },
        true
      )
      if (onDevicesReceive) {
        onDevicesReceive(response)
      }
      return response.meta.total
    } catch (e) {}
  }, [onDevicesReceive])

  const identifyUser = useCallback(
    async userDetails => {
      const { id, client, role = {} } = userDetails
      const { displayName } = role
      const devicesCount = await getDevicesCount()

      refiner(refinerActions.identifyUser, {
        id,
        email: 'hidden@example.com',
        name: `${id || ''}${client?.id ? `[${client.id}]` : ''}`,
        role: displayName,
        userId: id,
        clientId: client?.id || '',
        featurePackage: client?.featurePackage?.title || '',
        serviceLevel: client?.type?.title || '',
        devicesCount: devicesCount ?? ''
      })
    },
    [getDevicesCount]
  )

  useEffect(() => {
    refiner(refinerActions.setProject, process.env.REACT_APP_REFINER_PROJECT_ID)
    handleLanguageChange(i18next.language)
    i18next.on('languageChanged', handleLanguageChange)
    return () => {
      refiner(refinerActions.resetUser)
      refiner(refinerActions.stopPinging)
      i18next.off('languageChanged', handleLanguageChange)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userDetails?.id && !getOriginalUser()) {
      void identifyUser(userDetails)
    } else {
      refiner(refinerActions.resetUser)
      refiner(refinerActions.stopPinging)
    }
    // eslint-disable-next-line
  }, [userDetails])
}
