import React, { useCallback, useEffect, useState } from 'react'
import { Table, TableBody, Typography, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { _uniqBy } from 'utils/lodash'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  TableLibraryCell,
  TableLibraryHead,
  TableLibraryRow
} from 'components/TableLibrary'
import ScheduleWorkingDays from 'components/Pages/Schedule/ScheduleWorkingDays'
import { checkData } from 'utils/tableUtils'
import CustomBadge from 'components/Badge'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { scheduleConstants } from 'constants/index'
import Popup from 'components/Popup'
import DeviceNameViewCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceNameViewCell'
import { useUserRole } from 'hooks/tableLibrary'
import { getSchedulePreview } from 'actions/scheduleActions'
import { ScreenPreviewModal } from 'components/Modal'
import LibraryTagChips from 'components/LibraryTagChips'
import LibraryGroupChips from 'components/LibraryGroupChips'
import { TextWithTooltip, Text } from 'components/Typography'
import { CheckboxSwitcher } from 'components/Checkboxes'

const styles = ({ palette, type, colors, typography, spacing }) => ({
  cellPaddings: {
    padding: '10px 8px !important'
  },
  name: {
    ...typography.darkAccent[type],
    marginLeft: '7px'
  },
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  },
  boldTitle: {
    fontWeight: 'bold',
    fontSize: 13,
    textTransform: 'capitalize',
    color: palette[type].pages.schedule.boldTitle
  },
  contentsTotal: {
    ...typography.darkText[type],
    fontSize: '11px',
    color: colors.highlight,
    display: 'flex',
    alignItems: 'center',
    '& i': {
      marginRight: '4px',
      fontSize: '14px'
    }
  },
  contentsCardWrap: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    border: `solid 5px ${palette[type].sideModal.content.border}`,
    background: palette[type].sideModal.groups.header.background,
    position: 'relative'
  },
  contentsCardHeader: {
    padding: '15px',
    borderBottom: `solid 1px ${palette[type].sideModal.content.border}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  deviceListRoot: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    overflow: 'hidden'
  },
  deviceListRow: {
    display: 'flex',
    alignItems: 'center',
    height: 55,
    margin: '0 7.5px 0 15px',
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`,
    '& i': {
      marginRight: 7,
      color: colors.highlight
    },
    '&:nth-child(even)': {
      margin: '0 15px 0 7.5px'
    }
  },
  itemDarkTitle: {
    whiteSpace: 'nowrap',
    paddingLeft: 5
  },
  helperCardWrap: {
    backgroundColor: palette[type].helperCard.background,
    border: '1px solid ' + colors.highlight,
    borderRadius: '4px',
    padding: '13px 15px 20px',
    marginBottom: spacing(1)
  },
  helperTitle: {
    fontSize: '12px',
    color: colors.highlight,
    fontStyle: 'italic',
    fontWeight: 'bold',
    lineHeight: '15px'
  },
  helperText: {
    fontSize: '12px',
    color: colors.highlight,
    lineHeight: '15px',
    whiteSpace: 'pre-line'
  },
  checkboxSwitcherContainer: {
    marginBottom: 16
  },
  popupTrigger: {
    display: 'inline-block'
  },
  popupContentWrapper: {
    padding: '5px 15px',
    textAlign: 'left'
  }
})

const initialColumns = [
  { id: 'title', label: 'Title' },
  { id: 'workingDays', label: 'Days', align: 'center' },
  { id: 'time', label: 'Time', align: 'center' },
  { id: 'devices', label: 'Devices', align: 'center' },
  { id: 'device_tags', label: 'Device Tags', align: 'center' },
  { id: 'device_groups', label: 'Device Groups', align: 'center' },
  { id: 'device_locations', label: 'Device Locations', align: 'center' }
]

const columnWidth = {
  title: 200,
  workingDays: 300,
  time: 150,
  devices: 100,
  deviceTags: 100
}

const contentStyleWidth = {
  devices: 385,
  device_tags: 200,
  device_groups: 200,
  device_locations: 200
}

const CONTENT_SIDE_PADDINGS = 15

const contentStyle = {
  padding: '0',
  borderRadius: '6px'
}

const parseDeviceConflictData = data => {
  const schedules = data.map(
    ({ schedule, device_groups, device_locations, device_tags, devices }) => ({
      ...schedule,
      device_groups,
      device_locations,
      device_tags,
      devices
    })
  )
  const uniqSchedules = _uniqBy(schedules, 'id')

  return uniqSchedules.map(
    ({
      id,
      title,
      startTime,
      endTime,
      workingDays,
      devices,
      device_tags,
      device_locations,
      device_groups
    }) => ({
      id,
      title,
      startTime,
      endTime,
      workingDays: workingDays || '',
      devices: devices.map(({ id, name, alias }) => ({ id, name, alias })),
      device_tags,
      device_locations,
      device_groups
    })
  )
}

const ScheduleConflictContent = ({
  error,
  hasConflictNote = true,
  revertBackToOriginValue,
  onChangeRevertBackToOrigin = f => f,
  isTimelineTable,
  classes,
  t
}) => {
  const dispatch = useDispatch()
  const [schedules, setSchedules] = useState([])
  const role = useUserRole()

  const handlePreviewClick = useCallback(
    ({ id, orientation }) => {
      dispatch(getSchedulePreview({ id, orientation }, 'conflict'))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!!error.data?.length) {
      setSchedules(parseDeviceConflictData(error.data))
    }
    // eslint-disable-next-line
  }, [error])

  const renderList = useCallback(
    ({ list, listType }) => {
      switch (listType) {
        case 'devices':
          return (
            <Popup
              position={'bottom right'}
              trigger={
                <div className={classes.popupTrigger}>
                  <CustomBadge content={list.length} />
                </div>
              }
              contentStyle={{
                ...contentStyle,
                width: contentStyleWidth[listType]
              }}
            >
              <div className={classes.contentsCardWrap}>
                <div className={classes.contentsCardHeader}>
                  <Typography className={classes.boldTitle}>
                    {t('Devices')}
                  </Typography>
                  <Typography
                    className={classNames(
                      classes.boldTitle,
                      classes.contentsTotal
                    )}
                  >
                    <i className="fa-regular fa-desktop" />
                    {list.length}
                  </Typography>
                </div>
                <div className={classes.deviceListRoot}>
                  {!!list.length &&
                    list.map(item => (
                      <div key={item.id} className={classes.deviceListRow}>
                        <i className="fa-regular fa-desktop" />
                        <div className={classes.itemDarkTitle}>
                          <DeviceNameViewCell
                            role={role}
                            classes={classes}
                            row={item}
                            columnWidth={120}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Popup>
          )
        case 'device_tags':
          if (!list.length) {
            return <Text>{t('N/A')}</Text>
          }

          return (
            <Popup
              position={'bottom right'}
              trigger={
                <div className={classes.popupTrigger}>
                  <CustomBadge content={list.length} />
                </div>
              }
              contentStyle={{
                ...contentStyle,
                width: contentStyleWidth[listType]
              }}
            >
              <div className={classes.contentsCardWrap}>
                <div className={classes.contentsCardHeader}>
                  <Typography className={classes.boldTitle}>
                    {t('Device Tags')}
                  </Typography>
                </div>
                <div className={classes.popupContentWrapper}>
                  <LibraryTagChips
                    tags={list}
                    maxWidth={
                      contentStyleWidth[listType] - CONTENT_SIDE_PADDINGS
                    }
                    justifyContent="unset"
                  />
                </div>
              </div>
            </Popup>
          )
        case 'device_groups':
          if (!list.length) {
            return <Text>{t('N/A')}</Text>
          }

          return (
            <Popup
              position={'bottom right'}
              trigger={
                <div className={classes.popupTrigger}>
                  <CustomBadge content={list.length} />
                </div>
              }
              contentStyle={{
                ...contentStyle,
                width: contentStyleWidth[listType]
              }}
            >
              <div className={classes.contentsCardWrap}>
                <div className={classes.contentsCardHeader}>
                  <Typography className={classes.boldTitle}>
                    {t('Device Groups')}
                  </Typography>
                </div>
                <div className={classes.popupContentWrapper}>
                  <LibraryGroupChips
                    groups={list}
                    maxWidth={
                      contentStyleWidth[listType] - CONTENT_SIDE_PADDINGS
                    }
                    justifyContent="unset"
                  />
                </div>
              </div>
            </Popup>
          )
        case 'device_locations':
          if (!list.length) {
            return <Text>{t('N/A')}</Text>
          }

          return (
            <Popup
              position={'bottom right'}
              trigger={
                <div className={classes.popupTrigger}>
                  <CustomBadge content={list.length} />
                </div>
              }
              contentStyle={{
                ...contentStyle,
                width: contentStyleWidth[listType]
              }}
            >
              <div className={classes.contentsCardWrap}>
                <div className={classes.contentsCardHeader}>
                  <Typography className={classes.boldTitle}>
                    {t('Device Locations')}
                  </Typography>
                </div>
                <div className={classes.popupContentWrapper}>
                  {list.map(location => (
                    <TextWithTooltip
                      maxWidth={
                        contentStyleWidth[listType] - CONTENT_SIDE_PADDINGS
                      }
                    >
                      {location.name}
                    </TextWithTooltip>
                  ))}
                </div>
              </div>
            </Popup>
          )
        default:
          break
      }
    },
    [t, classes, role]
  )

  return (
    <>
      <Table className={classes.table}>
        <TableLibraryHead
          noType={true}
          editRows={false}
          disableSort
          rowCount={schedules.length}
          columns={initialColumns}
          columnWidth={columnWidth}
          headerCellClassName={classes.tableHeaderCell}
        />
        <TableBody>
          {schedules.map(row => {
            const scheduleTypeInfo = row?.isSmartSchedule
              ? scheduleConstants.scheduleTypes.SmartSchedule
              : scheduleConstants.scheduleTypes[row?.scheduleType] ||
                scheduleConstants.scheduleTypes.Timed
            return (
              <TableLibraryRow key={row.id} hover tabIndex={-1}>
                <TableLibraryCell className={classes.cellPaddings}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <LibraryTypeIcon
                        color={scheduleTypeInfo.color}
                        icon={scheduleTypeInfo.icon}
                        iconHelperClass={scheduleTypeInfo.iconHelperClass}
                        onClick={() =>
                          handlePreviewClick({
                            id: row.id,
                            orientation: row.orientation
                          })
                        }
                      />
                    </Grid>
                    <Grid item>
                      <TextWithTooltip
                        maxWidth={157}
                        rootClassName={classes.name}
                      >
                        {checkData(row.title)}
                      </TextWithTooltip>
                    </Grid>
                  </Grid>
                </TableLibraryCell>

                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  <ScheduleWorkingDays
                    workingDays={row.workingDays.split(',')}
                  />
                </TableLibraryCell>
                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  {`${row.startTime} - ${row.endTime}`}
                </TableLibraryCell>
                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  {renderList({ list: row.devices, listType: 'devices' })}
                </TableLibraryCell>

                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  {renderList({
                    list: row.device_tags,
                    listType: 'device_tags'
                  })}
                </TableLibraryCell>

                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  {renderList({
                    list: row.device_groups,
                    listType: 'device_groups'
                  })}
                </TableLibraryCell>

                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  {renderList({
                    list: row.device_locations,
                    listType: 'device_locations'
                  })}
                </TableLibraryCell>
              </TableLibraryRow>
            )
          })}
        </TableBody>
      </Table>

      {!isTimelineTable && (
        <CheckboxSwitcher
          label={t('Revert to original after new schedule expires')}
          tooltip={t('Schedule override toggle tooltip')}
          value={revertBackToOriginValue}
          name="revertBackToOrigin"
          handleChange={onChangeRevertBackToOrigin}
          switchContainerClass={classes.checkboxSwitcherContainer}
        />
      )}

      {hasConflictNote && (
        <Grid item xs={12} className={classes.helperCardWrap}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.helperTitle}>
                {t('Note')}
              </Typography>
              <Typography className={classes.helperText}>
                {t('note conflict modal')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <ScreenPreviewModal
        targetReducer="schedule"
        actionKey="conflict"
        bounds="html"
      />
    </>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(ScheduleConflictContent)
)
