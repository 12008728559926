import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import scheduleSaga from 'sagas/scheduleSaga'

const actions = [types.POST_SCHEDULE_SUCCESS, types.PUT_SCHEDULE_SUCCESS]

const scheduleInvalidateCache = () => {
  return actions.map(action => {
    return takeLatest(action, scheduleSaga.invalidateScheduleTags)
  })
}

export default scheduleInvalidateCache
