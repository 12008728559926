import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import { withStyles, Dialog } from '@material-ui/core'
import { useSnackbar as _useSnackbar } from 'notistack'
import { _get } from 'utils/lodash'

import AccountNavigationLink from 'components/Account/AccountNavigationLink'
import SuggestionForm from 'components/Header/SuggestionForm'
import { clearPostData } from 'actions/feedbackActions'
import { useUserRole } from 'hooks/tableLibrary'
import useAppConfig from 'hooks/api/useAppConfig'
import GlobalSearch from './GlobalSearch'
import TrainingForm from '../Header/TrainingForm'
import useClientSettings from '../Pages/AccountSettings/hooks/useClientSettings'
import { SIGNATURE_CARE } from '../../constants/clientsConstants'

const SUGGESTION = 'suggestion'
const TRAINING = 'training'
const styles = theme => {
  const { palette, type, colors } = theme
  return {
    accountNavigation: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      color: palette[type].header.rightAction.iconColor
    },
    dialog: {
      width: 674,
      background: palette[type].suggestionBox.background
    },
    appointmentDialog: {
      background: colors.backgroundLightPrimary
    },
    dialogRoot: {
      zIndex: '1402 !important'
    }
  }
}

const AccountNavigation = ({ classes, t, post, clearPostData }) => {
  const { enqueueSnackbar } = _useSnackbar()

  const [dialog, setDialog] = useState(false)
  const [type, setType] = useState('')
  const role = useUserRole()
  const { data: clientSettings } = useClientSettings()
  const handleClose = () => {
    setDialog(false)
  }

  const appConfig = useAppConfig()

  const trainingLink =
    appConfig.REACT_APP_SIGNATURE_CARE_TRAINING_URL ||
    process.env.REACT_APP_SIGNATURE_CARE_TRAINING_URL

  useEffect(() => {
    if (post) {
      if (post.response) {
        enqueueSnackbar(t('Successfully sent'), { variant: 'success' })

        handleClose()
        clearPostData()
      } else if (post.error) {
        const fieldErrors = {}

        post.error.errorFields.forEach(
          field => (fieldErrors[field.name] = field.value.join(' '))
        )

        if (!post.error.errorFields?.length) {
          enqueueSnackbar(post.error.message, { variant: 'error' })
        }

        clearPostData()
      }
    }
    //eslint-disable-next-line
  }, [post])

  return (
    <>
      <nav className={classes.accountNavigation}>
        {clientSettings?.serviceLevel === SIGNATURE_CARE &&
          !role.system &&
          trainingLink && (
            <>
              <AccountNavigationLink
                linkIconClassName={classNames(
                  'hvr-grow fa-light fa-graduation-cap',
                  classes.icon
                )}
                handleClick={() => {
                  setType(TRAINING)
                  setDialog(true)
                }}
                tooltipHeader={t('Want to learn more?')}
                tooltipTitle={t(
                  'Sign up for personalized training to best utilize this CMS'
                )}
              />
            </>
          )}
        {!role.system && (
          <AccountNavigationLink
            linkIconClassName={classNames(
              'hvr-grow fa-light fa-messages',
              classes.icon
            )}
            iconButtonClassName
            handleClick={() => {
              setType(SUGGESTION)
              setDialog(true)
            }}
            tooltipHeader={t('Got an Idea?')}
            tooltipTitle={t(
              'Send us your idea or feature request and help improve this CMS'
            )}
          />
        )}
        <GlobalSearch
          trigger={
            <AccountNavigationLink
              linkIconClassName={classNames(
                'fa-regular fa-magnifying-glass',
                classes.icon
              )}
              iconButtonClassName="hvr-grow-flip-icon"
            />
          }
        />
      </nav>

      <Dialog
        open={dialog}
        classes={{
          root: classes.dialogRoot,
          paper:
            type === SUGGESTION ? classes.dialog : classes.appointmentDialog
        }}
        maxWidth={type === SUGGESTION ? false : 'xl'}
        onClose={handleClose}
        fullWidth={type !== SUGGESTION}
      >
        {type === SUGGESTION ? (
          <SuggestionForm
            closeModal={handleClose}
            errorFields={_get(post, 'error.errorFields', [])}
          />
        ) : (
          <TrainingForm closeModal={handleClose} url={trainingLink} />
        )}
      </Dialog>
    </>
  )
}

const mapStateToProps = ({ feedback }) => ({
  post: feedback.post
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearPostData
    },
    dispatch
  )

export default withTranslation('translations')(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(AccountNavigation)
  )
)
