import React, { useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar as _useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { withStyles, Typography, Grid } from '@material-ui/core'
import classNames from 'classnames'
import moment from 'moment'
import * as Yup from 'yup'
import _isEmpty from 'lodash/isEmpty'
import { Close } from '@material-ui/icons'

import { FormControlInput, FormControlReactSelect } from 'components/Form'

import { useCustomSnackbar } from 'hooks/index'
import {
  daysData,
  DEFAULT_REBOOT_AFTER_UNIT,
  DEFAULT_REBOOT_AFTER_VALUE,
  REBOOT_KEY
} from 'constants/reboot'
import {
  AM_PM_FORMAT,
  AP_TIME_FORMAT,
  DATE_TIME_VIEW_FORMAT,
  MEDIA_DATE_TIME_FORMAT,
  MEDIA_DATE_TIME_S_FORMAT,
  TIME_FORMAT,
  TIME_S_FORMAT
} from 'constants/dateTimeFormats'
import { TextWithTooltip } from 'components/Typography'
import { getTimezone, setDeviceRebootStorage } from 'utils/deviceUtils'
import { DateTimeView } from 'components/TableLibrary'
import Card from 'components/Card/Card'
import Text from 'components/Typography/Text'
import useConfirmation from 'hooks/useConfirmation'
import { BlueButton } from 'components/Buttons'
import { minuteOptions, minutesToSec, secToMin, timeOptions } from 'utils/time'
import { allWorkingDays } from 'constants/report'
import Workdays from 'components/Workdays'
import {
  storageGetItem,
  storageRemoveItem,
  storageSetItem
} from 'utils/localStorage'
import FormControlDateTimePickerNew from 'components/Form/formControlDateTimePickers/FormControlDateTimePickerNew'
import TimePickerNew from 'components/Form/formControlDateTimePickers/components/TimePickerNew'
import MaterialPopup from 'components/Popup/MaterialPopup'
import { simulateEvent } from 'utils'

const styles = ({ palette, type, fontSize, fontWeight, colors, spacing }) => {
  return {
    root: {
      height: '100%',
      padding: 20,
      background: palette[type].dialog.background
    },
    infoContainer: {
      marginBottom: 16,
      gap: 30
    },
    infoText: {
      fontSize: fontSize.primary,
      fontWeight: fontWeight.normal
    },
    infoAlias: {
      fontSize: fontSize.primary,
      width: 'fit-content'
    },
    infoName: {
      width: 'fit-content',
      paddingRight: 2
    },
    infoLabel: {
      color: palette[type].pages.devices.rebootModal.info.label.color
    },
    infoValue: {
      fontWeight: fontWeight.normal,
      color: palette[type].pages.devices.rebootModal.info.value.color
    },
    checkboxContainer: {
      position: 'relative',
      left: -15,
      flex: 1
    },
    inputContainer: {
      width: 75
    },
    selectContainer: {
      width: 'calc(100% - 95px)'
    },
    containerMB: {
      marginBottom: 16
    },
    errorTextClass: {
      width: 'max-content'
    },
    dateTimePicker: {
      '& > div:first-child': {
        width: '100%'
      }
    },
    scrollbarRoot: {
      height: '320px !important',
      maxHeight: 'calc(100vh - 320px)'
    },
    bulkModalScrollbar: {
      height: '140px !important'
    },
    dateTimeInput: {
      paddingRight: 5
    },
    timePickerWrapper: {
      paddingLeft: 10
    },
    dateTimeText: {
      marginLeft: 0
    },
    dateTimeContent: {
      display: 'flex',
      alignItems: 'center'
    },
    subLabel: {
      fontStyle: 'italic',
      textTransform: 'lowercase'
    },
    cardHeader: {
      paddingLeft: 0,
      borderRadius: '4px',
      border: `solid 1px ${palette[type].sideModal.content.border}`,
      backgroundColor: palette[type].pages.media.general.card.header.background
    },
    cardHeaderText: {
      fontWeight: 'bold',
      lineHeight: 1,
      padding: '20px',
      color: palette[type].card.greyHeader.color
    },
    cardRoot: {
      padding: 0,
      marginBottom: '20px',
      borderRadius: '4px',
      border: `solid 5px ${palette[type].sideModal.content.border}`,
      background: palette[type].sideModal.groups.header.background
    },
    cardsWrapper: {
      padding: '0 15px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 25
    },
    cardContent: {
      height: '100%',
      maxHeight: 'calc(100% - 78px)',
      padding: 20
    },
    rebootEveryAt: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    },
    rebootText: {
      paddingLeft: 18,
      display: 'flex',
      alignItems: 'center',
      height: '100%'
    },
    refreshEveryWrapper: {
      display: 'flex',
      margin: 'auto'
    },
    cardSection: {
      gap: 16
    },
    oneTimeRebootBtn: {
      width: 115
    },
    scheduleRebootBtn: {
      display: 'flex',
      marginLeft: 30,
      width: 95
    },
    timeDurationPickerText: {
      padding: '9px 12px'
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.5
    },
    rebootAfterPickerWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    removeScheduleIcon: {
      color: palette[type].sideModal.header.titleColor,
      marginLeft: 5,
      width: 20,
      height: 20,
      cursor: 'pointer'
    },
    infoItem: {
      paddingLeft: 25
    },
    afterValueContainer: {
      maxWidth: 70
    },
    minutesText: {
      paddingLeft: 6
    },
    rebootEveryLabel: {
      height: 32
    },
    setMessage: {
      width: 'calc(100% - 25px)',
      textAlign: 'center'
    },
    noScheduleText: {
      fontStyle: 'italic'
    },
    listRoot: {
      height: 300
    },
    recurrenceTimeWrapper: {
      width: 100
    }
  }
}

const rebootRequestedData = t => ({
  tooltipText: t(
    'A reboot request is already active on the device. Please try again later'
  ),
  opaque: 0.5
})

const isWaitingForReboot = ({
  key,
  deviceId,
  rebootChoice,
  rebootAfterInterval,
  rebootAt,
  rebootDevice
}) => {
  const storageData = storageGetItem(key)
  const rebootRequestData = storageData ? JSON.parse(storageData) : []

  const currentDevice = rebootRequestData.find(
    device => device.deviceId === deviceId
  )

  if (!currentDevice) return false

  let timePassed = false
  const currentTime = moment()
  const rebootRequestTime =
    rebootChoice === 'at' ? moment(rebootAt) : moment(currentDevice.createdAt)
  const deviceBeenRebooted = !rebootDevice && !rebootChoice

  if (rebootChoice === 'now') {
    const interval = 300
    timePassed = currentTime.isAfter(rebootRequestTime.add(interval, 'second'))
  } else if (rebootChoice === 'after' && rebootAfterInterval) {
    timePassed = currentTime.isAfter(
      rebootRequestTime.add(rebootAfterInterval, 'second')
    )
  } else if (rebootChoice === 'at') {
    timePassed = currentTime.isAfter(rebootRequestTime)
  }

  if (timePassed || deviceBeenRebooted) {
    const filteredDevices = rebootRequestData.filter(
      device => device.deviceId !== deviceId
    )

    if (filteredDevices.length) {
      storageSetItem(key, JSON.stringify(filteredDevices))
    } else {
      storageRemoveItem(key)
    }
  }

  if (deviceBeenRebooted) {
    return false
  }

  return !timePassed
}
const RebootForm = ({
  classes,
  data = {},
  initialRebootValues = {},
  handleClose = f => f,
  bulkReboot,
  device,
  namePrefix = 'reboot',
  onSubmit,
  putReducer,
  onClear,
  tabs,
  backendData
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = _useSnackbar()
  const showSnackbar = useCustomSnackbar(t, enqueueSnackbar, closeSnackbar)
  const { showConfirmation } = useConfirmation()

  const names = useMemo(
    () => ({
      choice: `${namePrefix}Choice`,
      afterValue: `${namePrefix}AfterValue`,
      afterUnit: `${namePrefix}AfterUnit`,
      at: `${namePrefix}At`
    }),
    [namePrefix]
  )

  const recurringNames = useMemo(
    () => ({
      everyValue: `${namePrefix}EveryValue`,
      workingDays: `${namePrefix}WorkingDays`
    }),
    [namePrefix]
  )

  const initialRecurringValues = {
    [recurringNames.everyValue]:
      // nosemgrep
      timeOptions[Math.floor(Math.random() * timeOptions.length)].value,
    [recurringNames.workingDays]: allWorkingDays
  }

  const formConfig = useMemo(
    () => ({
      initialValues: {
        [names.choice]: '',
        [names.afterValue]: secToMin(DEFAULT_REBOOT_AFTER_VALUE),
        [names.afterUnit]: DEFAULT_REBOOT_AFTER_UNIT,
        [names.at]: moment().format(MEDIA_DATE_TIME_FORMAT)
      },
      validationSchema: (t, timezone) =>
        Yup.object().shape({
          [names.choice]: Yup.string().nullable(),
          [names.at]: Yup.date().when(names.choice, {
            is: 'at',
            then: Yup.date().min(
              timezone
                ? moment().utcOffset(timezone).format(MEDIA_DATE_TIME_S_FORMAT)
                : moment().format(MEDIA_DATE_TIME_S_FORMAT),
              t('Date & Time must be greater than the current Date & Time')
            )
          })
        })
    }),
    [names]
  )

  const { initialValues, validationSchema } = formConfig

  const translations = useMemo(
    () => ({
      next: t('Next Reboot'),
      now: t('Reboot now'),
      after: t('Reboot after'),
      at: t('Reboot at'),
      noScheduled: t('No Reboot Scheduled')
    }),
    [t]
  )

  const info = [
    {
      name: 'name',
      alias: 'alias',
      label: 'Device Name'
    },
    {
      name: 'currentDeviceTime',
      label: 'Current Device Time'
    },
    {
      name: 'lastReboot',
      label: 'Last Rebooted at'
    },
    {
      name: 'nextReboot',
      label: translations.next,
      subLabel: 'Scheduled at'
    }
  ]

  const handleSubmit = useCallback(
    values => {
      const { rebootEveryValue, rebootWorkingDays, ...rest } = values

      let oneTimeRebootData = {
        initiator_id: rest.initiator_id,
        initiator_type: rest.initiator_type,
        rebootChoice: rest.rebootChoice
      }

      if (values.rebootChoice === 'after') {
        oneTimeRebootData.rebootAfterValue = minutesToSec(rest.rebootAfterValue)
        oneTimeRebootData.rebootAfterUnit = rest.rebootAfterUnit
        oneTimeRebootData.rebootAt = moment()
          .add(minutesToSec(rest.rebootAfterValue), 'second')
          .format(MEDIA_DATE_TIME_FORMAT)
      } else if (values.rebootChoice === 'at') {
        oneTimeRebootData.rebootAt = moment(rest.rebootAt).format(
          MEDIA_DATE_TIME_FORMAT
        )
      }

      onSubmit(oneTimeRebootData)
    },
    [onSubmit]
  )

  const handleSubmitRecurringForm = useCallback(
    values => {
      const { rebootEveryValue, rebootWorkingDays } = values
      const periodicalRebootData = {
        periodicalRebootSettings: {
          rebootDay: rebootWorkingDays.toString().replaceAll(',', '|'),
          rebootTime: moment(rebootEveryValue, TIME_FORMAT).format(
            TIME_S_FORMAT
          )
        }
      }
      onSubmit(periodicalRebootData)
    },
    [onSubmit]
  )

  const form = useFormik({
    initialValues,
    validationSchema: validationSchema(t, getTimezone(device)),
    onSubmit: handleSubmit
  })

  const recurringForm = useFormik({
    initialValues: initialRecurringValues,
    onSubmit: handleSubmitRecurringForm
  })

  const {
    values: recurringValues,
    handleChange: handleChangeRecurring,
    submitForm: submitRecurringForm,
    setFieldValue: setRecurringFieldValue,
    setValues: setRecurringValues
  } = recurringForm

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
    submitForm,
    validateForm,
    handleChange,
    setFieldError
  } = form

  useEffect(
    () => {
      validateForm()
    },
    //eslint-disable-next-line
    []
  )

  useEffect(() => {
    if (!_isEmpty(initialRebootValues)) {
      const {
        rebootEveryValue,
        rebootWorkingDays,
        ...rest
      } = initialRebootValues

      setRecurringValues({ rebootEveryValue, rebootWorkingDays })
      setValues(rest)
    }

    // eslint-disable-next-line
  }, [initialRebootValues])

  useEffect(() => {
    if (!_isEmpty(putReducer.response)) {
      data.periodicalRebootSettings
        ? showSnackbar('Reboot schedule successfully removed', 'success')
        : showSnackbar('Reboot successfully scheduled', 'success')

      if (putReducer.response.deviceId) {
        setDeviceRebootStorage({ deviceId: putReducer.response.deviceId })
      } else if (putReducer.response.deviceIds) {
        putReducer.response.deviceIds.forEach(deviceId => {
          setDeviceRebootStorage({ deviceId })
        })
      }

      onClear()
      handleClose()
    } else if (!_isEmpty(putReducer.error)) {
      putReducer.error.errorFields.forEach(field => {
        setFieldError(field.name, field.value)
      })
      if (!putReducer.error.errorFields?.length) {
        showSnackbar(putReducer.error.message, 'error')
      }
      onClear()
    }
    // eslint-disable-next-line
  }, [putReducer])

  const dataFieldValue = useCallback(
    fieldName => {
      const isCurrentFieldNa = data[fieldName] === 'NA'

      switch (fieldName) {
        case 'currentDeviceTime':
        case 'lastReboot':
          return isCurrentFieldNa ? (
            t('N/A')
          ) : (
            <DateTimeView
              withTimeZone={false}
              date={data[fieldName]}
              textClass={classes.dateTimeText}
            />
          )
        case 'nextReboot':
          return !data?.nextReboot ||
            moment(data?.nextReboot).isBefore(moment()) ? (
            <span className={classes.noScheduleText}>
              {translations.noScheduled}
            </span>
          ) : (
            <Grid container alignItems="center">
              <div className={classes.dateTimeContent}>
                <DateTimeView
                  withTimeZone={false}
                  date={data?.nextReboot}
                  separatorClass={classes.dateTimeText}
                />
              </div>
            </Grid>
          )
        default:
          return data[fieldName]
      }
    },
    [data, translations, t, classes]
  )

  const handleOneTimeRebootClick = useCallback(
    action => {
      setFieldValue(names.choice, action)

      setTimeout(() => {
        submitForm()
      }, 1)
    },
    [submitForm, names.choice, setFieldValue]
  )

  const handleScheduleClick = useCallback(() => {
    showConfirmation(
      t(
        'Rebooting devices frequently may corrupt its file structure and even reduce its lifespan. This option should not be used consistently for long periods of time. Are you sure you want to enable weekly rebooting?'
      ),
      () => {
        submitRecurringForm()
      }
    )
  }, [showConfirmation, submitRecurringForm, t])

  const handleRemoveSchedule = useCallback(() => {
    onSubmit({
      periodicalRebootSettings: null
    })
  }, [onSubmit])

  const scheduledDaysMessage = useMemo(() => {
    if (data.periodicalRebootSettings) {
      return data.periodicalRebootSettings.rebootDay
        .split('|')
        .map(day => daysData[day])
        .join(', ')
    }
    return ''
  }, [data.periodicalRebootSettings])

  const rebootRequested = useMemo(() => {
    if (!backendData?.rebootDetails) return false

    return isWaitingForReboot({
      key: REBOOT_KEY,
      deviceId: device.id,
      rebootChoice: backendData.rebootDetails.rebootChoice,
      rebootAfterInterval: backendData.rebootDetails.rebootAfterValue,
      rebootAt: backendData.rebootDetails.rebootAt,
      rebootDevice: backendData.rebootDevice
    })
  }, [backendData?.rebootDetails, backendData?.rebootDevice, device?.id])

  const onRecurringTimeChange = useCallback(
    ({ target: { name, value } }) => {
      const preparedTime = moment(value, AP_TIME_FORMAT).format(TIME_FORMAT)
      handleChangeRecurring(simulateEvent(name, preparedTime))
    },
    [handleChangeRecurring]
  )

  return (
    <div className={classes.root}>
      {tabs}
      {!bulkReboot &&
        info.map((field, index) => (
          <Grid container key={index} className={classes.infoContainer}>
            <Grid item xs={2} />
            <Grid item xs={3} className={classes.infoItem}>
              <Typography
                className={classNames(classes.infoText, classes.infoLabel)}
              >
                {t(field.label)}
                <span className={classes.subLabel}>
                  {field.subLabel ? ` (${t(field.subLabel)})` : ''}
                </span>
                :
              </Typography>
            </Grid>
            <Grid item xs={5} className={classes.infoItem}>
              {data[field.alias] && (
                <TextWithTooltip
                  maxWidth={340}
                  placement="bottom"
                  color="title.primary"
                  weight="bold"
                  rootClassName={classes.infoAlias}
                >
                  {data[field.alias]}
                </TextWithTooltip>
              )}

              {dataFieldValue(field.name) && (
                <TextWithTooltip
                  maxWidth={230}
                  placement="bottom"
                  color="title.primary"
                  component="div"
                  rootClassName={classes.infoName}
                >
                  {dataFieldValue(field.name)}
                </TextWithTooltip>
              )}
            </Grid>
          </Grid>
        ))}

      <div className={classes.cardsWrapper}>
        <Card
          icon={false}
          shadow={false}
          radius={false}
          hasMargin={false}
          grayHeader
          removeSidePaddings
          headerSidePaddings
          removeNegativeHeaderSideMargins
          title={t('One-time Device Reboot')}
          rootClassName={classes.cardRoot}
          headerClasses={[classes.cardHeader]}
          headerTextClasses={[classes.cardHeaderText]}
        >
          <Grid className={classes.cardContent}>
            <Grid container alignItems="center" className={classes.containerMB}>
              <BlueButton
                className={classes.oneTimeRebootBtn}
                {...(rebootRequested && rebootRequestedData(t))}
                onClick={() => handleOneTimeRebootClick('now')}
              >
                {translations.now}
              </BlueButton>
            </Grid>

            <Grid container alignItems="center" className={classes.containerMB}>
              <Grid item xs={4}>
                <BlueButton
                  className={classes.oneTimeRebootBtn}
                  {...(rebootRequested && rebootRequestedData(t))}
                  onClick={() => handleOneTimeRebootClick('after')}
                >
                  {translations.after}
                </BlueButton>
              </Grid>

              <Grid item xs={8} className={classes.timePickerWrapper}>
                <Grid item className={classes.rebootAfterPickerWrapper}>
                  <FormControlReactSelect
                    marginBottom={0}
                    id={names.afterValue}
                    name={names.afterValue}
                    value={
                      values[names.afterValue]
                        ? values[names.afterValue]
                        : initialValues[names.afterValue]
                    }
                    handleChange={handleChange}
                    maxMenuHeight={250}
                    options={minuteOptions}
                    formControlContainerClass={classes.afterValueContainer}
                    isSort={false}
                    withPortal
                  />

                  <Text rootClassName={classes.minutesText}>
                    {t('minute', {
                      count: values[names.afterValue]
                        ? values[names.afterValue]
                        : initialValues[names.afterValue]
                    })}
                  </Text>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.containerMB}>
              <Grid item xs={4}>
                <BlueButton
                  className={classes.oneTimeRebootBtn}
                  {...(rebootRequested && rebootRequestedData(t))}
                  onClick={() => handleOneTimeRebootClick('at')}
                >
                  {translations.at}
                </BlueButton>
              </Grid>

              <Grid item xs={8} className={classes.timePickerWrapper}>
                <FormControlDateTimePickerNew
                  name={names.at}
                  value={values[names.at]}
                  onChange={handleChange}
                  error={errors[names.at]}
                  touched={touched[names.at]}
                  displayFormat={DATE_TIME_VIEW_FORMAT}
                  popupPosition="top"
                  withTimePicker
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Card
          icon={false}
          shadow={false}
          radius={false}
          hasMargin={false}
          grayHeader
          removeSidePaddings
          headerSidePaddings
          removeNegativeHeaderSideMargins
          title={t('Recurring Device Reboot')}
          rootClassName={classes.cardRoot}
          headerClasses={[classes.cardHeader]}
          headerTextClasses={[classes.cardHeaderText]}
        >
          <Grid className={classes.cardContent}>
            <Grid container alignItems="center" className={classes.cardSection}>
              <Grid container>
                <Grid item xs={1} />
                <Grid item xs={10} className={classes.rebootEveryLabel}>
                  <Text rootClassName={classes.rebootText}>
                    {t('Reboot Every')}
                  </Text>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={1} />
                <Workdays
                  name={recurringNames.workingDays}
                  workingDays={
                    recurringValues[recurringNames.workingDays]
                      ? recurringValues[recurringNames.workingDays]
                      : initialRecurringValues[recurringNames.workingDays]
                  }
                  setFieldValue={setRecurringFieldValue}
                  xs={10}
                />
              </Grid>

              <Grid container>
                <Grid container>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <Text rootClassName={classes.rebootText}>{t('at')}</Text>
                  </Grid>
                  <Grid item className={classes.recurrenceTimeWrapper}>
                    <MaterialPopup
                      on="click"
                      placement="bottom"
                      trigger={
                        <FormControlInput
                          value={moment(
                            recurringValues[recurringNames.everyValue],
                            TIME_FORMAT
                          ).format(AM_PM_FORMAT)}
                          marginBottom={false}
                          readOnly
                        />
                      }
                      hasArrow={false}
                    >
                      <TimePickerNew
                        maskValue={TIME_FORMAT}
                        name={recurringNames.everyValue}
                        value={moment(
                          recurringValues[recurringNames.everyValue],
                          TIME_FORMAT
                        ).format(AM_PM_FORMAT)}
                        onChange={onRecurringTimeChange}
                        showSeconds={false}
                        rootClassName={classes.listRoot}
                        noLabel
                      />
                    </MaterialPopup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <BlueButton
                    {...(data.periodicalRebootSettings
                      ? {
                          tooltipText: t(
                            'A reboot schedule is already active on the device. Please remove that request to assign a new request'
                          )
                        }
                      : {
                          onClick: handleScheduleClick
                        })}
                    className={classes.scheduleRebootBtn}
                    opaque={data.periodicalRebootSettings}
                  >
                    {t('Schedule')}
                  </BlueButton>
                </Grid>
              </Grid>

              {!!data.periodicalRebootSettings?.rebootDay &&
                !!data.periodicalRebootSettings?.rebootTime && (
                  <Grid container justifyContent="center" alignItems="center">
                    <Text fontStyle="italic" rootClassName={classes.setMessage}>
                      {`${t(
                        'Currently, Reboots are scheduled for'
                      )} ${scheduledDaysMessage} ${t('at')} ${moment(
                        data.periodicalRebootSettings.rebootTime,
                        TIME_S_FORMAT
                      ).format(AM_PM_FORMAT)}`}
                    </Text>
                    <Close
                      onClick={handleRemoveSchedule}
                      className={classes.removeScheduleIcon}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  )
}

RebootForm.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  id: PropTypes.number,
  data: PropTypes.object,
  initialRebootValues: PropTypes.object,
  putReducer: PropTypes.object,
  onClear: PropTypes.func,
  bulkReboot: PropTypes.bool
}

export default withStyles(styles)(RebootForm)
