import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import bandwidthPackageSaga from 'sagas/bandwidthPackageSaga'

const actions = [
  types.POST_BANDWIDTH_PACKAGE_SUCCESS,
  types.PUT_BANDWIDTH_PACKAGE_SUCCESS
]

const bandwidthPackageInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, bandwidthPackageSaga.invalidateBandwidthPackageTags)
  )

export default bandwidthPackageInvalidateCache
