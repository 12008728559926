import {
  ENTERPRISE_ROLE,
  ENTERPRISE_TOKEN,
  EXPIRES_IN,
  FACEBOOK_TOKEN,
  LAST_ACTIVITY,
  ORG_ROLE,
  ORG_TOKEN,
  SYSTEM_ROLE,
  SYSTEM_TOKEN
} from 'constants/api'
import { calculateExpires } from 'utils/date'
import { LIBRARY_VIEW_SETTINGS, VIEW_SETTINGS } from 'constants/localStorage'
import { entityConstants } from '../constants'
import { libraryViews } from '../constants/library'
import update from 'immutability-helper'
import getUserRoleLevel from './getUserRoleLevel'

export function storageSetItem(name, value) {
  window.localStorage.setItem(name, value)
}

export function storageRemoveItem(name) {
  window.localStorage.removeItem(name)
}

export function storageGetItem(name) {
  return window.localStorage.getItem(name)
}

export function storageClear() {
  storageRemoveItem(ORG_TOKEN)
  storageRemoveItem(SYSTEM_TOKEN)
  storageRemoveItem(ENTERPRISE_TOKEN)
  storageRemoveItem(EXPIRES_IN)
  storageRemoveItem(LAST_ACTIVITY)
  storageRemoveItem(FACEBOOK_TOKEN)
}

export function storageSetToken(name, type, token, expires) {
  storageClear()
  storageSetItem(name, `${type} ${token}`)
  storageSetItem(EXPIRES_IN, calculateExpires(expires))
}

export function getTokenNameByRole(role) {
  switch (role) {
    case ORG_ROLE:
      return ORG_TOKEN
    case SYSTEM_ROLE:
      return SYSTEM_TOKEN
    case ENTERPRISE_ROLE:
      return ENTERPRISE_TOKEN
    default:
      return ORG_TOKEN
  }
}

export function storageGetExpires() {
  return storageGetItem(EXPIRES_IN)
}

export function getOriginalUser() {
  const originalUsersList = JSON.parse(localStorage.getItem('originalUsers'))
  if (originalUsersList?.length) {
    return originalUsersList.pop()
  }
}

export const getUrlPrefixByTokenType = type => {
  switch (type) {
    case ORG_TOKEN:
      return 'org'
    case ENTERPRISE_TOKEN:
      return 'enterprise'
    case SYSTEM_TOKEN:
      return 'system'
    default:
      return getUserRoleLevel()
  }
}

export function getApiParamsByOriginalUser(url) {
  const { token, type } = getOriginalUser() || {}

  const prefix = getUrlPrefixByTokenType(type)

  return {
    url: `${prefix === 'enterprise' && url === '/me' ? 'org' : prefix}${url}`,
    headers: {
      Authorization: token
    },
    impersonated: true
  }
}

export function saveLibraryView(storageSettings) {
  const localStoragePathSettings = JSON.parse(
    storageGetItem(LIBRARY_VIEW_SETTINGS)
  )

  if (
    localStoragePathSettings &&
    typeof localStoragePathSettings === 'object'
  ) {
    const settings = update(
      Object.fromEntries(
        Object.entries(localStoragePathSettings).filter(
          ([key, value]) =>
            Object.values(entityConstants).includes(key) &&
            Object.values(libraryViews).includes(value)
        )
      ),
      { $merge: storageSettings }
    )

    storageSetItem(LIBRARY_VIEW_SETTINGS, JSON.stringify(settings))
  } else {
    storageSetItem(LIBRARY_VIEW_SETTINGS, JSON.stringify(storageSettings))
  }
}

export const getViewSettings = () => {
  try {
    return JSON.parse(localStorage.getItem(VIEW_SETTINGS)) || {}
  } catch (e) {
    return {}
  }
}

export const getViewSetting = (key, fallback) => {
  const settings = getViewSettings()
  const view = settings[key] || fallback

  if ([...Object.values(libraryViews), fallback].includes(view)) {
    return view
  }

  return libraryViews.list
}
