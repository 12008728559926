import { HTML_CONTENT, EMAIL_TEMPLATE } from 'constants/library'
import { combineReducers } from '@reduxjs/toolkit'
import login from './loginReducer'
import user from './userReducer'
import logout from './logoutReducer'
import createTemplate from './createTemplateReducer'
import resetPassword from './resetPasswordReducer'
import media from './mediaReducer'
import addMedia from './addMediaReducer'
import addPlaylist from './addPlaylistReducer'
import playlist from './playlistReducer'
import template from './templateReducer'
import schedule from './scheduleReducer'
import device from './deviceReducer'
import report from './reportReducer'
import clients from './clientReducer'
import users from './usersReducer'
import dashboard from './dashboardReducer'
import feedback from './feedbackReducer'
import group from './groupReducer'
import designGallery from './designGallery/designGalleryReducer'
import leftSidebar from './designGallery/leftSidebarReducer'
import config from './configReducer'
import fonts from './fontsReducer'
import clientPackage from './clientPackageReducer'
import devicePackage from './devicePackageReducer'
import bandwidthPackage from './bandwidthPackageReducer'
import clientSettings from './clientSettingsReducer'
import settings from './settingsReducer'
import HTMLContent from './HTMLContentReducer'
import emailTemplate from './emailTemplateReducer'
import helps from './helpReducer'
import contentAppHelps from './contentAppHelpReducer'
import alert from './alertReducer'
import role from './roleReducer'
import preference from './preferenceReducer'
import impersonateReducer from 'reducers/impersonateReducer'
import whiteLabel from './whiteLabelReducer'
import smartPlaylist from './smartPlaylistReducer'
import quoteReducer from './quoteReducer'
import appReducer from './appReducer'
import resellerClients from './resellerClientsReducer'
import resellerClientUsers from './resellerClientUserReducer'
import ipProviders from './ipProviders'
import refreshToken from 'reducers/refreshTokenReducer'
import filters from 'reducers/filters'
import menuMaker from 'reducers/menuMakerReducer'
import emailNotification from 'reducers/emailNotificationReducer'
import scrollReducer from 'reducers/scrollReducer'
import tableauReducer from 'reducers/tableauReducer'
import calendarAggregatorReducer from './calendarAggregatorReducer'
import deviceNoc from './deviceNocReducer'
import publicReducer from './public'
import menuDesign from 'reducers/menuDesign/menuDesignReducer'
import menuDesignLeftSidebar from 'reducers/menuDesign/leftSidebarReducer'
import postHog from 'reducers/postHogReducer'
import mediaFeaturesReducer from './new/mediaFeatures'
import appSliceReducer from 'slices/appSlice'
import filtersSliceReducer from 'slices/filtersSlice'
import customParametersSliceReducer from 'slices/customParametersSlice'
import surveySliceReducer from 'slices/surveySlice'
import oauth2SliceReducer from 'slices/oauth2Slice'

// NEW (Refactor)
import clientReducer from './new/client'
import deviceReducer from './new/device'
import authReducer from './new/auth'
import searchReducer from './new/search'
import googleDocumentReducer from './googleDocumentReducer'
import oauth2Reducer from 'reducers/new/oauth2'

const editorReducer = combineReducers({
  designGallery,
  leftSidebar
})

const rootReducer = {
  appReducer,
  appSliceReducer,
  surveySliceReducer,
  user,
  users,
  login,
  media,
  addMedia,
  addPlaylist,
  group,
  logout,
  device,
  clients,
  schedule,
  report,
  feedback,
  playlist,
  template,
  dashboard,
  resetPassword,
  impersonateReducer,
  createTemplate,
  editor: editorReducer,
  config,
  fonts,
  [HTML_CONTENT]: HTMLContent,
  clientSettings,
  settings,
  clientPackage,
  devicePackage,
  bandwidthPackage,
  [EMAIL_TEMPLATE]: emailTemplate,
  helps,
  contentAppHelps,
  role,
  preference,
  whiteLabel,
  quoteReducer,
  smartPlaylist,
  alert,
  resellerClients,
  ipProviders,
  refreshToken,
  filters,
  filtersSliceReducer,
  customParametersSliceReducer,
  menuMaker,
  emailNotification,
  resellerClientUsers,
  scrollReducer,
  tableauReducer,
  calendarAggregator: calendarAggregatorReducer,
  googleDocumentReducer,
  deviceNoc,
  menuDesign,
  menuDesignLeftSidebar,
  new: combineReducers({
    client: clientReducer,
    device: deviceReducer,
    auth: authReducer,
    search: searchReducer,
    oauth2: oauth2Reducer,
    mediaFeatures: mediaFeaturesReducer
  }),
  public: publicReducer,
  postHog,
  oauth2SliceReducer
}

export default rootReducer
