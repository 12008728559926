import React, { useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

import { shadeColor } from 'utils'
import { getContrastTextFromRgb } from 'utils/color'
import { isFontAwesomeIcon } from 'utils/featureUtils'

const styles = theme => ({
  typeIconWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: theme.palette[theme.type].typeIcon.boxShadow
  },
  typeIcon: {
    fontSize: '20px',
    color: '#fff',
    cursor: 'pointer'
  },
  faTypeIcon: {
    fontSize: '16px',
    paddingTop: '2px'
  },
  asteriskWrapper: {
    position: 'relative'
  },
  asterisk: {
    position: 'absolute',
    top: 2,
    right: 5,
    fontSize: 14,
    width: 6,
    height: 6
  }
})

const LibraryTypeIcon = React.forwardRef(
  (
    {
      classes,
      color,
      wrapHelperClass,
      iconHelperClass,
      iconColor,
      fontSize,
      className,
      icon: Icon = null,
      iconComponentColor,
      wrapperClassName,
      withAsterisk,
      asteriskClass,
      ...props
    },
    ref
  ) => {
    const { typeIcon, faTypeIcon, typeIconWrap } = classes

    const backgroundImage = useMemo(
      () =>
        color
          ? `linear-gradient(to bottom, ${shadeColor(color, 20)}, ${color})`
          : 'linear-gradient(to bottom, #e5dc58, #c6b72d)',
      [color]
    )

    const isFAIcon = isFontAwesomeIcon(iconHelperClass)

    return (
      <div
        ref={ref}
        className={classNames(typeIconWrap, wrapHelperClass, className, {
          [classes.asteriskWrapper]: withAsterisk
        })}
        style={{ backgroundImage }}
        {...props}
        id="libraryTypeIcon"
      >
        {!Icon ? (
          <>
            {withAsterisk && (
              <span className={classNames(classes.asterisk, asteriskClass)}>
                *
              </span>
            )}
            <i
              style={{
                color: iconColor || getContrastTextFromRgb(color),
                ...(fontSize && { fontSize })
              }}
              className={classNames(typeIcon, iconHelperClass, {
                'icon-world-wide-web': !iconHelperClass,
                [faTypeIcon]: isFAIcon
              })}
            />
          </>
        ) : (
          <Icon
            className={classNames(typeIcon, iconHelperClass, {
              [faTypeIcon]: isFAIcon
            })}
            iconComponentColor={iconComponentColor}
            wrapperClassName={wrapperClassName}
          />
        )}
      </div>
    )
  }
)

export default withStyles(styles)(LibraryTypeIcon)
