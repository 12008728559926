import { lazy } from 'react'
import { WysiwygEditor } from './WysiwygEditor'
import SliderInputRange from './SliderInputRange'
import FormControlInput from './FormControlInput'
import FormControlTelInput from './FormControlTelInput'
import FormControlCountrySelect from './FormControlCountrySelect'
import FormControlDateRangePickers from './FormControlDateRangePickers'
import FormControlSingleDatePicker from './FormControlSingleDatePicker'
import FormControlMultipleDatesPicker from './FormControlMultipleDatesPicker'
import FormControlMultipleTimePicker from './FormControlMultipleTimePicker'
import FormControlCustomSelect from './FormControlCustomSelect'
import FormControlCheckbox from './FormControlCheckbox'
import FormControlDateTimePicker from './FormControlDateTimePicker'
import FormControlChips from './FormControlChips'
import FormControlPaletteSelector from './FormControlPaletteSelector'
import FormControlRadio from './FormControlRadio'
import FormControlReactSelect from './FormControlReactSelect'
import FormControlSelectWithSearch from './FormControlSelectWithSearch'
import FormControlSelectFontSync from './FormControlSelectFontSync'
import FormControlSelectFont from './FormControlSelectFont'
import FormControlSelectTextTheme from './FormControlSelectTextTheme'
import FormControlCounter from './FormControlCounter'
import FormControlAutocomplete from './FormControlAutocomplete'
import FormControlAutocompleteNew from './FormControlAutocompleteNew'
import FormControlAutocompleteQuery from './FormControlAutocompleteQuery'
import FormControlAutocompleteSync from './FormControlAutocompleteSync'
import FormControlFileDropzone from './FormControlFileDropzone'
import FormControlSelectResolution from './FormControlCounter'
import FormControlSelectSkin from './FormControlSelectSkin'
import FormControlBackgroundSelect from './FormControlBackgroundSelect'
import FormControlDateTimeRangePicker from './FormControlDateTimeRangePicker'
import FormControlMediaTypeChips from './FormControlMediaTypeChips'
import FormControlColorPicker from './FormControlColorPicker'
import FormControlChipsColorPicker from './FormControlChipsColorPicker'
import WrapperDatePicker from './WrapperDatePicker'
import FormControlCustomDateRangePickers from './FormControlCustomDateRangePickers'
import FormControlMultyInput from './FormControlMultyInput'
import FormControlPalette from './FormControlPalette'
import {
  FormControlTimeDurationPicker,
  FormControlMaterialDateTimePicker
} from './FormControlMaterialTimePickers'
import FormControlImage from './FormControlImage'
import FormControlSelectClient from './FormControlSelectClient'
import FormControlDaysSelection from './FormControlDaysSelection'
import FormControlSelectTag from './FormControlSelectTag'
import FormControlSelectGroup from './FormControlSelectGroup'
import FormControlSelectLocations from './FormControlSelectLocations'
import FormControlMultipleDateTimePicker from './FormControlMultipleDateTimePicker'
import FormControlSelectTimezone from './FormControlSelectTimezone'
import FormControlIconPicker from './FormControlIconPicker'
import FormControlMultipleColorPicker from './FormControlMultipleColorPicker'
import FormControlBrandGuideColorPicker from './FormControlBrandGuideColorPicker'
import FormControlBrandGuideSelectFont from './FormControlBrandGuideSelectFont'
import VideoWallMatrixInput from './VideoWallMatrixInput'
import FormControlCreatableAutocomplete from './FormControlCreatableAutocomplete'
import FormControlDatePickerNew from './formControlDateTimePickers/FormControlDatePickerNew'
import FormControlDateTimePickerNew from './formControlDateTimePickers/FormControlDateTimePickerNew'
import FormControlDateRangePickerNew from './formControlDateTimePickers/FormControlDateRangePickerNew'
import FormControlDateTimeRangePickerNew from './formControlDateTimePickers/FormControlDateTimeRangePickerNew'
import FormControlButtonSelect from './ButtonWithPopup/FormControlButtonSelect'
import FormControlButtonAutocomplete from './ButtonWithPopup/FormControlButtonAutocomplete'
import FormControlSelectDevice from './FormControlSelectDevice'
import FormControlAmadeusSelect from './FormControlAmadeusSelect'

const CanvasThemeEditor = lazy(() =>
  import('components/CanvasEditors').then(module => ({
    default: module.CanvasThemeEditor
  }))
)

export {
  WysiwygEditor,
  FormControlInput,
  SliderInputRange,
  FormControlTelInput,
  FormControlCountrySelect,
  FormControlDateRangePickers,
  FormControlSingleDatePicker,
  FormControlMultipleDatesPicker,
  FormControlMultipleTimePicker,
  FormControlCustomSelect,
  FormControlCheckbox,
  FormControlDateTimePicker,
  FormControlChips,
  FormControlPaletteSelector,
  FormControlRadio,
  FormControlReactSelect,
  FormControlSelectWithSearch,
  FormControlSelectFontSync,
  FormControlSelectFont,
  FormControlSelectTextTheme,
  FormControlCounter,
  FormControlAutocomplete,
  FormControlAutocompleteNew,
  FormControlAutocompleteQuery,
  FormControlAutocompleteSync,
  FormControlFileDropzone,
  FormControlSelectResolution,
  FormControlSelectSkin,
  FormControlBackgroundSelect,
  FormControlDateTimeRangePicker,
  FormControlMediaTypeChips,
  FormControlColorPicker,
  FormControlChipsColorPicker,
  WrapperDatePicker,
  FormControlCustomDateRangePickers,
  FormControlMultyInput,
  FormControlPalette,
  FormControlMaterialDateTimePicker,
  FormControlTimeDurationPicker,
  FormControlImage,
  FormControlDaysSelection,
  FormControlSelectClient,
  FormControlSelectTag,
  FormControlSelectGroup,
  FormControlSelectLocations,
  FormControlMultipleDateTimePicker,
  FormControlSelectTimezone,
  CanvasThemeEditor,
  FormControlIconPicker,
  FormControlMultipleColorPicker,
  FormControlBrandGuideColorPicker,
  FormControlBrandGuideSelectFont,
  VideoWallMatrixInput,
  FormControlCreatableAutocomplete,
  FormControlDatePickerNew,
  FormControlDateTimePickerNew,
  FormControlDateRangePickerNew,
  FormControlDateTimeRangePickerNew,
  FormControlButtonSelect,
  FormControlButtonAutocomplete,
  FormControlSelectDevice,
  FormControlAmadeusSelect
}
