import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'

export const helpPagesApi = createAppApi('helpPages', {
  tagTypes: [apiTags.helpPages],
  endpoints: builder => ({
    getHelpPages: builder.query({
      providesTags: [apiTags.helpPages],
      query: (params = {}) => ({
        url: '/help',
        params
      })
    }),
    putHelpPages: builder.mutation({
      invalidatesTags: [apiTags.helpPages],
      query: ({ id, data = {} }) => ({
        url: `/help/${id}`,
        method: 'PUT',
        data
      })
    })
  })
})

export const { useGetHelpPagesQuery, usePutHelpPagesMutation } = helpPagesApi
