import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControlReactSelect } from 'components/Form'
import AmadeusOption from './AmadeusOption'
import { _get } from 'utils/lodash'
import { simulateEvent } from 'utils'
import { locationsDataToOptions } from 'utils/api/amodeusUtils'
import { useGetAmadeusLocationsQuery } from 'api/amadeusApi'
import { amadeusOptionTypes as optionTypes } from 'constants/commonOptions'

const initialSelectionConfig = {
  [optionTypes.location]: true,
  [optionTypes.roomGroup]: true,
  [optionTypes.room]: true
}

const styles = {
  option: {
    '&:disabled': {
      background: 'unset',
      color: 'unset'
    }
  }
}

const FormControlAmadeusSelect = ({
  tokenId,
  values,
  onChange,
  name,
  isMulti,
  selectionConfig = initialSelectionConfig,
  useGetOptionsQuery = useGetAmadeusLocationsQuery,
  dataToOptionsTransformer = locationsDataToOptions,
  addOptionProps = (option, options) => ({}),
  staticRequestParams,
  onOptionsChanged = options => {},
  isFormReset,
  ...props
}) => {
  const { t } = useTranslation()

  const { data, isFetching } = useGetOptionsQuery(
    { tokenId, ...(staticRequestParams || {}) },
    { skip: !tokenId || props.disabled }
  )

  const currentSelectionConfig = useMemo(
    () => ({
      ...initialSelectionConfig,
      ...selectionConfig
    }),
    [selectionConfig]
  )

  const handleChange = useCallback(
    e => {
      if (!isMulti) {
        onChange(e)
      }

      const {
        target: { options, value, name }
      } = e

      if (_get(options, '[0].action') === 'select-option') {
        const item = _get(options, '[0].option', {})

        let filteredValue = value

        if (item.type === optionTypes.location) {
          filteredValue = filteredValue.filter(
            option => !option.meta || option.meta?.locationId !== item.value
          )
        }

        if (item.type === optionTypes.roomGroup) {
          filteredValue = filteredValue.filter(
            option =>
              option.type !== optionTypes.room ||
              option.meta?.roomGroupId !== item.value
          )
        }

        if (item.meta?.locationId) {
          filteredValue = filteredValue.filter(
            option =>
              option.type !== optionTypes.location ||
              option.value !== item.meta.locationId
          )
        }

        if (item.meta?.roomGroupId) {
          filteredValue = filteredValue.filter(
            option =>
              option.type !== optionTypes.roomGroup ||
              option.value !== item.meta.roomGroupId
          )
        }

        onChange(simulateEvent(name, filteredValue))
        return
      }
      onChange(e)
    },
    [onChange, isMulti]
  )

  const options = useMemo(
    () =>
      dataToOptionsTransformer(data)
        .map(option => ({
          ...option,
          ...(!option.custom && {
            isDisabled: !currentSelectionConfig[option.type]
          }),
          ...(option.custom && {
            label: t(option.label)
          })
        }))
        .map((option, index, arr) => ({
          ...option,
          ...addOptionProps(option, arr)
        })),
    [dataToOptionsTransformer, data, t, currentSelectionConfig, addOptionProps]
  )

  useEffect(() => {
    onOptionsChanged(options)
    // eslint-disable-next-line
  }, [options, values, isFormReset])

  return (
    <FormControlReactSelect
      {...props}
      name={name}
      {...(isMulti && { values })}
      isLoading={isFetching}
      options={options}
      onChange={handleChange}
      components={{
        Option: AmadeusOption
      }}
      styles={styles}
      hideSelectedOptions={false}
      isSort={false}
      isMulti={isMulti}
    />
  )
}

export default FormControlAmadeusSelect
