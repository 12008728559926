import React from 'react'

import { _isNumber, _isString } from 'utils/lodash'
import { TextWithTooltip } from 'components/Typography'
import { Grid } from '@material-ui/core'

const mapAlignValueToFlex = value => {
  switch (value) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    default:
      return value
  }
}

const TextCell = ({ value, colDef, ...props }) => {
  const { align = 'flex-start' } = colDef

  const cellValue = value || 'N/A'

  return (
    <Grid container justifyContent={mapAlignValueToFlex(align)}>
      <TextWithTooltip maxWidth="100%" {...props}>
        {(_isString(cellValue) || _isNumber(cellValue)) && cellValue}
      </TextWithTooltip>
    </Grid>
  )
}

export default TextCell
