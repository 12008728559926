import React from 'react'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    paddingLeft: 14,
    height: '100%',
    borderInlineStartStyle: 'solid',
    borderInlineStartWidth: '1px',
    alignItems: 'flex-start',
    display: 'flex',
    borderInlineStartColor: '#fff3',
    marginLeft: 20,
    paddingTop: 2
  },
  icon: {
    cursor: 'pointer',
    marginRight: '0 !important'
  }
}))

const CloseSnackbarIcon = ({ onClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CloseIcon onClick={onClick} id="close-icon" className={classes.icon} />
    </div>
  )
}

export default CloseSnackbarIcon
