import { fabric } from 'fabric'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { _get, _uniq, _uniqBy } from 'utils/lodash'

import {
  SAVED_OBJECT_OPTIONS,
  TABS_NAMES,
  replaceValueForBE,
  DEFAULT_PATTERN_COLOR,
  canvasFontVariantsToLoad
} from 'constants/canvasConstants'
import { IMAGE_FEATURE_ID } from 'constants/featureConstants'
import { shapeOfMeta } from 'constants/initialLibraryState'
import { designGalleryService, fontsService, mediaService } from 'services'
import capitalize from './capitalize'
import { approveStatuses } from 'constants/library'
import { isTruthy } from 'utils/generalUtils'
import { fabricGif } from 'components/Pages/DesignGallery/components/canvas/helpers/fabricGif'
import { combineFontName, getFontDescriptors } from './fontUtils'
import { addLoadedFont } from 'actions/fontsActions'
import * as types from 'actions/index'
import { getThumbnail } from './mediaUtils'

export const convertToPreviewData = (
  data = [],
  type,
  selected = false,
  designTypeFallback
) => {
  return data.map(
    ({ id, name, content, canvas, type: designType, ...rest }) => ({
      id,
      name,
      src: { small: getThumbnail(rest), original: content },
      type: TABS_NAMES.patterns,
      selected,
      showAs: type,
      canvas,
      designType: designType || designTypeFallback,
      extra: rest
    })
  )
}

export const filterFontCombinations = (data, searchTerm = '') => {
  const term = searchTerm.toLowerCase()
  return term
    ? data.filter(
        ({ main, secondary }) =>
          main.toLowerCase().includes(term) ||
          secondary.toLowerCase().includes(term)
      )
    : data
}

export const filterTabByRender = tabs =>
  tabs.filter(tab => (tab.hasOwnProperty('render') ? tab.render : true))

export const sortTabByOrder = tabs =>
  tabs.sort((a, b) => (a.order > b.order ? 1 : -1))

export const getCanvasConfig = (canvas, removeClipPath = true) => {
  const config = canvas.toJSON([...SAVED_OBJECT_OPTIONS, 'clipPath', 'variant'])
  const parsedConfig = {
    ...config,
    objects: config.objects.map(obj => {
      if (obj.patternSettings?.url) {
        return { ...obj, fill: null }
      }
      if (
        obj.isComponentBackground ||
        (obj.isProperty && obj.type === 'image')
      ) {
        obj.clipPathId = obj.clipPath.id
      }
      removeClipPath && delete obj.clipPath
      return obj
    })
  }

  return JSON.stringify(parsedConfig)
}

export const checkMediaIsLandscape = ({ resolution }) => {
  if (!resolution) {
    return false
  }
  const [width, height] = resolution.split('x')
  return Number.parseInt(width) > Number.parseInt(height)
}

export const createLocationState = (backTo, meta = {}) => ({
  backTo,
  meta: {
    requestData: {},
    design: undefined,
    ...meta
  }
})

export const getReplacedObjectValueForBE = (obj, fromBE = false) => {
  replaceValueForBE.forEach(({ property, feValue, beValue }) => {
    if (obj[property]) {
      if (fromBE && obj[property] === beValue) {
        obj[property] = feValue
      } else if (obj[property] === feValue) {
        obj[property] = beValue
      }
    }
  })
  return obj
}

export const getLibraryImagesOption = async (value, params) => {
  try {
    const response = await mediaService.getMediaGridViewEmbeddedItems({
      fields: 'title,labels,id',
      labelOrName: value || undefined,
      sort: 'title',
      order: 'asc',
      approvedStatus: approveStatuses.approve,
      featureId: IMAGE_FEATURE_ID,
      cancelable: true,
      ...params
    })

    response.data = response.data.map(({ title, id }) => ({
      label: title,
      value: id
    }))

    return response
  } catch (err) {
    return {
      data: [],
      meta: {
        ...shapeOfMeta
      }
    }
  }
}

export const getStockImagesOption = async (value, params) => {
  try {
    const response = await designGalleryService.getImageLabels({
      label: value || undefined,
      sort: 'label',
      order: 'asc',
      relatedImage: 'stock',
      ...params
    })
    response.data = response.data.map(({ label }) => ({
      label,
      value: label
    }))

    return response
  } catch (err) {
    return {
      data: [],
      meta: {
        ...shapeOfMeta
      }
    }
  }
}

export const getRoyaltyImagesOption = async (value, params, appConfig) => {
  try {
    const response = await designGalleryService.getRoyaltyImages(
      {
        royaltyImagesPage: params.page || 1,
        sort: 'label',
        order: 'asc',
        perPage: params.limit,
        query: value
      },
      appConfig
    )
    const lowerCaseSearch = value.toLowerCase()

    const data = _uniqBy(
      response.hits.reduce(
        (a, b) =>
          a.concat(
            b.tags
              ? b.tags
                  .split(',')
                  .filter(tag => tag.toLowerCase().includes(lowerCaseSearch))
                  .map(tag => ({
                    label: capitalize(tag.trim()),
                    value: tag.trim()
                  }))
              : []
          ),
        []
      ),
      'label'
    )

    return {
      data,
      meta: {
        ...shapeOfMeta,
        currentPage: params.page,
        lastPage: Math.ceil(response.totalHits / params.limit),
        perPage: params.limit,
        total: response.total
      }
    }
  } catch (err) {
    return {
      data: [],
      meta: {
        ...shapeOfMeta
      }
    }
  }
}

export const convertDataToBackgroundImages = data => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    src: { small: item.thumb, original: item.content },
    type: TABS_NAMES.background,
    selected: false,
    showAs: 'bg'
  }))
}

export const convertDataToPattern = data => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    src: { small: item.thumb, original: item.content },
    type: TABS_NAMES.patterns,
    selected: false,
    showAs: 'bg'
  }))
}

export const convertDataToShape = data => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    src: { small: item.thumb, original: item.content },
    type: TABS_NAMES.shapes,
    selected: false,
    showAs: 'svg'
  }))
}

export const convertDataToObjects = data => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    src: { small: item.thumb, original: item.content },
    type: TABS_NAMES.objects,
    selected: false,
    showAs: 'svg'
  }))
}

export const convertDataToIcons = data => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    src: { small: item.thumb, original: item.content },
    type: TABS_NAMES.emojis,
    selected: false,
    showAs: 'svg'
  }))
}

export const convertDataToIconGroups = data => {
  return Object.keys(data).reduce(
    (acc, next) => ({
      ...acc,
      [next]: data[next].map(item => ({
        id: item.id,
        name: item.name,
        src: { small: item.thumb, original: item.content },
        type: TABS_NAMES.emojis,
        selected: false,
        showAs: 'svg'
      }))
    }),
    {}
  )
}

export const convertDataToEmojis = data => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    src: { small: item.thumb, original: item.content },
    type: TABS_NAMES.emojis,
    selected: false,
    showAs: 'svg'
  }))
}

export const loadPatternToBlob = async url => {
  const data = await fetch(url)
  const blob = await data.blob()

  return blob
}

const recurrsiveSetColor = (node, color) => {
  if (
    [
      'g',
      'altGlyph',
      'circle',
      'ellipse',
      'path',
      'polygon',
      'polyline',
      'rect',
      'text',
      'textPath',
      'tref',
      'tspan'
    ].includes(node.tagName)
  ) {
    node.setAttribute && node.setAttribute('fill', color)
  }
  if (node.childNodes.length) {
    node.childNodes.forEach(doc => {
      recurrsiveSetColor(doc, color)
    })
  }
}

export const setPatternColor = async (blob, color = DEFAULT_PATTERN_COLOR) => {
  const svg = await blob.text()
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(svg, 'text/xml')
  recurrsiveSetColor(xmlDoc.getElementsByTagName('svg')[0], color)
  const newBlob = new Blob([new XMLSerializer().serializeToString(xmlDoc)], {
    type: 'image/svg+xml'
  })

  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(newBlob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export const getCoordsForRotatePoint = (
  sourcePoint,
  parentObj,
  prevAngle,
  newAngle
) => {
  let origX = sourcePoint.x
  let origY = sourcePoint.y
  let topLeftPoint = new fabric.Point(origX, origY)

  let angle = fabric.util.degreesToRadians(newAngle - prevAngle)
  let center = parentObj.getCenterPoint()
  let origin = new fabric.Point(center.x, center.y)
  let { x, y } = fabric.util.rotatePoint(topLeftPoint, origin, angle)

  return {
    x,
    y
  }
}

export const fetchAndModifySvg = async url => {
  const response = await fetch(url)
  const svgString = await response.text()
  const parser = new DOMParser()
  const doc = parser.parseFromString(svgString, 'image/svg+xml')
  const svgElement = doc.querySelector('svg')
  svgElement.setAttribute('preserveAspectRatio', 'none')
  const serializer = new XMLSerializer()
  const modifiedSvgString = serializer.serializeToString(svgElement)
  return 'data:image/svg+xml;base64,' + window.btoa(modifiedSvgString)
}

export const isDeletable = object => {
  if (!object) {
    return false
  }
  const { isFrame, isBackground, locked } = object

  return isTruthy(!isFrame, !isBackground, !locked)
}

export const isCopyable = object => {
  if (!object) {
    return false
  }
  const { isFrame, isBackground } = object

  return isTruthy(!isFrame, !isBackground)
}

export const isGroupingAllowed = object => {
  if (!object) {
    return false
  }
  return object.type === 'activeSelection'
}

export const isGroupingDisabled = object => {
  if (!object) {
    return false
  }
  return object.type === 'group' && object.isGroupedObject
}

export const getFabricGifImage = async ({
  id,
  url,
  external,
  width,
  height,
  showSnackbar = f => f,
  t = f => f
}) => {
  const gifObj = await fabricGif({
    gif: url,
    maxWidth: width,
    maxHeight: height,
    showSnackbar,
    t
  })

  if (gifObj) {
    gifObj.type = 'image'
    gifObj.imageId = id
    gifObj.external = external
    gifObj.url = url
    gifObj.isGif = true
  }

  return gifObj
}

export const loadCanvasFonts = async (fonts, dispatchAction) => {
  const response = await fontsService.getFonts({
    family: _uniq(fonts).join(','),
    limit: 9999
  })
  if (response?.data) {
    const promiseSequence = []
    const isFireFox = navigator.userAgent.includes('Firefox')
    response.data.forEach(({ family, variants }) => {
      const allowedVariants = variants.filter(({ variant }) =>
        canvasFontVariantsToLoad.includes(variant)
      )
      const variantsToLoad =
        allowedVariants?.length && !canvasFontVariantsToLoad.includes('*')
          ? allowedVariants
          : variants
      variantsToLoad.forEach(({ file, variant }) => {
        const fontPromise = new FontFace(
          isFireFox ? `"${family}"` : family,
          `url(${file})`,
          getFontDescriptors(variant)
        )
          .load()
          .then(fontFace => document.fonts.add(fontFace))
          .finally(() =>
            dispatchAction(addLoadedFont(combineFontName(family, variant)))
          )
        promiseSequence.push(fontPromise)
      })
    })
    await Promise.allSettled(promiseSequence)
  }
  dispatchAction({ type: types.GET_FONTS_SUCCESS, payload: response })
  return response
}

export const prepareGiphyImages = ({ gifs }) => {
  const lastImageId = _get(gifs, `${gifs.length - 1}.id`)

  return gifs.map(image => ({
    id: image.id,
    isLastInPage: lastImageId === image.id,
    name: image.title,
    resolution: `${image.images.original.width}x${image.images.original.height}`,
    showAs: 'image',
    type: 'GIPHY Images',
    src: {
      original: image.images.original.url,
      small: image.images.preview_gif.url
    },
    external: true
  }))
}

export const gf = ({ appConfig }) => {
  if (!appConfig.REACT_APP_GIPHY_API_KEY) {
    return {}
  }

  return new GiphyFetch(appConfig.REACT_APP_GIPHY_API_KEY)
}

export const getGiphyImagesOption = async ({ value, appConfig }) => {
  const { data: categories } = await gf({ appConfig }).categories()

  const data = categories
    .map(category => ({
      value: category.name_encoded,
      label: category.name
    }))
    .filter(({ label }) => label.toLowerCase().includes(value.toLowerCase()))

  try {
    return {
      data,
      meta: {
        ...shapeOfMeta
      }
    }
  } catch (err) {
    return {
      data: [],
      meta: {
        ...shapeOfMeta
      }
    }
  }
}
