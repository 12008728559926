import { lazy } from 'react'

const AdminDashboard = lazy(() => import('./Dashboard'))
const UsersLibrary = lazy(() => import('./UsersLibrary'))
const ResellerUsersLibrary = lazy(() => import('./ResellerUsersLibrary'))
const DeviceLibrary = lazy(() => import('./DeviceLibrary'))
const DeviceNOC = lazy(() => import('./DeviceNOC'))
const AppVersionsLibrary = lazy(() => import('./AppVersionsLibrary'))
const BrightSignOSVersionsLibrary = lazy(() =>
  import('./BrightSignOSVersionsLibrary')
)
const ClientLicensesLibrary = lazy(() => import('./ClientLicensesLibrary'))
const LicensesLibrary = lazy(() => import('./LicensesLibrary'))
const ClientsLibrary = lazy(() => import('./ClientsLibrary'))
const PackagesLibrary = lazy(() => import('./PackagesLibrary'))
const HelpPagesLibrary = lazy(() => import('./HelpPagesLibrary'))
const ContentAppHelpPagesLibrary = lazy(() =>
  import('./ContentAppHelpPagesLibrary')
)
const ChannelsLibrary = lazy(() => import('./ChannelsLibrary'))
const OEMClientsLibrary = lazy(() => import('./OEMClientsLibrary'))
const TagsLibrary = lazy(() => import('./TagsLibrary'))
const VideoTutorialsComponent = lazy(() => import('./VideoTutorials'))
const ClientUsersLibrary = lazy(() => import('./ClientUsersLibrary'))
const UserEulaTerms = lazy(() => import('./ClientUsersLibrary/UserEulaTerms'))
const MediaContentSource = lazy(() => import('./MediaContentSource'))
const ResellerClientsLibrary = lazy(() => import('./ResellerClientsLibrary'))
const ResellerClientUsersLibrary = lazy(() =>
  import('./ResellerClientUsersLibrary')
)
const DeviceModelsLibrary = lazy(() => import('./DeviceModelsLibrary'))
const SuperAdminSettings = lazy(() => import('./SuperAdminSettings'))
const DeviceHealth = lazy(() => import('./DeviceLibrary/DeviceHealth'))
const EmailNotificationsLibrary = lazy(() =>
  import('./EmailNotificationsLibrary')
)
const FeatureManagement = lazy(() => import('./FeatureManagement'))

export {
  DeviceHealth,
  SuperAdminSettings,
  AdminDashboard,
  UsersLibrary,
  DeviceLibrary,
  DeviceNOC,
  AppVersionsLibrary,
  BrightSignOSVersionsLibrary,
  ClientLicensesLibrary,
  LicensesLibrary,
  ClientsLibrary,
  PackagesLibrary,
  HelpPagesLibrary,
  ContentAppHelpPagesLibrary,
  ChannelsLibrary,
  OEMClientsLibrary,
  TagsLibrary,
  ClientUsersLibrary,
  UserEulaTerms,
  MediaContentSource,
  ResellerClientsLibrary,
  ResellerClientUsersLibrary,
  EmailNotificationsLibrary,
  VideoTutorialsComponent,
  DeviceModelsLibrary,
  FeatureManagement,
  ResellerUsersLibrary
}
