import routeByName from './routes'

export const permissionTypes = {
  read: 'read',
  write: 'write',
  create: 'create',
  update: 'update',
  delete: 'delete',
  other: 'other',
  approve: 'approve' // custom FE type
}

export const DASHBOARD = 'dashboard'
export const DEVICE = 'device'
export const DEVICE_REBOOT = 'deviceReboot'
export const DEVICE_TYPE = 'deviceType'
export const DEVICE_GROUP = 'deviceGroup'
export const DEVICE_ALERT = 'deviceAlert'
export const DEVICE_LICENSE = 'device license'
export const DEVICE_THUMBNAIL = 'deviceThumbnail'
export const DEVICE_HEALTH = 'deviceHealth'
export const APP_HEALTH = 'app health'
export const DEVICE_OFFLINE_NOTIFICATION = 'deviceOfflineNotification'
export const SYSTEM_DEVICE_ASSIGN_APP_VERSION = 'systemDeviceAssignAppVersion'
export const SYSTEM_DEVICE_TYPE_ASSIGN_APP_VERSION =
  'systemDeviceTypeAssignAppVersion'
export const CLIENT_DEVICE_ASSIGN_APP_VERSION = 'clientDeviceAssignAppVersion'
export const APP_VERSION = 'appVersion'
export const MEDIA = 'media'
export const MEDIA_GROUP = 'mediaGroup'
export const MEDIA_CONTENT_SOURCE = 'mediaContentSource'
export const MEDIA_CONTENT_CATEGORY = 'mediaContentCategory'
export const MEDIA_FONT = 'mediaFont'
export const DESIGN_GALLERY = 'designGallery'
export const PLAYLIST = 'playlist'
export const PLAYLIST_GROUP = 'playlistGroup'
export const SMART_PLAYLIST = 'smartPlaylist'
export const INTERACTIVE_PLAYLIST = 'interactivePlaylist'
export const ONE_DRIVE_PLAYLIST = 'onedrive playlist'
export const TEMPLATE = 'template'
export const TEMPLATE_GROUP = 'templateGroup'
export const TEMPLATE_BACKGROUND_PATTERN = 'templateBackgroundPattern'
export const SCHEDULE = 'schedule'
export const SCHEDULE_GROUP = 'scheduleGroup'
export const SETTING = 'setting'
export const USER = 'user'
export const USER_PERMISSION = 'userPermission'
export const USER_GROUP = 'userGroup'
export const CLIENT_USER = 'clientUser'
export const TAG = 'tag'
export const CLIENT = 'client'
export const CLIENT_GROUP = 'clientGroup' // enterprise client group
export const GROUP = 'group' // org/system client group
export const CLIENT_NOTE = 'client note'
export const HTML_CONTENT = 'htmlContent'
export const ROLES = 'roles'
export const EULA = 'eula process'
export const POSTER = 'poster'
export const POSTER_TAG = 'posterTag'
export const HELP_PAGE = 'helpPage'
export const CONTENT_APP_HELP_PAGE = 'contentAppHelpPage'
export const CONTENT_APP_HELP_PAGE_DETAIL = 'contentAppHelpPageDetail'
export const CLIENT_PACKAGE = 'clientPackage'
export const CLIENT_LICENSE = 'clientLicense'
export const BANDWIDTH_PACKAGE = 'bandwidthPackage'
export const DEVICE_PACKAGE = 'devicePackage'
export const IMPERSONATE = 'impersonate'
export const ORG_REPORT = 'orgReport'
export const ORG_REPORT_CONFIG = 'orgReportConfig' // custom FE group
export const SYSTEM_REPORT = 'systemReport'
export const SYSTEM_REPORT_CONFIG = 'systemReportConfig' // custom FE group
export const MENU_MAKER = 'menu maker'
export const DEVICE_LOCATION_VIEW = 'deviceLocationView'
export const DYNAMIC_EMAIL_NOTIFICATION = 'dynamic email notification'
export const PREFERENCE = 'preference'
export const RESELLER_CLIENT = 'resellerClient'
export const RESELLER_CLIENT_USER = 'resellerClientUser'
export const RESELLER_USER = 'resellerUser'
export const USER_GROUP_STORE = 'userGroupStore'
export const DEVICE_ALARM_NOTIFICATION = 'deviceAlarmNotification'
export const IP_CIDR = 'ipCidr'
export const DEVICE_GLOBAL_CONFIG = 'deviceGlobalConfig'
export const TUTORIAL_VIDEOS = 'VideoTutorials'
export const CALENDAR_AGGREGATOR = 'calendar aggregator'
export const FEATURE = 'feature'
export const DEVICE_REFRESH_LICENSE = 'device refresh license'
export const DEVICE_OS_FIRMWARE = 'deviceOsFirmware'

export const redirectUrlsForUnauthorized = {
  [DASHBOARD]: 'dashboard',
  [MEDIA]: routeByName.media.root,
  [DEVICE]: routeByName.device.root,
  [PLAYLIST]: routeByName.playlist.root,
  [TEMPLATE]: routeByName.template.root,
  [SCHEDULE]: routeByName.schedule.root,
  [ORG_REPORT]: routeByName.report.root,
  [CLIENT]: routeByName.clients.root,
  [USER]: routeByName.users.root,
  [TAG]: routeByName.tag.root,
  [RESELLER_CLIENT]: routeByName.reseller.client,
  [RESELLER_CLIENT_USER]: routeByName.reseller.clientUser
}

export const permissionPageGroups = {
  device: 'device',
  group: 'group',
  media: 'media',
  playlist: 'playlist',
  smartplaylist: 'smartplaylist',
  schedule: 'schedule',
  template: 'template',
  user: 'user',
  client: 'client'
}
