import { createAppApi } from 'services/api'
import { createSuccessInvalidator, getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'
import { deviceQueryParamsHelper } from 'utils/queryParamsHelpers'

export const deviceApi = createAppApi('deviceQuery', {
  tagTypes: [apiTags.device, apiTags.appVersion, apiTags.osFirmware],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      url: '/device',
      builder,
      tags: [apiTags.device],
      name: 'Device'
    }),
    getDevices: builder.query({
      providesTags: [apiTags.device],
      query: (params = {}) => ({
        url: '/device',
        params: deviceQueryParamsHelper(params)
      })
    }),
    bulkTriggerDevicePreview: builder.query({
      query: (params = { limit: 9999 }) => ({
        url: `/device/preview/bulkTriggerDevice`,
        params
      })
    }),
    deviceList: builder.query({
      query: (params = {}) => ({
        url: '/device/list',
        params: deviceQueryParamsHelper(params)
      })
    }),
    deviceGrid: builder.query({
      query: (params = {}) => ({
        url: '/device/gridView',
        params: deviceQueryParamsHelper(params)
      })
    }),
    deviceNoc: builder.query({
      query: (params = {}) => ({
        url: '/org/public/noc/general',
        params: deviceQueryParamsHelper(params)
      })
    }),
    publicUptime: builder.query({
      query: (params = {}) => ({
        url: '/public/noc/uptime',
        params: deviceQueryParamsHelper(params)
      })
    }),
    fetchSupportToken: builder.mutation({
      query: (data = {}) => ({
        url: '/device/fetchSupportToken',
        method: 'POST',
        data
      })
    }),
    getDeviceUpTime: builder.query({
      query: (params = {}) => ({
        url: `/device/noc/uptime`,
        params: deviceQueryParamsHelper(params)
      })
    }),
    ...getCRUDEndpoints({
      name: 'AppVersions',
      url: '/device/appVersion',
      builder,
      tags: [apiTags.appVersion]
    }),
    postDeviceCustomParameters: builder.mutation({
      query: data => ({
        url: '/device/customParameters',
        data,
        method: 'POST'
      })
    }),
    ...getCRUDEndpoints({
      name: 'OsFirmware',
      url: '/device/osFirmware',
      builder,
      tags: [apiTags.osFirmware]
    }),
    bulkDeleteOsFirmware: builder.mutation({
      query: (ids = {}) => ({
        url: `device/osFirmware/bulk?ids=${ids}`,
        method: 'DELETE',
        invalidatesTags: createSuccessInvalidator([apiTags.osFirmware])
      })
    }),
    applyDeviceOSFirmware: builder.mutation({
      query: (data = {}) => ({
        url: 'device/applyDeviceOSFirmware',
        method: 'POST',
        data
      })
    })
  })
})

export const {
  usePostDeviceMutation,
  useGetDeviceQuery,
  useLazyBulkTriggerDevicePreviewQuery,
  useLazyDeviceListQuery,
  useDeviceListQuery,
  useLazyDeviceGridQuery,
  useLazyDeviceNocQuery,
  useLazyPublicUptimeQuery,
  useLazyGetDevicesQuery,
  useGetDevicesQuery,
  useFetchSupportTokenMutation,
  useLazyGetDeviceUpTimeQuery,
  useGetAppVersionssQuery,
  useDeleteAppVersionsMutation,
  usePostDeviceCustomParametersMutation,
  useGetOsFirmwaresQuery,
  useLazyGetOsFirmwaresQuery,
  useGetOsFirmwareQuery,
  usePostOsFirmwareMutation,
  usePutOsFirmwareMutation,
  useDeleteOsFirmwareMutation,
  useBulkDeleteOsFirmwareMutation,
  useApplyDeviceOSFirmwareMutation
} = deviceApi
