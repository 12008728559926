import { makeStyles } from '@material-ui/core'
import { components } from 'react-select'

import Tooltip from 'components/Tooltip'
import { Text } from 'components/Typography'
import { Spacing } from 'components/Containers'
import { Checkbox } from 'components/Checkboxes'
import { amadeusOptionTypes as optionTypes } from 'constants/commonOptions'

const optionNestingLevelByType = {
  [optionTypes.location]: 0,
  [optionTypes.roomGroup]: 1,
  [optionTypes.room]: 2
}

const useStyles = makeStyles(({ fontSize, spacing }) => ({
  label: ({ nestingLevel }) => ({
    marginLeft: spacing(1),
    ...(nestingLevel < 3 && { color: '#999' }),
    ...(nestingLevel === 1 && { fontWeight: 700 }),
    ...(nestingLevel === 2 && { fontWeight: 600, fontSize: fontSize.small }),
    ...(nestingLevel === 3 && { fontSize: fontSize.smaller })
  }),
  checkbox: ({ isPassiveSelected }) => ({
    ...(isPassiveSelected && { opacity: 0.4 })
  })
}))

const AmadeusOption = props => {
  const { data, isSelected } = props
  const value = props.getValue() || []
  const includedInSelection = value.some(
    ({ value }) =>
      value === data.meta?.locationId || value === data.meta?.roomGroupId
  )

  const nestingLevel = optionNestingLevelByType[data.type]
  const classes = useStyles({
    nestingLevel,
    isPassiveSelected: !isSelected && includedInSelection
  })

  return (
    <components.Option
      {...{
        ...props,
        data: {
          ...data,
          isDisabled: data.isDisabled || data.isSelectionDisabled
        }
      }}
    >
      <Spacing direction="row" variant={0} paddingLeft={nestingLevel}>
        {(!data.isDisabled || data.isSelectionDisabled) && (
          <Checkbox
            className={classes.checkbox}
            size="small"
            checked={isSelected || includedInSelection}
            disabled={data.isSelectionDisabled}
          />
        )}
        <Tooltip title={data.tooltip || ''}>
          <Text rootClassName={classes.label}>{data.label}</Text>
        </Tooltip>
      </Spacing>
    </components.Option>
  )
}

export default AmadeusOption
