import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import helpSaga from 'sagas/helpSaga'

const actions = [types.PUT_HELP_SUCCESS]

const helpInvalidateCache = () =>
  actions.map(action => takeLatest(action, helpSaga.invalidateHelpTags))

export default helpInvalidateCache
