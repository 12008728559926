export const SETTINGS = 'settings'
export const SECURITY_SETTINGS = 'securitySettings'
export const CLIENT_DETAILS = 'clientDetails'
export const PREMIUM_FEATURES = 'premiumFeatures'
export const ADMIN_DETAILS = 'adminDetails'
export const BANDWIDTH_DETAILS = 'bandwidthDetails'

export const STANDARD_CLIENT_TYPE = 'STANDARD_CLIENT'
export const RESELLER_CLIENT_TYPE = 'RESELLER_CLIENT'
export const ENTERPRISE_CLIENT_TYPE = 'ENTERPRISE_CLIENT'
export const OEM_CLIENT_TYPE = 'OEM_CLIENT'

export const SIGNATURE_CARE = 'Signature Care'
export const EXPRESS_SUPPORT = 'Express Support'
export const STANDARD_SUPPORT = 'Standard Support'

export const clientTypeColors = {
  'white-label': '#f5a623',
  [STANDARD_CLIENT_TYPE]: '#2ecc71',
  [RESELLER_CLIENT_TYPE]: '#3498db',
  [ENTERPRISE_CLIENT_TYPE]: '#9b59b6',
  [OEM_CLIENT_TYPE]: '#f1c40f'
}

export const clientStatuses = {
  Active: 'Active',
  Inactive: 'Inactive',
  Dormant: 'Dormant'
}
