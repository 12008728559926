import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import menuMakerSaga from 'sagas/menuMakerSaga'

const actions = [
  types.POST_MENU_STATION_SUCCESS,
  types.PUT_MENU_STATION_SUCCESS
]

const menuStationsInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, menuMakerSaga.invalidateMenuStationsTags)
  )

export default menuStationsInvalidateCache
