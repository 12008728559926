import { _countBy, _get } from 'utils/lodash'
import moment from 'moment'

import { playlistStatus, playlistTypes } from 'constants/playlist'
import { featureNames } from 'constants/featureConstants'
import { getUrlPrefix } from './index'
import {
  MEDIA_FALLBACK_TIME,
  mediaStatus,
  PLAYLIST_FALLBACK_TRANSITION_ID
} from 'constants/media'
import { getMediaStatus, getThumbnail } from 'utils/mediaUtils'
import routeByName from 'constants/routes'

export const getPlaylistFeatureNameByType = playlistType => {
  switch (playlistType) {
    case playlistTypes.Interactive.name:
      return featureNames.InteractiveContent
    case playlistTypes.Smart.name:
      return featureNames.SmartPlaylist

    default:
      return playlistTypes.Standard.name
  }
}

export const parseCollectionTags = tagList =>
  tagList.map(tagInfo => {
    const { tag, ...restTagInfo } = tagInfo
    return {
      ...restTagInfo,
      tagId: tag.map(({ value }) => value)
    }
  })

export const getCommonDuration = items => {
  return Object.entries(_countBy(items, 'duration')).reduce(
    (a, b) => (a?.[1] < b?.[1] ? b : a),
    [MEDIA_FALLBACK_TIME, 0]
  )?.[0]
}

export const getCommonTransition = items => {
  const transition = Object.entries(_countBy(items, 'transition.id')).reduce(
    (a, b) => (a?.[1] < b?.[1] ? b : a),
    [PLAYLIST_FALLBACK_TRANSITION_ID, 0]
  )?.[0]

  return Number(transition || PLAYLIST_FALLBACK_TRANSITION_ID)
}

export const getPlaylistStatus = (playlist = {}) => {
  const { activateOn, expireOn, approved, isFeatureAuthenticated } = playlist

  if (playlist.hasOwnProperty('approved') && !approved)
    return playlistStatus.notApproved

  if (expireOn && moment(expireOn).isBefore(moment())) {
    return playlistStatus.expired
  }

  if (moment().isBefore(moment(activateOn))) return playlistStatus.activates

  if (expireOn) {
    return playlistStatus.expires
  }

  if (
    playlist.hasOwnProperty('isFeatureAuthenticated') &&
    !isFeatureAuthenticated
  ) {
    return playlistStatus.deauthorized
  }
}

export const getPlaylistMedia = ({ mediaList }) => {
  const visibleMedia = []
  const hiddenMedia = []

  mediaList.forEach(media => {
    if (!(mediaStatus.expired === getMediaStatus(media) && !media.isRetain)) {
      visibleMedia.push(media)
    } else {
      hiddenMedia.push(media)
    }
  })

  return {
    visibleMedia,
    hiddenMedia
  }
}

export const setMediaSortOrder = mediaList =>
  mediaList.map((media, index) => ({
    ...media,
    sortOrder: index
  }))

export const getEditPath = ({ playlistType, id, view = 'list' }) => {
  switch (playlistType) {
    case playlistTypes.Folder.name:
      return getUrlPrefix(
        routeByName.playlist.editMagicFolderPlaylist(id, view)
      )
    case playlistTypes.Smart.name:
      return getUrlPrefix(routeByName.playlist.editSmartPlaylist(id, view))
    case playlistTypes.Interactive.name:
      return getUrlPrefix(
        routeByName.playlist.editInteractivePlaylist(id, view)
      )

    default:
      return getUrlPrefix(routeByName.playlist.editWithId(id, view))
  }
}

export const getPlaylistThumbnail = playlist => {
  return getThumbnail(playlist.thumbnailMedia || _get(playlist, 'media[0]', {}))
}
