import { createAppApi } from 'services/api'
import { createSuccessInvalidator, getCRUDEndpoints } from 'utils/apiUtils'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import apiTags from '../constants/apiTags'

export const templatesApi = createAppApi('templatesQuery', {
  tagTypes: [apiTags.template, apiTags.templateGallery],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      url: '/template',
      builder,
      name: 'Template',
      tags: [apiTags.template]
    }),
    publishTemplates: builder.mutation({
      query: (data = {}) => ({
        url: '/template/bulkPublish',
        method: 'POST',
        data
      })
    }),
    publishTemplate: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.template]),
      query: ({ id, ...data }) => ({
        url: `/template/${id}/publish`,
        method: 'POST',
        data
      })
    }),
    copyTemplates: builder.mutation({
      query: (data = {}) => {
        const { impersonated, ...restData } = data
        return {
          url: '/template/bulkCopy',
          method: 'POST',
          data: restData,
          ...(impersonated && getApiParamsByOriginalUser('/template/bulkCopy'))
        }
      }
    }),
    shareTemplates: builder.mutation({
      query: (data = {}) => ({
        url: '/template/bulkShare',
        method: 'POST',
        data
      })
    }),
    copyTemplateGallery: builder.mutation({
      query: (data = {}) => ({
        url: `/templateGallery/copy`,
        method: 'PUT',
        data
      })
    }),
    getTemplatesBackground: builder.query({
      providesTags: [apiTags.templatesBackground],
      query: (params = {}) => ({
        url: '/template/backgroundPattern',
        params
      })
    }),
    ...getCRUDEndpoints({
      url: '/templateGallery',
      builder,
      name: 'TemplateGalleryItem',
      tags: [apiTags.templateGallery]
    })
  })
})

export const {
  usePutTemplateMutation,
  useDeleteTemplateMutation,
  usePublishTemplateMutation,
  useLazyGetTemplateQuery,
  useLazyGetTemplatesQuery,
  usePublishTemplatesMutation,
  useCopyTemplatesMutation,
  useShareTemplatesMutation,
  useCopyTemplateGalleryMutation,
  useGetTemplateQuery,
  useGetTemplatesQuery,
  useGetTemplateGalleryItemQuery,
  useGetTemplatesBackgroundQuery
} = templatesApi
