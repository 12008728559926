import store from '../store/configureStore'
import history from '../history'
import { clearUserDetailsAction } from 'actions/userActions'
import { clearLogoutInfo, clearLoginInfo } from 'actions/authenticationActions'
import { clearClientStore } from 'actions/new/client'
import { clearSettingsAction } from 'actions/settingsActions'
import { clearConfigReducer } from 'actions/configActions'
import getUserType from './getUserType'
import { apiConstants } from '../constants'
import { storageClear } from 'utils/localStorage'
import socket from '../services/socket'
import { LOGOUT_EVENT_NAME } from 'constants/api'
import { setTotalActiveInactiveDevices } from 'slices/appSlice'

const handleUnauthorized = async () => {
  const type = getUserType()

  document.dispatchEvent(new Event(LOGOUT_EVENT_NAME))

  await store.dispatch(clearLogoutInfo())
  await store.dispatch(clearLoginInfo())
  await store.dispatch(clearSettingsAction())
  await store.dispatch(clearClientStore())
  await store.dispatch(clearUserDetailsAction())
  await store.dispatch(clearConfigReducer())
  await store.dispatch(setTotalActiveInactiveDevices())
  socket.disconnect()

  storageClear()

  let path
  if (type === apiConstants.SYSTEM_USER) {
    path = '/system/sign-in'
  } else {
    if (type === apiConstants.ENTERPRISE_USER) {
      path = 'enterprise/sign-in'
    } else {
      path =
        history.location.pathname === '/system/sign-in'
          ? '/system/sign-in'
          : '/sign-in'
    }
  }

  history.push(path)
  history.go(0)
}

export default handleUnauthorized
