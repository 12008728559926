import { useMemo } from 'react'

import { getFeatureIdByGroup } from 'utils/mediaUtils'
import useConfigMediaCategory from 'hooks/api/useConfigMediaCategory'

const useDetermineMediaFeatureId = (group, returnFeature) => {
  const configMediaCategory = useConfigMediaCategory()

  return useMemo(
    () => getFeatureIdByGroup(configMediaCategory, group, returnFeature),
    [configMediaCategory, group, returnFeature]
  )
}

export default useDetermineMediaFeatureId
