import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUserIpAction } from 'actions/userActions'

const useIpAddress = () => {
  const dispatch = useDispatch()
  const { response, isPending, status } = useSelector(({ user }) => user.userIp)

  useEffect(
    () => {
      if (!isPending && !response.ip && status !== 'fulfilled') {
        dispatch(getUserIpAction())
      }
    },
    // eslint-disable-next-line
    []
  )

  return useMemo(
    () => ({
      ipAddress: response.ip
    }),
    [response]
  )
}

export default useIpAddress
