import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import devicePackageSaga from 'sagas/devicePackageSaga'

const actions = [
  types.POST_DEVICE_PACKAGE_SUCCESS,
  types.PUT_DEVICE_PACKAGE_SUCCESS
]

const devicePackageInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, devicePackageSaga.invalidateDevicePackageTags)
  )

export default devicePackageInvalidateCache
