import { useCallback, useEffect, useMemo, useState } from 'react'

export default function useGetAllQuery({
  useLazyQuery,
  params,
  perRequest = 100
}) {
  const [getItems, result] = useLazyQuery()
  const { error } = result
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])

  const getAll = useCallback(() => {
    setLoading(true)
    setItems([])
    getItems({ ...params, page: 1, limit: perRequest })
  }, [params, perRequest, getItems])

  useEffect(() => {
    const { isSuccess, isError } = result
    const data = result.data?.data || []
    const { currentPage, lastPage } = result.data?.meta || {}

    if (isSuccess) {
      setItems(prev => [...prev, ...data])
      if (currentPage < lastPage) {
        getItems({ ...params, page: currentPage + 1, limit: perRequest })
      } else {
        setLoading(false)
      }
    } else if (isError) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [result])

  return useMemo(
    () => ({
      items: loading ? null : items,
      loading,
      getAll,
      error
    }),
    [loading, items, getAll, error]
  )
}
