import { createAppApi } from 'services/api'

export const amadeusApi = createAppApi('amadeusApi', {
  endpoints: builder => ({
    getAmadeusLocations: builder.query({
      query: params => ({
        method: 'GET',
        url: '/amadeus/locations',
        params
      })
    }),
    getAmadeusRooms: builder.query({
      query: params => ({
        method: 'GET',
        url: '/amadeus/fnRooms',
        params
      })
    })
  })
})

export const {
  useGetAmadeusLocationsQuery,
  useGetAmadeusRoomsQuery
} = amadeusApi
