import React from 'react'
import { makeStyles } from '@material-ui/core'
import moment from 'moment/moment'

import LibraryTagChips from 'components/LibraryTagChips'

const useStyles = makeStyles(theme => ({
  root: {
    lineHeight: 'normal'
  }
}))

const TagsCell = ({ data, column, colDef }) => {
  const classes = useStyles()

  const { tag, tags, licenseExpiration } = data
  const { actualWidth, sort } = column

  return (
    <LibraryTagChips
      tags={tag || tags}
      descend={sort === 'desc'}
      maxWidth={actualWidth - 40}
      rootClasses={classes.root}
      isDevicesLibrary={colDef.isDevicesLibrary}
      maxDisplayedTags={colDef.maxDisplayedTags || 5}
      isLicenseExpired={
        licenseExpiration && moment(licenseExpiration).isBefore(moment())
      }
    />
  )
}

export default TagsCell
