import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  topBannerPreview: {},
  viewSettings: {},
  recoveryCodes: [],
  totalActiveInactiveDevices: undefined
}

export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    showTopBannerPreview: (state, action) => {
      state.topBannerPreview = action.payload
    },
    hideTopBannerPreview: state => {
      state.topBannerPreview = initialState.topBannerPreview
    },
    setViewSetting: (state, action) => {
      state.viewSettings[action.payload.key] = action.payload.view
    },
    setRecoveryCodes: (state, action) => {
      state.recoveryCodes = action.payload
    },
    setTotalActiveInactiveDevices: (state, action) => {
      state.totalActiveInactiveDevices = action.payload
    }
  }
})

export const {
  showTopBannerPreview,
  hideTopBannerPreview,
  setViewSetting,
  setRecoveryCodes,
  setTotalActiveInactiveDevices
} = appSlice.actions

export default appSlice.reducer
