import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { queryParamsHelper } from 'utils'
import { deviceQueryHelper } from 'utils/scheduleUtils'
import { createMergeListEndpoint } from '../utils/apiUtils'

export const reportsApi = createAppApi('reportsQuery', {
  tagTypes: [apiTags.report, apiTags.accountLogging],
  endpoints: builder => ({
    //TODO rename getReports and getMultipleReport to match the url
    getReports: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/countApiCall`,
        params: { ...params }
      })
    }),
    getMultipleReport: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: '/report',
        params
      })
    }),
    getExposureCount: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/playbackPlayingLog/exposureCount`,
        params: queryParamsHelper({
          ...params,
          ...(params.device && deviceQueryHelper(params.device))
        })
      })
    }),
    getExposureTime: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/playbackPlayingLog/exposureTime`,
        params: queryParamsHelper({
          ...params,
          ...(params.device && deviceQueryHelper(params.device))
        })
      })
    }),
    getVerboseData: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/playbackPlayingLog/getVerboseData`,
        params: queryParamsHelper({
          ...params,
          ...(params.device && deviceQueryHelper(params.device))
        })
      })
    }),
    getAccountLog: builder.query({
      query: params => ({
        url: '/report/accountLog',
        params
      })
    }),
    ...createMergeListEndpoint({
      builder,
      name: 'getAccountLog',
      url: '/report/accountLog',
      type: [apiTags.accountLogging]
    })
  })
})

export const {
  useLazyGetReportsQuery,
  useGetMultipleReportQuery,
  useGetExposureCountQuery,
  useGetExposureTimeQuery,
  useGetVerboseDataQuery,
  useGetAccountLogQuery,
  useGetAccountLogMergeQuery,
  useLazyGetAccountLogQuery
} = reportsApi
