import update from 'immutability-helper'
import axios from 'axios'
import { _get, _isEmpty } from 'utils/lodash'

import { ObjectToFormData } from 'utils/mediaUtils'
import {
  checkFirstFileIsUpdate,
  preparePayloadFileDataForUppy,
  transformFileMap
} from 'utils/uppy'
import {
  BMP,
  GIF,
  JPG,
  JPEG,
  SVG,
  MOV,
  MP3,
  MP4,
  OGG,
  PDF,
  PNG,
  WAV,
  WEBM,
  OGG_VIDEO,
  WAV_2
} from 'constants/mimeTypes'
import {
  getExtensionFromPath,
  getFileExtensionFromUrl,
  typesToExtensions,
  videoFileExtensions
} from 'utils/generalUtils'

export const MAX_SFTP_PORT = 65535

export const sourceTypes = {
  FILE: 'file',
  WEB: 'web',
  LOCAL: 'local',
  SFTP: 'sftp'
}

export function convertRequestData(requestData, uploadType, mode) {
  let data = requestData
  if (uploadType === 'file' && requestData.file) {
    data = ObjectToFormData(requestData)
    data.delete('attributes[same_for_all]')
    data.delete('attributes[type]')
    data.set('attributes', JSON.stringify(requestData.attributes))
  }
  if (uploadType === 'file' && mode === 'edit' && requestData.file) {
    data.append('_method', 'PUT')
  }
  return data
}

export const videoTypes = [MP4, MOV, WEBM, OGG_VIDEO]

export const audioTypes = [MP3, OGG, WAV, WAV_2, WEBM]

export const imageTypes = [BMP, JPG, JPEG, SVG, PNG, GIF]

export const pdfTypes = [PDF]

export const folderMimeType = 'application/vnd.google-apps.folder'

const generalAllowedTypes = [
  BMP,
  GIF,
  JPG,
  JPEG,
  SVG,
  MOV,
  MP3,
  MP4,
  OGG,
  PDF,
  PNG,
  WAV,
  WEBM
]

export const urlTypes = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
  PDF: 'PDF',
  UNDEFINED: 'UNDEFINED'
}

export const getUrlType = url => {
  const ext = getFileExtensionFromUrl(url)

  if (typesToExtensions(videoTypes).includes(ext)) {
    return urlTypes.VIDEO
  } else if (typesToExtensions(audioTypes).includes(ext)) {
    return urlTypes.AUDIO
  } else if (typesToExtensions(imageTypes).includes(ext)) {
    return urlTypes.IMAGE
  } else if (typesToExtensions(pdfTypes).includes(ext)) {
    return urlTypes.PDF
  } else {
    return urlTypes.UNDEFINED
  }
}

export const getAllowedTypes = fromDesignGallery =>
  fromDesignGallery ? imageTypes : generalAllowedTypes

const prepareFileDataSFTP = files =>
  files.map(file => {
    if (!file.path) {
      return file
    }

    return {
      path: file.path,
      timestamp: file.last_modified,
      type: file.type,
      visibility: file.visibility,
      file_size: file.file_size,
      dirname: file.path.replace(/(.*[\\/])[^\\/]+$/, '$1') || '/',
      basename: file.path.replace(/^.*[\\/]/, ''),
      extension: file.path.split('.').pop(),
      filename: file.path.replace(/\.[^.]+$/, '').replace(/^.*[\\/]/, '')
    }
  })

export function createUploadRequestData(
  postData,
  values,
  mode,
  featureId,
  backendData,
  sftpValues,
  showDuration = false,
  isVideoType
) {
  const {
    type,
    files,
    webFileUrl,
    localFileUrl,
    duration,
    sameForAll,
    muteAudio,
    play_clip,
    start_time,
    end_time,
    page_duration,
    total_pages,
    localUrlType
  } = values

  const { sftp, file } = sftpValues
  const videoData = file.length
    ? {
        play_clip: sftpValues.play_clip,
        start_time: sftpValues.start_time,
        end_time: sftpValues.end_time
      }
    : { play_clip, start_time, end_time }

  postData.featureId = featureId
  postData.muteAudio = muteAudio

  let uploadType = type
  let uploadFileUrl = webFileUrl

  let requestData = {}
  if (uploadType === sourceTypes.FILE) {
    requestData = update(postData, {
      attributes: {
        $set: {
          type: uploadType,
          ...(isVideoType && { ...videoData })
        }
      }
    })

    if (checkFirstFileIsUpdate(files)) {
      requestData.file = transformFileMap(files)
    } else if (!_isEmpty(files)) {
      requestData.file = preparePayloadFileDataForUppy(
        backendData,
        files,
        false,
        false
      )
    }

    if (files.length > 1) {
      requestData.attributes.same_for_all = sameForAll
    }

    const isPDF = files.find(file => file.type === 'application/pdf')
    if (isPDF) requestData.attributes.page_duration = page_duration
  } else if (uploadType === sourceTypes.SFTP) {
    const fileExtension = getExtensionFromPath(file[0]?.path || file[0]?.name)
    requestData = update(postData, {
      muteAudio: { $set: sftpValues.muteAudio },
      sftp: {
        $set: {
          host: sftp.host,
          port: sftp.port,
          username: sftp.username,
          password: sftp.password
        }
      },
      attributes: {
        $set: {
          type: uploadType,
          ...(PDF.ext === fileExtension && {
            page_duration: sftpValues.page_duration
          }),
          ...(isVideoType && { ...videoData })
        }
      },
      file: {
        $set: prepareFileDataSFTP(file)
      }
    })
  } else if (uploadType === sourceTypes.LOCAL) {
    requestData = update(postData, {
      attributes: {
        $set: {
          type: uploadType,
          media_file_url: localFileUrl,
          media_file_duration: duration,
          local_url_type: localUrlType,
          ...(localUrlType === PDF.mime && {
            page_duration,
            total_pages
          }),
          ...(isVideoType && { ...videoData })
        }
      }
    })
  } else if (uploadType === sourceTypes.WEB) {
    requestData = update(postData, {
      attributes: {
        $set: {
          type: uploadType,
          media_file_url: uploadFileUrl,
          ...(![urlTypes.PDF].includes(getUrlType(localFileUrl)) && {
            page_duration
          }),
          ...(isVideoType && { ...videoData })
        }
      }
    })
  }
  return requestData
}

export const prepareSftpData = ({
  host,
  port,
  username,
  password,
  directory
}) => ({
  'sftp[host]': host,
  'sftp[port]': port,
  'sftp[username]': username,
  'sftp[password]': password,
  directory
})

export const getBlobFromUrl = async ({ url, showSnackbar, t }) => {
  try {
    const { data } = await axios.get(url, {
      responseType: 'blob'
    })

    return window.URL.createObjectURL(data)
  } catch (e) {
    showSnackbar(t('Unable to load file data'), 'error')
    throw new Error(e)
  }
}

export const DEFAULT_TIME = '00:00:00'

export const isVideoType = ({ values, sftpValues }) => {
  if (values.files || sftpValues.file) {
    const files = values.files.length ? values.files : sftpValues.file

    return !!(
      files.length &&
      videoFileExtensions.includes(
        getExtensionFromPath(_get(files, '[0].path') || _get(files, '[0].name'))
      )
    )
  }

  return true
}
