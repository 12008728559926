import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Text, TextWithTooltip } from 'components/Typography'

const useStyles = makeStyles(({ shapes, spacing, colors, palette, type }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: shapes.height.primary,
    backgroundColor: palette[type].pageContainer.header.background,
    border: `1px solid ${palette[type].formControls.input.border}`,
    borderRadius: shapes.height.primary / 2,
    padding: `0 ${spacing(1)}px`,
    userSelect: 'none',
    cursor: 'pointer'
  },
  label: {
    minWidth: 'fit-content'
  },
  highlighted: {
    borderColor: colors.highlight,
    background: colors.highlightedBackground
  },
  icon: {
    color: colors.hightlightedicons,
    marginRight: 4
  },
  clearIcon: {
    cursor: 'pointer',
    fontSize: '16px',
    width: 12,
    transition: 'all 0.2s ease',
    '&:hover': {
      marginLeft: -2,
      width: 14,
      fontSize: '20px'
    }
  },
  marginRight: {
    marginRight: spacing(1)
  },
  count: {
    backgroundColor: colors.highlight,
    borderRadius: 10,
    minWidth: 20,
    height: 20,
    textSize: 12,
    padding: '0 6px'
  }
}))

const PopupButton = ({ value, label, isMulti, options, onClear }) => {
  const classes = useStyles()

  const selectedOption =
    isMulti && value?.length > 1
      ? null
      : options.find(
          option =>
            option.value ===
            (isMulti ? (value || [])[0]?.value || (value || [])[0] : value)
        )

  const hasValue = isMulti ? !!value?.length : !!value

  return (
    <div
      className={classNames(classes.root, { [classes.highlighted]: hasValue })}
    >
      <Text
        rootClassName={classNames(classes.marginRight, classes.label)}
      >{`${label}${hasValue ? ':' : ''}`}</Text>
      {!hasValue && (
        <i className={classNames('fa-solid fa-caret-down', classes.icon)} />
      )}
      {selectedOption && (
        <Grid container alignItems="center" wrap="nowrap">
          {selectedOption?.meta?.icon &&
            (typeof selectedOption.meta.icon === 'string' ? (
              <i
                style={{ color: selectedOption.meta.color }}
                className={classNames(selectedOption.meta.icon, classes.icon)}
              />
            ) : (
              <selectedOption.meta.icon
                className={classes.icon}
                style={{ color: selectedOption.meta.color, width: 16 }}
              />
            ))}
          <TextWithTooltip maxWidth={150} rootClassName={classes.marginRight}>
            {selectedOption.label}
          </TextWithTooltip>
        </Grid>
      )}
      {isMulti &&
        (value?.length > 1 || (value?.length === 1 && !selectedOption)) && (
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classNames(classes.count, classes.marginRight)}
          >
            <Text weight="bold" variant="small" color="white">
              {value?.length}
            </Text>
          </Grid>
        )}
      {hasValue && (
        <i
          onClick={e => {
            e.stopPropagation()
            onClear()
          }}
          className={classNames(
            'fa-solid fa-xmark',
            classes.icon,
            classes.clearIcon
          )}
        />
      )}
    </div>
  )
}

PopupButton.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  onClear: PropTypes.func.isRequired
}

export default PopupButton
