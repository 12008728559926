import { _cloneDeep } from 'utils/lodash'
import { amadeusOptionTypes as optionTypes } from 'constants/commonOptions'

const getId = (data, type) => `${type}/${data.id}`

export const locationsDataToOptions = data => {
  const options = []
  const optionsData = _cloneDeep(data || [])

  optionsData
    .sort((a, b) => (a.locationName || '').localeCompare(b.locationName))
    .forEach(location => {
      const roomGroups = (location.functionRoomGroups || []).sort((a, b) =>
        (a.name || '').localeCompare(b.name)
      )
      const ungroupedRooms = (
        location.functionRooms?.filter(room => !room.functionRoomGroup) || []
      ).sort((a, b) => (a.name || '').localeCompare(b.name))

      const locationId = getId(location, optionTypes.location)

      options.push({
        value: locationId,
        label: location.locationName,
        type: optionTypes.location
      })

      roomGroups?.forEach(roomGroup => {
        const rooms = (
          location.functionRooms?.filter(
            room => room.functionRoomGroup?.id === roomGroup.id
          ) || []
        ).sort((a, b) => (a.name || '').localeCompare(b.name))

        const roomGroupId = getId(roomGroup, optionTypes.roomGroup)

        options.push({
          value: roomGroupId,
          label: roomGroup.name,
          type: optionTypes.roomGroup,
          meta: { locationId }
        })

        rooms.forEach(room => {
          options.push({
            value: getId(room, optionTypes.room),
            label: room.name,
            type: optionTypes.room,
            meta: {
              locationId,
              roomGroupId
            }
          })
        })
      })

      if (ungroupedRooms?.length) {
        options.push({
          label: 'Ungrouped',
          value: null,
          type: optionTypes.roomGroup,
          meta: { locationId },
          isDisabled: true,
          custom: true
        })

        ungroupedRooms.forEach(room => {
          options.push({
            value: getId(room, optionTypes.room),
            label: room.name,
            type: optionTypes.room,
            meta: { locationId }
          })
        })
      }
    })

  return options
}

export const roomDataToOptions = data => {
  const options = []
  const locations = []
  const roomGroups = []
  const rooms = _cloneDeep(data || [])

  rooms.forEach(room => {
    if (room.location && !locations.some(({ id }) => room.location.id === id)) {
      locations.push(room.location)
    }
    if (
      room.functionRoomGroup &&
      !roomGroups.some(({ id }) => room.functionRoomGroup.id === id)
    ) {
      roomGroups.push({
        ...room.functionRoomGroup,
        locationId: room.location?.id
      })
    }
  })

  locations
    .sort((a, b) => (a.locationName || '').localeCompare(b.locationName))
    .forEach(location => {
      const groups = roomGroups
        .filter(({ locationId }) => locationId === location.id)
        .sort((a, b) => (a.name || '').localeCompare(b.name))
      const ungroupedRooms =
        rooms.filter(
          room => !room.functionRoomGroup && room.location?.id === location.id
        ) || [].sort((a, b) => (a.name || '').localeCompare(b.name))

      const locationId = getId(location, optionTypes.location)

      options.push({
        value: locationId,
        label: location.locationName,
        type: optionTypes.location
      })

      groups?.forEach(roomGroup => {
        const filteredRooms = rooms
          .filter(room => room.functionRoomGroup?.id === roomGroup.id)
          .sort((a, b) => (a.name || '').localeCompare(b.name))

        const roomGroupId = getId(roomGroup, optionTypes.roomGroup)

        options.push({
          value: roomGroupId,
          label: roomGroup.name,
          type: optionTypes.roomGroup,
          meta: { locationId }
        })

        filteredRooms.forEach(room => {
          options.push({
            value: getId(room, optionTypes.room),
            label: room.name,
            type: optionTypes.room,
            meta: {
              locationId,
              roomGroupId,
              media: room.media
            }
          })
        })
      })

      if (ungroupedRooms?.length) {
        options.push({
          label: 'Ungrouped',
          value: null,
          type: optionTypes.roomGroup,
          meta: { locationId },
          isDisabled: true,
          custom: true
        })

        ungroupedRooms.forEach(room => {
          options.push({
            value: getId(room, optionTypes.room),
            label: room.name,
            type: optionTypes.room,
            meta: { locationId, media: room.media }
          })
        })
      }
    })

  return options
}
