import React, { useCallback, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'

import Spacing from 'components/Containers/Spacing'
import { setConfirmationRequired } from 'actions/appActions'

export default function useConfirmation(options) {
  const {
    defaultOnConfirm,
    onCancel,
    confirmButtonText,
    cancelButtonText,
    confirmButtonIcon,
    confirmButtonIconClassName = 'fa-regular fa-circle-check',
    cancelButtonIcon,
    cancelButtonIconClassName = 'fa-regular fa-circle-xmark',
    defaultMessage,
    singleButton,
    variant = 'warning',
    preventDuplicate = true,
    customActionComponent: CustomActionComponent
  } = useMemo(() => options || {}, [options])

  const dispatch = useDispatch()
  const [confirmFunc, setConfirmFunc] = useState(null)
  const [cancelFunc, setCancelFunc] = useState(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslation('translations')

  const handleCloseSnackbar = useCallback(
    key => {
      closeSnackbar(key)
      dispatch(setConfirmationRequired(false))
    },
    [closeSnackbar, dispatch]
  )

  const showConfirmation = useCallback(
    (message = defaultMessage, callback, cancelCallback, customVariant) => {
      dispatch(setConfirmationRequired(true))
      const fallbackFunction = f => f

      const onConfirmCallback =
        confirmFunc || callback || defaultOnConfirm || fallbackFunction
      const onCancelCallback =
        cancelFunc || cancelCallback || onCancel || fallbackFunction

      enqueueSnackbar(message, {
        variant: customVariant ? customVariant : variant,
        action: key => (
          <>
            {!singleButton && (
              <Spacing variant={0} paddingRight={1}>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => {
                    handleCloseSnackbar(key)
                    onCancelCallback()
                  }}
                  startIcon={
                    cancelButtonIcon || (
                      <i className={cancelButtonIconClassName}></i>
                    )
                  }
                >
                  {cancelButtonText || t('No')}
                </Button>
              </Spacing>
            )}
            <Spacing variant={0}>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  handleCloseSnackbar(key)
                  onConfirmCallback()
                }}
                startIcon={
                  confirmButtonIcon || (
                    <i className={confirmButtonIconClassName}></i>
                  )
                }
              >
                {confirmButtonText || (singleButton ? t('Ok') : t('Yes'))}
              </Button>
            </Spacing>
          </>
        ),
        ...(CustomActionComponent && {
          action: key => (
            <CustomActionComponent
              onClose={() => handleCloseSnackbar(key)}
              onConfirm={onConfirmCallback}
              onCancel={onCancelCallback}
            />
          )
        }),
        preventDuplicate,
        autoHideDuration: null
      })
    },
    [
      dispatch,
      enqueueSnackbar,
      confirmFunc,
      cancelFunc,
      defaultOnConfirm,
      onCancel,
      handleCloseSnackbar,
      defaultMessage,
      confirmButtonText,
      cancelButtonText,
      singleButton,
      t,
      cancelButtonIcon,
      cancelButtonIconClassName,
      confirmButtonIcon,
      confirmButtonIconClassName,
      variant,
      preventDuplicate,
      CustomActionComponent
    ]
  )

  return useMemo(
    () => ({
      showConfirmation,
      closeConfirmation: handleCloseSnackbar,
      setConfirmFunc,
      setCancelFunc
    }),
    [showConfirmation, handleCloseSnackbar]
  )
}

export const withConfirmation = (options = {}) => Component => props => {
  const confirmationProps = useConfirmation({ ...options })
  return <Component {...confirmationProps} {...props} />
}
