import { CLIENT_ADMIN } from 'constants/roles'

export const parse = (data = {}) => ({
  id: data.id,
  role: data.level,
  level: data.name,
  org: data.level === 'org',
  system: data.level === 'system',
  enterprise: data.level === 'enterprise',
  reseller: data.level === 'reseller'
})

export const isOrgAdmin = parsedRole =>
  parsedRole.org && parsedRole.level === CLIENT_ADMIN
