import { put, call, select } from 'redux-saga/effects'
import {
  logoutUserService,
  loginSocialService,
  updateTokenService,
  reset
} from 'services'
import * as types from '../actions'
import {
  impersonateSystemUserService,
  impersonateUserService,
  getSSODataService,
  getImpersonateToken as getImpersonateTokenService
} from 'services/authenticationService'
import * as roles from 'utils/roles'
import getTokenName from 'utils/getTokenName'
import {
  getOriginalUser,
  getTokenNameByRole,
  storageRemoveItem,
  storageSetToken
} from 'utils/localStorage'
import { loginSuccess } from 'actions/new/auth'
import { userDetailsSuccess } from 'actions/userActions'
import { ORIGINAL_USERS } from 'constants/api'
import { getDetails } from 'services/userService'
import { calculateExpires } from 'utils/date'

function* loginSuccessHandler({
  role,
  tokenType,
  accessToken,
  expiresIn,
  onSuccess
}) {
  storageRemoveItem(ORIGINAL_USERS)
  storageSetToken(getTokenNameByRole(role), tokenType, accessToken, expiresIn)

  const user = yield call(getDetails)

  if (role !== user.role.level) {
    storageSetToken(
      getTokenNameByRole(user.role.level),
      tokenType,
      accessToken,
      expiresIn
    )
  }

  yield put(userDetailsSuccess(user))
  yield onSuccess(user.role.level)
}

export function* loginOktaSaga({ onSuccess, ...payload }) {
  try {
    const response = yield call(loginSocialService, payload)
    yield put({ type: types.LOGIN_USER_SUCCESS, payload: response })
    yield loginSuccessHandler({
      role: payload.role,
      expiresIn: response.expiresIn,
      accessToken: response.accessToken,
      tokenType: response.tokenType,
      onSuccess: function* () {
        yield put(loginSuccess())
        onSuccess()
      }
    })
  } catch (error) {
    yield put({
      type: types.LOGIN_USER_ERROR,
      payload:
        error?.message || 'No account or user found with that login credentials'
    })
  }
}

export function* getSSODataSaga(payload) {
  try {
    const response = yield call(getSSODataService, payload)
    if (response.accessToken) {
      yield put({ type: types.LOGIN_USER_SUCCESS, payload: response })
      yield loginSuccessHandler({
        role: 'org',
        expiresIn: response.expiresIn,
        accessToken: response.accessToken,
        tokenType: response.tokenType,
        onSuccess: function* () {
          yield put(loginSuccess())
        }
      })
      payload.onLogin()
    } else {
      yield put({
        type: types.SSO_DATA_SUCCESS,
        payload: response
      })
    }
  } catch (error) {
    yield put({
      type: types.SSO_DATA_FAILURE,
      error
    })
  }
}

export function* logoutSaga() {
  try {
    const response = yield call(logoutUserService)
    yield put({ type: types.LOGOUT_USER_ACCESS, response })
  } catch (error) {
    yield put({ type: types.LOGOUT_USER_ERROR, error })
  }
}

export function* updateTokenSaga({ impersonated }) {
  try {
    const { accessToken, expiresIn, tokenType } = yield call(
      updateTokenService,
      impersonated
    )
    if (impersonated) {
      const { type } = getOriginalUser()
      localStorage.setItem(
        'originalUsers',
        JSON.stringify([
          {
            type,
            token: `${tokenType} ${accessToken}`,
            expiresIn: calculateExpires(expiresIn)
          }
        ])
      )
    } else {
      storageSetToken(getTokenName(), tokenType, accessToken, expiresIn)
    }
    yield put({ type: types.UPDATE_USER_TOKEN_SUCCESS, impersonated })
  } catch (error) {}
}

export function* resetSaga(action) {
  try {
    const response = yield call(reset, action.payload)
    yield put({ type: types.RESET_PASSWORD_SUCCESS, response })
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_ERROR, error })
  }
}

export function* impersonateSaga({ payload }) {
  try {
    const { role } = yield select(({ user: { details } }) => details.response)
    const parsedRole = roles.parse(role)
    const usedService = parsedRole.system
      ? impersonateSystemUserService
      : impersonateUserService
    const response = yield call(usedService, payload)
    yield put({ type: types.IMPERSONATE_USER_SUCCESS, response })
  } catch (error) {
    yield put({ type: types.IMPERSONATE_USER_ERROR, error })
  }
}

export function* getImpersonateToken({ payload }) {
  try {
    const response = yield call(getImpersonateTokenService, payload)
    yield put({ type: types.GET_IMPERSONATE_TOKEN_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_IMPERSONATE_TOKEN_ERROR, payload: error })
  }
}
