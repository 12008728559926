import React, { memo, useCallback, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { _get } from 'utils/lodash'
import classNames from 'classnames'

import DeviceCardScheduleContent from './DeviceCardScheduleContent'
import Tooltip from 'components/Tooltip'
import Spacing from 'components/Containers/Spacing'
import DeviceCard from 'components/Card/DeviceCard'
import { TextWithTooltip } from 'components/Typography'
import MaterialPopup from 'components/Popup/MaterialPopup'
import useTimeout from 'hooks/useTimeout'
import { DARK } from 'theme'
import { deviceStatus } from '../../../../../../constants/deviceConstants'

const styles = ({ palette, type }) => {
  return {
    rowWrapper: {
      position: 'relative',
      padding: '6px 10px',
      height: 48,
      borderRadius: '4px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette[type].formControls.input.border,
      cursor: 'pointer',
      transition: 'all 0.3s ease-out',
      boxShadow: palette[type].scheduleDeviceCard.boxShadow,
      background: palette[type].scheduleDeviceCard.background,
      '&:hover': {
        borderColor: palette[type].scheduleDeviceCard.hover.borderColor,
        '& $hoverElement': {
          opacity: 1
        },
        '& $statusCircle': {
          backgroundColor: '#3B86F8'
        }
      }
    },
    activeDeviceTextWrapper: {
      width: 'calc(100% - 18px)'
    },
    hoverElement: {
      content: "''",
      position: 'absolute',
      inset: 0,
      borderRadius: '4px',
      background: palette[type].scheduleDeviceCard.hover.background,
      opacity: 0,
      transition: 'all 0.3s ease-out'
    },
    textWrapper: {
      height: '100%',
      width: 'calc(100% - 12px)'
    },
    cardWrapper: {
      width: 320
    },
    aliasText: {
      lineHeight: '17px',
      zIndex: 1,
      ...(type === DARK && { color: '#fff' })
    },
    titleText: {
      lineHeight: '15px',
      zIndex: 1,
      ...(type === DARK && { color: '#fff', opacity: 0.6 })
    },
    selected: {
      transition: 'all 0.3s ease-in-out',
      borderColor: palette[type].scheduleDeviceCard.selected.borderColor,
      background: palette[type].scheduleDeviceCard.selected.background,
      boxShadow: palette[type].scheduleDeviceCard.selected.boxShadow,
      '& $statusCircle': {
        backgroundColor: '#fff !important'
      }
    },
    disabled: {
      cursor: 'auto'
    },
    selectedAliasText: {
      color: '#fff',
      transition: 'all 0.3s ease-out'
    },
    selectedTitleText: {
      color: '#fff',
      opacity: 0.6,
      transition: 'all 0.3s ease-out'
    },
    statusCircle: {
      zIndex: 1,
      content: "''",
      width: 10,
      minWidth: 10,
      height: 10,
      background: palette[type].formControls.input.border,
      borderRadius: '50%',
      transition: 'all 0.3s ease-out',
      animation: '$deviceStatusPulse 5000ms infinite'
    },
    '@keyframes deviceStatusPulse': {
      '0%, 40%, 60%, 100%': {
        opacity: 1
      },
      '50%': {
        opacity: 0.25
      }
    }
  }
}

const ScheduleDeviceRowSmall = ({
  item,
  checkboxValue,
  disabled,
  disabledReason,
  onChange,
  classes
}) => {
  const [setPopupTimeout, clearPopupTimeout] = useTimeout()
  const [popupHidden, setPopupHidden] = useState(true)

  const handleChange = useCallback(() => {
    if (disabled) {
      return
    }
    onChange(item)
  }, [onChange, item, disabled])

  const handleDisplayPopup = useCallback(() => {
    setTimeout(() => {
      const popupContent = document.querySelector('.popup-content')
      const left = _get(popupContent, 'style.left', '0px')
      const leftValue = Number(left.replace('px', ''))
      const top = _get(popupContent, 'style.top', '0px')
      const topValue = Number(top.replace('px', ''))

      if (leftValue < 0 || leftValue > 40) {
        popupContent.style.left = '25px'
      }
      if (topValue < 0) {
        popupContent.style.top = '10px'
      }
    }, 5)
  }, [])

  const isActiveDevice = item.status === deviceStatus.active

  return (
    <Tooltip
      title={disabledReason || ''}
      disableHoverListener={!disabledReason}
      single
    >
      <div
        onMouseEnter={() => setPopupTimeout(() => setPopupHidden(false), 1000)}
        onMouseLeave={() => {
          clearPopupTimeout()
          setPopupHidden(true)
        }}
        onClick={handleChange}
      >
        <MaterialPopup
          style={popupHidden ? { opacity: 0, display: 'none' } : undefined}
          trigger={
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              className={classNames(classes.rowWrapper, {
                [classes.selected]: checkboxValue,
                [classes.disabled]: disabled
              })}
            >
              <Grid
                container
                item
                direction="column"
                wrap="nowrap"
                justifyContent={item.alias ? 'space-between' : 'center'}
                className={classNames({
                  [classes.activeDeviceTextWrapper]: isActiveDevice
                })}
              >
                <div
                  className={classNames(classes.hoverElement, {
                    hidden: checkboxValue
                  })}
                />
                <TextWithTooltip
                  maxWidth="100%"
                  color="title.primary"
                  weight="bold"
                  rootClassName={classNames(classes.aliasText, {
                    [classes.selectedAliasText]: checkboxValue
                  })}
                >
                  {item.alias}
                </TextWithTooltip>
                <TextWithTooltip
                  maxWidth="100%"
                  variant="smaller"
                  rootClassName={classNames(classes.titleText, {
                    [classes.selectedTitleText]: checkboxValue
                  })}
                >
                  {item.name}
                </TextWithTooltip>
              </Grid>
              {isActiveDevice && <div className={classes.statusCircle} />}
            </Grid>
          }
          onOpen={handleDisplayPopup}
        >
          <div className={classes.cardWrapper}>
            {!popupHidden && (
              <DeviceCard
                device={item}
                withFooter={false}
                infoIconsPosition="top"
                variant="auto"
                disableHoverEffect
                bottomContentComponent={
                  <Spacing variant={0} paddingHor={2} paddingTop={2}>
                    <DeviceCardScheduleContent device={item} />
                  </Spacing>
                }
              />
            )}
          </div>
        </MaterialPopup>
      </div>
    </Tooltip>
  )
}

export default withStyles(styles)(memo(ScheduleDeviceRowSmall))
