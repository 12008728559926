import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { clearLoginInfo, clearLogoutInfo } from 'actions/authenticationActions'
import { clearSettingsAction } from 'actions/settingsActions'
import { clearClientStore } from 'actions/new/client'
import { clearUserDetailsAction } from 'actions/userActions'
import { clearConfigReducer } from 'actions/configActions'
import socket from 'services/socket'
import { storageClear } from 'utils/localStorage'
import { clearWeatherStorage } from 'utils/weather'
import { LOGOUT_EVENT_NAME } from 'constants/api'
import { setTotalActiveInactiveDevices } from 'slices/appSlice'

export default function useLogout() {
  const dispatch = useDispatch()

  const handleLogout = useCallback(async () => {
    storageClear()

    document.dispatchEvent(new Event(LOGOUT_EVENT_NAME))

    await dispatch(clearLogoutInfo())
    await dispatch(clearLoginInfo())
    await dispatch(clearSettingsAction())
    await dispatch(clearClientStore())
    await dispatch(clearUserDetailsAction())
    await dispatch(clearConfigReducer())
    await clearWeatherStorage()
    await dispatch(setTotalActiveInactiveDevices())
    socket.disconnect()
  }, [dispatch])

  return handleLogout
}
