import React, { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import FormControlChips from 'components/Form/FormControlChips'

const FormControlReactSelect = forwardRef(
  (
    {
      onChange,
      value,
      labelPosition = 'top',
      label,
      formControlContainerClass,
      formControlLabelClass,
      error,
      touched,
      marginBottom,
      isSearchable = false,
      isMulti = false,
      isSort = true,
      ...props
    },
    ref
  ) => {
    return (
      <FormControlChips
        propsRef={ref}
        isMulti={isMulti}
        isSearchable={isSearchable}
        handleChange={onChange}
        values={value}
        error={error}
        isSort={isSort}
        touched={touched}
        formControlContainerClass={formControlContainerClass}
        formControlLabelClass={formControlLabelClass}
        marginBottom={marginBottom}
        labelPosition={labelPosition}
        label={label}
        {...props}
      />
    )
  }
)

FormControlReactSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool
  ]),
  labelPosition: PropTypes.string,
  label: PropTypes.string,
  isSort: PropTypes.bool,
  formControlContainerClass: PropTypes.string,
  formControlLabelClass: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.array
}

export default memo(FormControlReactSelect)
