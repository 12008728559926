import React, { useMemo } from 'react'
import { Grid, Link, withStyles } from '@material-ui/core'
import classesNames from 'classnames'
import { withTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'

import FooterLink from './FooterLink'
import useWhiteLabel from 'hooks/useWhiteLabel'

function styles({ typography, palette, type }) {
  return {
    footerGrid: {
      marginBottom: '35px',
      borderTop: `1px solid ${palette[type].pages.singIn.border}`,
      paddingTop: '15px'
    },
    dense: {
      marginBottom: '5px'
    },
    footerText: {
      color: palette[type].header.account.color,
      textAlign: 'center',
      fontFamily: typography.fontFamily,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
      margin: '0 auto'
    },
    textContainer: {
      '& > p': {
        display: 'inline',
        margin: '0 10px'
      }
    }
  }
}

const labels = [
  'Privacy Policy footer link',
  'Term and Conditions footer link',
  'About Mvix footer link'
]

function Footer({ t, classes, dense }) {
  const { privacyPolicy, termsCondition, aboutPage, support } = useWhiteLabel()

  const renderFooterLinks = useMemo(
    () =>
      [privacyPolicy, termsCondition, aboutPage].map(
        ({ link, preferred, text, fallbackLink }, index) =>
          preferred === 'text' ? ( // nosemgrep
            <span
              className={classes.textContainer}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
            />
          ) : (
            <FooterLink
              key={index}
              href={link || fallbackLink}
              label={t(labels[index])}
            />
          )
      ),
    [aboutPage, privacyPolicy, t, termsCondition, classes.textContainer]
  )

  return (
    <footer>
      <Grid
        container
        justifyContent="center"
        className={classesNames(classes.footerGrid, {
          [classes.dense]: dense
        })}
      >
        {renderFooterLinks}
      </Grid>
      <Grid className={classes.footerText}>
        {t(
          'Questions on how to use the software or need personalized training?'
        )}
        <div>
          {t('Please visit our ')}
          <Link href={support.uri} target="_blank">
            {t('client success portal')}
          </Link>
          {t('or email us at')}
          <FooterLink
            href={`mailto:${support.email}`}
            label={support.email}
            inline
            target="_self"
          />
        </div>
      </Grid>
    </footer>
  )
}

export default withTranslation('translations')(withStyles(styles)(Footer))
