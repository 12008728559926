import * as types from './index'

export const loginOktaAction = (data, role, onSuccess = f => f) => ({
  type: types.LOGIN_USER_OKTA,
  data,
  role,
  onSuccess
})

export const getSSOData = ({ data, onLogin }) => ({
  type: types.GET_SSO_DATA,
  data,
  onLogin
})

export const clearSSOData = () => ({
  type: types.CLEAR_SSO_DATA
})

export const clearLoginInfo = () => ({ type: types.CLEAR_LOGIN_INFO })

export const logoutUserAction = () => ({ type: types.LOGOUT_USER })

export const clearLogoutInfo = () => ({ type: types.CLEAR_LOGOUT_INFO })

export const updateTokenAction = impersonated => ({
  type: types.UPDATE_USER_TOKEN,
  impersonated
})

export const resetAction = data => ({
  type: types.RESET_PASSWORD,
  payload: data
})

export const impersonateUserAction = data => ({
  type: types.IMPERSONATE_USER,
  payload: data
})

export const getImpersonateToken = id => ({
  type: types.GET_IMPERSONATE_TOKEN,
  payload: id
})

export const clearImpersonateToken = id => ({
  type: types.CLEAR_IMPERSONATE_TOKEN,
  payload: id
})
