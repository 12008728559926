import { createAppApi } from 'services/api'
import { ENTERPRISE_ROLE, ORG_ROLE } from 'constants/api'
import apiTags from 'constants/apiTags'

export const configApi = createAppApi('configQuery', {
  tagTypes: [apiTags.role],
  endpoints: builder => ({
    getClientFeatures: builder.query({
      query: params => ({ url: '/config/feature/client', params })
    }),
    getDeviceTypes: builder.query({
      providesTags: [apiTags.deviceType],
      query: ({ roleLevel, ...params }) => ({
        url: [ORG_ROLE, ENTERPRISE_ROLE].includes(roleLevel)
          ? '/config/device/type'
          : '/deviceType',
        params
      })
    }),
    getRoles: builder.query({
      query: ({ level }) => ({ url: `/config/${level}/role` })
    }),
    getRolePermission: builder.query({
      providesTags: [apiTags.role],
      query: roleId => ({ url: `/role/${roleId}/permission` })
    }),
    getRole: builder.query({
      providesTags: [apiTags.role],
      query: id => ({
        url: `/role/${id}`
      })
    }),
    getTheme: builder.query({
      query: ({ featureId }) => ({
        url: `/config/mediaFeature/${featureId}/theme`
      })
    }),
    getContentSource: builder.query({
      query: ({ featureId }) => ({
        url: `/config/mediaFeature/${featureId}/contentSource`
      })
    }),
    getWorkplacePoster: builder.query({
      query: params => ({ url: '/config/workplacePoster', params }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems) => {
        currentCache.meta = newItems.meta
        if (newItems.meta.currentPage > 1) {
          currentCache.data.push(...newItems.data)
        } else {
          currentCache.data = newItems.data
        }
      }
    }),
    getWorkplacePosterTagsConfig: builder.query({
      query: params => ({ url: '/config/workplacePosterTag', params })
    })
  })
})

export const {
  useLazyGetClientFeaturesQuery,
  useGetClientFeaturesQuery,
  useLazyGetDeviceTypesQuery,
  useGetDeviceTypesQuery,
  useGetRolesQuery,
  useGetThemeQuery,
  useGetContentSourceQuery,
  useLazyGetWorkplacePosterQuery,
  useLazyGetWorkplacePosterTagsConfigQuery,
  useGetRoleQuery,
  useGetRolePermissionQuery,
  util
} = configApi
