import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { withStyles, Grid, Typography, Dialog } from '@material-ui/core'
import classNames from 'classnames'

import Drawer from './Drawer'
import Navigation from '../Navigation'
import AccountInfo from '../Account/AccountInfo'
import { AccountNavigation } from '../Account'
import LanguageSelector from './LanguageSelector'
import {
  HeaderAccountInfoLoader,
  HeaderAccountNavigationLoader,
  HeaderNavigationLoader,
  HeaderLogoLoader
} from '../Loaders'
import { navigationStateSelector } from 'selectors/appSelectors'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import useWhiteLabel from 'hooks/useWhiteLabel'
import useUserDetails from 'hooks/useUserDetails'
import { RESELLER_ROLE, SYSTEM_ROLE } from 'constants/api'
import Profile from '../Pages/Profile'
import { getOriginalUser } from 'utils/localStorage'
import { zIndexes } from 'constants/stylesConstants'
import UpgradeForm from './UpgradeForm'
import { SIGNATURE_CARE } from 'constants/clientsConstants'
import { LIGHT } from 'theme'

const styles = theme => {
  const { palette, type, colors } = theme
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      height: 80,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: palette[type].header.borderColor,
      boxShadow: `0 2px 4px 0 ${palette[type].header.shadow.s}, 0 1px 0 0 ${palette[type].header.shadow.f}`,
      backgroundColor: palette[type].default,
      position: 'fixed',
      zIndex: 111,
      width: '100%',
      top: 0
    },
    hovered: {
      zIndex: zIndexes.confirmation + 1
    },
    contentContainer: {
      maxWidth: 1600,
      margin: '0 auto',
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto'
    },
    leftPart: {
      padding: '.5rem 0'
    },
    logoImage: {
      width: 'auto',
      maxWidth: 104,
      height: '51px',
      marginTop: '1px'
    },
    navigationWrap: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center'
    },
    accountNavigationWrap: {
      display: 'flex',
      alignItems: 'center',
      padding: '.5rem 0'
    },
    hasTopBanner: {
      top: 35
    },
    bannerHasTwoMessages: {
      top: 80
    },
    dialogRoot: {
      borderRadius: '16px',
      zIndex: '1402 !important'
    },
    appointmentDialog: {
      borderRadius: '16px',
      backgroundColor: 'transparent',
      maxWidth: 1100
    },

    upgradeText: {
      color: theme.colors.other.color3[theme.type],
      fontSize: '14px'
    },
    imgWrapper: { paddingRight: '7px' },
    upgradeIconAnimation: { paddingTop: '3px' },
    upgradeWrapper: {
      display: 'flex',
      gap: '10px',
      padding: ' 12px 12px  12px 12px',
      alignItems: 'center',
      cursor: 'pointer',
      marginLeft: '5px',
      transitionDuration: '0.2s',
      '&:hover': {
        animation: 'pulse 3s linear infinite'
      }
    },

    upgradeDiv: {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 'auto',
        bottom: 'auto',
        left: '0',
        right: '0',
        width: '1px',
        height: '56px',
        backgroundColor:
          type === LIGHT ? colors.border.light : colors.border.dark
      }
    }
  }
}

const Header = ({
  t,
  classes,
  details = {},
  theme,
  hasTopBanner,
  bannerHasTwoMessages,
  ...props
}) => {
  const disabled = useSelector(navigationStateSelector)

  const {
    client: { sso },
    serviceLevel
  } = useUserDetails()
  const [userPic, setUserPic] = useState('')
  const [name, setName] = useState({ first: '', last: '' })
  const [upgradeForm, setUpgradeDialog] = useState(false)
  const [passwordReminder, setPasswordReminder] = useState({})
  const [loading, setLoading] = useState(true)
  const [languageSelectorIsOpen, setLanguageSelectorIsOpen] = useState(false)
  const [profileSelectorIsOpen, setProfileSelectorIsOpen] = useState(false)
  const [hovered, setHovered] = useState(false)
  const role = useUserRole()
  const { darkHeaderLogo, headerLogo } = useWhiteLabel()

  const isLightLogo = theme.type === 'dark' && darkHeaderLogo
  const handleNavigationHover = useCallback(() => {
    if (!hovered) {
      setHovered(true)
    }
  }, [hovered])

  const handleNavigationLeave = useCallback(() => {
    setHovered(false)
  }, [])

  useEffect(() => {
    if (details.response) {
      const {
        firstName,
        lastName,
        profile,
        passwordReminder
      } = details.response
      setName({ first: firstName, last: lastName })
      setUserPic(profile)
      setPasswordReminder(passwordReminder)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [details])

  useEffect(() => {
    if (Object.keys(passwordReminder).length && !getOriginalUser()) {
      if (role.role !== SYSTEM_ROLE) {
        const { forcePasswordChange } = passwordReminder

        if (forcePasswordChange) {
          setProfileSelectorIsOpen(true)
        }
      }
    }
    // eslint-disable-next-line
  }, [passwordReminder])

  const userProfile = useMemo(
    () => ({
      userName: `${name.first} ${name.last}`,
      userPic: userPic
    }),
    [name, userPic]
  )

  return (
    <>
      <header
        onMouseOver={handleNavigationHover}
        onMouseLeave={handleNavigationLeave}
        className={classNames(classes.root, 'top-header', {
          [classes.hovered]: hovered,
          [classes.hasTopBanner]: hasTopBanner,
          [classes.bannerHasTwoMessages]: bannerHasTwoMessages
        })}
      >
        <Grid container direction="row" className={classes.contentContainer}>
          <Grid item className={classes.leftPart}>
            <Grid container alignItems="center" direction="row">
              {loading ? (
                <HeaderLogoLoader />
              ) : (
                <>
                  <Grid item>
                    <Drawer
                      color={theme.colors.highlight}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item>
                    <RouterLink
                      to={
                        !disabled && role.role !== RESELLER_ROLE
                          ? `/${role.role}/dashboard`
                          : '#'
                      }
                    >
                      <div className={classes.imgWrapper}>
                        <img
                          className={classes.logoImage}
                          src={isLightLogo ? darkHeaderLogo : headerLogo}
                          alt="Logo"
                        />
                      </div>
                    </RouterLink>
                  </Grid>
                  {serviceLevel !== SIGNATURE_CARE && !role.system && (
                    <Grid item>
                      <div className={classes.upgradeDiv}>
                        <div
                          className={classes.upgradeWrapper}
                          onClick={() => {
                            setUpgradeDialog(true)
                          }}
                        >
                          <span className={classes.upgradeIconAnimation}>
                            <i
                              className="animation-icon  fa-solid fa-sparkles"
                              style={{
                                color: theme.colors.other.color3[theme.type],
                                fontSize: '21px'
                              }}
                            />
                          </span>
                          <Typography className={classes.upgradeText}>
                            {t('Upgrade')}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <Grid item className={classes.navigationWrap}>
            {loading ? (
              <HeaderNavigationLoader />
            ) : (
              <Navigation disabled={disabled} />
            )}
          </Grid>

          <Grid item className={classes.accountNavigationWrap}>
            {loading ? (
              <HeaderAccountNavigationLoader />
            ) : (
              <AccountNavigation />
            )}

            {loading ? (
              <HeaderAccountInfoLoader />
            ) : (
              <AccountInfo
                userProfile={userProfile}
                dark={props.dark}
                currentTheme={props.currentTheme}
                handleThemeChange={props.handleThemeChange}
                openLanguageSelector={setLanguageSelectorIsOpen}
                openProfileSelector={setProfileSelectorIsOpen}
                ssoLogoutUrl={sso?.ssoSetting?.singleLogoutService}
                color={theme.colors.other.color6[theme.type]}
                navigationDisabled={disabled}
              />
            )}
          </Grid>
        </Grid>
        {languageSelectorIsOpen && (
          <LanguageSelector
            setLanguageSelectorIsOpen={setLanguageSelectorIsOpen}
            route={props.location.pathname}
          />
        )}
      </header>

      <Dialog
        open={upgradeForm}
        classes={{
          root: classes.dialogRoot,
          paper: classes.appointmentDialog
        }}
        maxWidth="lg"
        onClose={() => {
          setUpgradeDialog(false)
        }}
        fullWidth
      >
        <UpgradeForm
          closeModal={() => {
            setUpgradeDialog(false)
          }}
        />
      </Dialog>

      {profileSelectorIsOpen && (
        <Profile
          handleThemeChange={props.handleThemeChange}
          openLanguageSelector={setLanguageSelectorIsOpen}
          setProfileSelectorIsOpen={setProfileSelectorIsOpen}
          currentTheme={props.currentTheme}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ user }) => ({
  details: user.details
})

export default withTranslation('translations')(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps)(withRouter(Header))
  )
)
