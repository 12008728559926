import { useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import Footer from 'components/Filter/Footer'
import Spacing from 'components/Containers/Spacing'
import { useUserRole } from 'hooks/tableLibrary'
import useUserDetails from 'hooks/useUserDetails'

const TableLibraryFilter = ({
  filterValues,
  filterConfig = [],
  onFilterSubmit,
  onFilterReset,
  close = f => f
}) => {
  const { t } = useTranslation()

  const role = useUserRole()

  const userDetails = useUserDetails()
  const form = useFormik({
    initialValues: filterValues || {},
    onSubmit: values => {
      onFilterSubmit(values)
      close()
    }
  })

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues
  } = form

  const handleFilterReset = useCallback(() => {
    onFilterReset()
    close()
  }, [onFilterReset, close])

  useEffect(() => {
    setValues(filterValues)
    // eslint-disable-next-line
  }, [filterValues])

  return (
    <Spacing variant={0} paddingHor={2} paddingVert={3}>
      <Spacing variant={2}>
        {filterConfig
          .filter(
            config =>
              !config.hasOwnProperty('forRoles') ||
              config.forRoles.includes(role.role)
          )
          .map(
            ({
              component: Component,
              name,
              label,
              getValue,
              getError,
              getTouched,
              getProps,
              ...props
            }) => (
              <Component
                key={name}
                name={name}
                label={t(label)}
                value={getValue ? getValue(values) : values[name]}
                error={getError ? getError(values) : errors[name]}
                touched={getTouched ? getTouched(values) : touched[name]}
                onChange={handleChange}
                onBlur={handleBlur}
                marginBottom={16}
                fullWidth
                {...props}
                {...(getProps && getProps({ form, role, userDetails, t }))}
              />
            )
          )}
      </Spacing>
      <Footer onSubmit={handleSubmit} onReset={handleFilterReset} />
    </Spacing>
  )
}

export default TableLibraryFilter
