import { useCallback, useEffect, useMemo } from 'react'

import useGetAllQuery from 'hooks/useGetAllQuery'
import { useLazyGetClientPackagesQuery } from 'api/clientPackageApi'

const useClientPackagesOptions = () => {
  const { items, getAll, loading } = useGetAllQuery({
    useLazyQuery: useLazyGetClientPackagesQuery,
    perRequest: 50
  })

  const transformClientPackage = useMemo(
    () =>
      (items || []).map(({ id: value, title: label }) => ({
        value,
        label
      })),
    [items]
  )

  const getFeatureIds = useCallback(
    findId => {
      const clientPackage = items?.find(
        ({ id }) => id === Number.parseInt(findId)
      )

      return clientPackage ? clientPackage.feature.map(({ id }) => id) : []
    },
    [items]
  )

  const getFeatureSettings = useCallback(
    findId => {
      const clientPackage = items?.find(
        ({ id }) => id === Number.parseInt(findId)
      )
      return clientPackage ? clientPackage.featureSetting : []
    },
    [items]
  )

  useEffect(() => {
    getAll()
    // eslint-disable-next-line
  }, [])

  return {
    values: transformClientPackage,
    getFeatureIds,
    getFeatureSettings,
    loading
  }
}

export default useClientPackagesOptions
