import React, { useEffect, useMemo, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import { compose } from '@reduxjs/toolkit'
import { _get } from 'utils/lodash'
import { useParams } from 'react-router'

import {
  FormControlReactSelect,
  FormControlAutocomplete
} from 'components/Form'
import Footer from 'components/Filter/Footer'
import FormControlSelectDevice from 'components/Form/FormControlSelectDevice'
import { deviceLibraryInitialFilter } from 'reducers/filters'
import {
  transformDataToOptions,
  translateOptions
} from 'utils/transformToOptionsUtils'
import { deviceNocView } from 'constants/deviceNoc'
import * as deviceNocService from 'services/public/deviceNoc'
import { featureNames } from 'constants/featureConstants'
import { useLazyDeviceNocQuery } from 'api/deviceApi'
import { sortOptions } from 'constants/libraryConstants/nocLibraryConstants'
import { initialDeviceStatusOptions } from 'constants/deviceConstants'

const styles = () => ({
  root: {
    padding: '25px 17px',
    width: 315
  },
  searchAction: {
    width: '90%'
  }
})

const DevicePublicFilter = ({
  t,
  classes,
  initialValues = {},
  onSubmit,
  onReset,
  view,
  close
}) => {
  const { token } = useParams()
  const translations = useMemo(
    () => ({
      deviceName: t('Device search name'),
      deviceType: t('Device Type'),
      locationCity: t('Device search location city'),
      status: t('Device search status'),
      sort: t('Sort Device')
    }),
    [t]
  )

  const form = useFormik({
    initialValues,
    onSubmit: values => {
      const updateValues = { ...deviceLibraryInitialFilter, ...values }

      onSubmit(updateValues)
      close()
    },
    onReset: () => {
      onReset()
      close()
    }
  })

  useEffect(() => {
    form.setValues(initialValues)
    // eslint-disable-next-line
  }, [initialValues])

  const fetcher = useCallback(
    params => {
      switch (view) {
        case deviceNocView.OVERVIEW:
        case deviceNocView.PREVIEW:
          return deviceNocService.getPublicNocGeneralItems({
            ...params,
            token,
            feature_name: featureNames.DeviceNoc
          })
        case deviceNocView.HEALTH:
          return deviceNocService.getPublicNocHealthItems({
            ...params,
            token,
            feature_name: featureNames.DeviceNoc
          })
        case deviceNocView.DIAGRAM:
          return deviceNocService.getPublicNocNetworkItems({
            ...params,
            token,
            feature_name: featureNames.DeviceNoc
          })
        case deviceNocView.UPTIME:
          return deviceNocService.getPublicNocNetworkItems({
            ...params,
            token,
            feature_name: featureNames.DeviceNoc
          })
        default:
          return deviceNocService.getPublicNocGeneralItems({
            ...params,
            token,
            feature_name: featureNames.DeviceNoc
          })
      }
    },
    [view, token]
  )

  const getDeviceTypeOption = useCallback(
    async (value, limit) =>
      await fetcher({
        fields: 'deviceType,deviceTypeId',
        name: value || undefined,
        sort: 'deviceType',
        order: 'asc',
        limit,
        distinct: true
      }).then(response => {
        const values = []
        _get(response, 'data', response).map(
          item =>
            _get(item, 'deviceType.name') &&
            values.push(_get(item, 'deviceType.name'))
        )
        return values.map(value => ({
          value,
          label: value
        }))
      }),
    [fetcher]
  )

  const getDeviceLocation = useCallback(
    async (value, limit) =>
      await fetcher({
        fields: 'city,state',
        location: value,
        sort: 'city',
        order: 'asc',
        distinct: true,
        limit
      }).then(response => {
        const data = _get(response, 'data', [])
        return transformDataToOptions('city', ['city', 'state'])(data)
      }),
    [fetcher]
  )

  return (
    <div className={classes.root}>
      <FormControlSelectDevice
        name="name"
        label={translations.deviceName}
        value={form.values.name}
        onChange={form.handleChange}
        marginBottom={16}
        useLazyQuery={useLazyDeviceNocQuery}
        isClearable
        hasCopy
        requestParams={{ token, feature_name: featureNames.DeviceNoc }}
      />
      <FormControlAutocomplete
        fullWidth
        isClearable
        name="deviceType"
        label={translations.deviceType}
        values={form.values.deviceType}
        getOptions={getDeviceTypeOption}
        handleChange={form.handleChange}
        hasDynamicChipsCreation={false}
        isCreatable
      />
      <FormControlAutocomplete
        fullWidth
        name="location"
        label={translations.locationCity}
        values={form.values.location}
        handleChange={form.handleChange}
        getOptions={getDeviceLocation}
        isClearable
        hasDynamicChipsCreation={false}
        isMulti
      />
      <FormControlReactSelect
        fullWidth
        label={translations.status}
        options={translateOptions(initialDeviceStatusOptions, t)}
        name="status"
        value={form.values.status}
        handleChange={form.handleChange}
        isClearable
        isMulti
        marginBottom={16}
      />
      <FormControlReactSelect
        fullWidth
        label={translations.sort}
        options={translateOptions(sortOptions, t)}
        name="sort"
        value={form.values.sort}
        handleChange={form.handleChange}
        isClearable
        marginBottom={16}
      />

      <Footer onSubmit={form.handleSubmit} onReset={form.handleReset} />
    </div>
  )
}

DevicePublicFilter.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  memo
)(DevicePublicFilter)
