import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'

export const clientPackageApi = createAppApi('clientsPackageQuery', {
  tagTypes: [apiTags.clientPackage],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      name: 'ClientPackage',
      tags: [apiTags.clientPackage],
      url: '/clientPackage'
    })
  })
})

export const {
  useLazyGetClientPackagesQuery,
  useGetClientPackagesQuery,
  useDeleteClientPackageMutation
} = clientPackageApi
