import update from 'immutability-helper'
import { Trans } from 'react-i18next'
import moment from 'moment/moment'

import * as types from 'actions'
import entityConstants from 'constants/entityConstants'
import featureConstants from 'constants/featureConstants'
import { HTML_CONTENT, approveStatuses } from 'constants/library'
import {
  BACKEND_DATE_FORMAT,
  MEDIA_DATE_TIME_S_FORMAT
} from 'constants/dateTimeFormats'
import { initialDeviceStatusOptions } from 'constants/deviceConstants'

export const mediaContentValues = {
  name: '',
  categoryId: null,
  clientName: ''
}

export const videoTutorialValuesFilter = {
  name: '',
  categoryId: null
}

export const deviceLibraryInitialFilter = {
  name: null,
  location: '',
  clientName: '',
  clientId: '',
  status: initialDeviceStatusOptions,
  macAddress: '',
  group: [],
  deviceType: '',
  deviceTypeId: [],
  id: '',
  tag: [],
  remoteSupportId: '',
  serialNumber: '',
  soNumber: '',
  firmware: '',
  updateAvailable: '',
  mediaFeature: [],
  title: '',
  sort: '',
  serviceLevel: ''
}

export const clientUserLibraryInitialFilter = {
  email: '',
  firstName: '',
  lastName: '',
  tag: [],
  roleId: null,
  status: 'Active',
  clientId: '',
  clientName: ''
}

export const scheduleLibraryInitialFilter = {
  title: '',
  group: [],
  device: '',
  scheduleType: [],
  status: '',
  tag: [],
  mediaFeature: [],
  'updatedAt-from': null,
  'updatedAt-to': null,
  isSmartSchedule: false
}

export const templateLibraryInitialFilter = {
  title: '',
  group: [],
  duration: '',
  size: '',
  tag: [],
  mediaFeatureNames: [],
  'updatedAt-from': null,
  'updatedAt-to': null,
  status: [],
  templateType: []
}

export const templateBackgroundInitialFilter = {
  name: '',
  tooltip: '',
  type: '',
  status: ''
}

export const workplacePosterInitialFilter = {
  tag: [],
  title: '',
  orientation: '',
  contentType: '',
  status: ''
}

export const playlistLibraryInitialFilter = {
  title: '',
  group: [],
  size: '',
  tag: [],
  status: [],
  mediaFeatureNames: [],
  playlistType: []
}

export const reportsLibraryInitialFilter = {
  title: '',
  table: [],
  status: ''
}

export const packageLibraryInitialFilter = {
  title: ''
}

export const appVersionsLibraryInitialFilter = {
  deviceType: '',
  version: '',
  type: '',
  creatorIp: '',
  tag: [],
  releaseNotes: '',
  status: 'Active'
}

export const brightSignOSVersionsLibraryInitialFilter = {
  version: '',
  creatorIp: '',
  releaseNotes: '',
  status: 'Active'
}

export const clientLibraryInitialFilter = {
  featurePackageTitle: '',
  clientName: '',
  clientId: '',
  id: '',
  group: [],
  status: 'Active',
  tag: [],
  clientTypeId: '',
  migrationApproved: '',
  serviceLevel: ''
}

export const associatedClientFilter = {
  ...Object.entries(clientLibraryInitialFilter).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value) ? [] : ''
    }),
    {}
  )
}

export const deviceTypesLibraryInitialFilter = {
  name: '',
  alias: '',
  status: ''
}
export const deviceModelsLibraryInitialFilter = {
  name: ''
}

export const clientLicenseLibraryInitialFilter = {
  name: ''
}

export const LicensesLibraryInitialFilter = {
  name: '',
  clientId: '',
  invoiceNumber: '',
  invoiceDate: '',
  licenseExpiration: '',
  deviceId: '',
  trlTag: '',
  isTransferred: '',
  tag: ''
}

export const tagLibraryInitialFilter = {
  tag: '',
  type: ''
}

export const menuItemLibraryInitialFilter = {
  name: '',
  isPriceEnabled: false,
  'price-from': '0',
  'price-to': '50',
  allergens: [],
  soldOut: '',
  category: '',
  tag: [],
  status: ''
}

export const usersLibraryInitialFilter = {
  clientId: '',
  lastName: '',
  firstName: '',
  email: '',
  roleId: '',
  clientName: '',
  group: [],
  tag: [],
  status: [{ value: 'Active', label: 'Active' }]
}

export const mediaLibraryInitialFilter = {
  title: '',
  featureId: [],
  group: [],
  tag: [],
  status: [
    { label: <Trans i18nKey={`Active`} />, value: 'Active' },
    { label: <Trans i18nKey={`Inactive`} />, value: 'Inactive' }
  ],
  approvedStatus: [
    { value: approveStatuses.approve, label: <Trans i18nKey={`Approved`} /> },
    {
      value: approveStatuses.waitingForApproval,
      label: <Trans i18nKey={`Awaiting Approval`} />
    }
  ],
  isExpired: '0'
}

export const roomLibraryInitialFilter = {
  title: '',
  onlyAvailable: 0
}

export const reportsApiUsageInitialFilter = {
  client_id: [],
  featureName: [],
  start: moment().subtract(1, 'years').format(BACKEND_DATE_FORMAT),
  stop: moment().format(BACKEND_DATE_FORMAT)
}

export const accountLoggingInitialFilter = {
  'createdAt-from': moment()
    .subtract(1, 'year')
    .startOf('day')
    .format(MEDIA_DATE_TIME_S_FORMAT),
  'createdAt-to': moment().endOf('day').format(MEDIA_DATE_TIME_S_FORMAT),
  componentType: '',
  title: '',
  action: '',
  userFirstName: '',
  userLastName: ''
}

export const initialState = {
  [entityConstants.PlaylistLibrary]: playlistLibraryInitialFilter,
  [entityConstants.DeviceLibrary]: deviceLibraryInitialFilter,
  [entityConstants.DeviceLibrary +
  entityConstants.ScheduleLibrary]: deviceLibraryInitialFilter,
  [entityConstants.VideoTutorials]: videoTutorialValuesFilter,
  [entityConstants.DeviceTypesLibrary]: deviceTypesLibraryInitialFilter,
  [entityConstants.AppVersionsLibrary]: appVersionsLibraryInitialFilter,
  [entityConstants.BrightSignOSVersionsLibrary]: brightSignOSVersionsLibraryInitialFilter,
  [entityConstants.MediaLibrary]: mediaLibraryInitialFilter,
  [entityConstants.UserLibrary]: usersLibraryInitialFilter,
  [featureConstants.RSSFeed]: mediaContentValues,
  [featureConstants.Feeds]: mediaContentValues,
  [featureConstants.News]: mediaContentValues,
  [featureConstants.Twitch]: mediaContentValues,
  [featureConstants[HTML_CONTENT]]: mediaContentValues,
  [featureConstants.YouTube]: mediaContentValues,
  [featureConstants.Radio]: mediaContentValues,
  [featureConstants.MediaRSS]: mediaContentValues,
  [featureConstants.CustomWidget]: mediaContentValues,
  [featureConstants.CustomWidget]: mediaContentValues,
  workplacePoster: workplacePosterInitialFilter,
  [entityConstants.ClientUserLibrary]: clientUserLibraryInitialFilter,
  [entityConstants.TemplateLibrary]: templateLibraryInitialFilter,
  [entityConstants.TemplateBackgroundLibrary]: templateBackgroundInitialFilter,
  [entityConstants.ScheduleLibrary]: scheduleLibraryInitialFilter,
  [entityConstants.ScheduleLibraryTimeline]: scheduleLibraryInitialFilter,
  [entityConstants.ClientLibrary]: clientLibraryInitialFilter,
  [entityConstants.ReportLibrary]: reportsLibraryInitialFilter,
  storedOptions: {},
  [entityConstants.NotificationsLibrary]: {
    name: ''
  },
  [entityConstants.ClientLicenseLibrary]: clientLicenseLibraryInitialFilter,
  [entityConstants.LicenseLibrary]: LicensesLibraryInitialFilter,
  [entityConstants.TagLibrary]: tagLibraryInitialFilter,
  [entityConstants.RoomLibrary]: roomLibraryInitialFilter,
  associatedClients: associatedClientFilter,
  [entityConstants.DeviceNocLibrary]: deviceLibraryInitialFilter,
  [entityConstants.MenuItemLibrary]: menuItemLibraryInitialFilter,
  [entityConstants.DeviceModelsLibrary]: deviceModelsLibraryInitialFilter,
  [entityConstants.ReportsApiUsage]: reportsApiUsageInitialFilter,
  [entityConstants.AccountLogging]: accountLoggingInitialFilter
}

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_FILTERS_BY_ENTITY:
      return update(state, {
        [action.payload.entity]: { $set: action.payload.data }
      })
    case types.RESET_FILTERS_BY_ENTITY:
      return update(state, {
        [action.payload.entity]: { $set: initialState[action.payload.entity] }
      })
    case types.SET_STORED_OPTIONS:
      return update(state, {
        storedOptions: { $merge: action.payload }
      })
    case types.RESET_ALL_FILTERS: {
      return update(state, {
        $set: initialState
      })
    }
    default:
      return state
  }
}

export default filtersReducer
