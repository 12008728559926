import update from 'immutability-helper'

import * as types from '../actions'
import {
  postInitialState,
  putInitialState
} from 'constants/initialLibraryState'

const initialState = {
  details: {
    loading: false
  },
  originalUser: {},
  put: {
    ...putInitialState,
    loading: false
  },
  putRegionalSetting: {
    ...putInitialState,
    loading: false
  },
  userIp: {
    response: {
      ip: null
    },
    isPending: false,
    status: 'pending'
  },
  profile: {},
  postBulk: postInitialState
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_DETAILS: {
      return update(state, {
        details: {
          loading: { $set: true }
        }
      })
    }
    case types.GET_USER_DETAILS_SUCCESS:
      return update(state, {
        details: {
          response: { $set: action.payload },
          loading: { $set: false }
        }
      })
    case types.GET_USER_DETAILS_ERROR:
      return update(state, {
        details: {
          error: { $set: action.payload },
          loading: { $set: false }
        }
      })
    case types.GET_ORIGINAL_USER_DETAILS_SUCCESS:
      return update(state, {
        originalUser: {
          response: { $set: action.payload }
        }
      })
    case types.GET_ORIGINAL_USER_DETAILS_ERROR:
      return update(state, {
        originalUser: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_USER_DETAILS: {
      return update(state, {
        put: {
          loading: { $set: true }
        }
      })
    }
    case types.PUT_USER_DETAILS_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload },
          loading: { $set: false }
        }
      })
    case types.PUT_USER_DETAILS_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload },
          loading: { $set: false }
        }
      })
    case types.USER_PUT_CLEAR:
      return update(state, {
        put: { $set: {} }
      })
    case types.USER_DETAILS_CLEAR:
      return update(state, {
        details: { $set: {} }
      })
    case types.GET_USER_IP: {
      return update(state, {
        userIp: {
          isPending: { $set: true },
          status: { $set: 'pending' }
        }
      })
    }
    case types.GET_USER_IP_SUCCESS:
      return update(state, {
        userIp: {
          $set: {
            response: action.payload,
            isPending: false,
            status: 'fulfilled'
          }
        }
      })
    case types.GET_USER_IP_ERROR:
      return update(state, {
        userIp: {
          isPending: { $set: false },
          status: { $set: 'fulfilled' }
        }
      })
    case types.GET_USER_PROFILE_SUCCESS:
      return update(state, {
        profile: {
          response: { $set: action.payload }
        }
      })
    case types.GET_USER_PROFILE_ERROR:
      return update(state, {
        profile: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_REGIONAL_SETTINGS: {
      return update(state, {
        putRegionalSetting: {
          loading: { $set: true }
        }
      })
    }
    case types.PUT_REGIONAL_SETTINGS_SUCCESS: {
      return update(state, {
        putRegionalSetting: {
          response: { $set: action.payload },
          loading: { $set: false }
        }
      })
    }
    case types.PUT_REGIONAL_SETTINGS_ERROR: {
      return update(state, {
        putRegionalSetting: {
          error: { $set: action.payload },
          loading: { $set: false }
        }
      })
    }

    case types.CLEAR_PUT_REGIONAL_SETTINGS: {
      return update(state, {
        putRegionalSetting: { $set: {} }
      })
    }
    case types.POST_USER_BULK_SUCCESS:
      return update(state, {
        postBulk: {
          response: { $set: action.payload }
        }
      })
    case types.POST_USER_BULK_ERROR:
      return update(state, {
        postBulk: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_USER_BULK_INFO:
      return update(state, {
        postBulk: {
          $set: postInitialState
        }
      })
    default:
      return state
  }
}

export default userReducer
