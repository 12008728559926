import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { TableLibraryCell, TableLibraryRow } from 'components/TableLibrary'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { checkData } from 'utils/tableUtils'
import { scheduleConstants } from 'constants/index'
import { TextWithTooltip } from 'components/Typography'
import DeviceTypeIconCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceTypeIconCell'
import classNames from 'classnames'
import ScheduleWorkingDays from 'components/Pages/Schedule/ScheduleWorkingDays'

const useStyles = makeStyles(() => ({
  cellPaddings: {
    padding: '10px 16px'
  },
  name: {
    marginLeft: 7
  },
  iconPadding: {
    padding: '0'
  }
}))

const DeviceScheduleSimpleTableRow = ({ device, schedule }) => {
  const classes = useStyles()
  const scheduleTypeInfo = schedule?.isSmartSchedule
    ? scheduleConstants.scheduleTypes.SmartSchedule
    : scheduleConstants.scheduleTypes[schedule.scheduleType] ||
      scheduleConstants.scheduleTypes.Timed
  return (
    <TableLibraryRow key={schedule.id} hover tabIndex={-1}>
      <TableLibraryCell className={classNames(classes.cellPaddings)}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <DeviceTypeIconCell
              item={device}
              isPopupDisabled={true}
              isTableLibraryCell={false}
              wrapperClass={classes.iconPadding}
            />
          </Grid>
          <Grid item>
            <TextWithTooltip
              rootClassName={classes.name}
              weight="bold"
              color="title.primary"
              maxWidth={157}
            >
              {device.alias || device.name}
            </TextWithTooltip>
          </Grid>
        </Grid>
      </TableLibraryCell>

      <TableLibraryCell className={classes.cellPaddings}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <LibraryTypeIcon
              color={scheduleTypeInfo.color}
              icon={scheduleTypeInfo.icon}
              iconHelperClass={scheduleTypeInfo.iconHelperClass}
            />
          </Grid>
          <Grid item>
            <TextWithTooltip
              rootClassName={classes.name}
              weight="bold"
              color="title.primary"
              maxWidth={157}
            >
              {checkData(schedule.title)}
            </TextWithTooltip>
          </Grid>
        </Grid>
      </TableLibraryCell>
      <TableLibraryCell className={classes.cellPaddings} align="center">
        <ScheduleWorkingDays workingDays={schedule.workingDays.split(',')} />
      </TableLibraryCell>
      <TableLibraryCell className={classes.cellPaddings} align="center">
        {`${schedule.startTime} - ${schedule.endTime}`}
      </TableLibraryCell>
    </TableLibraryRow>
  )
}

export default DeviceScheduleSimpleTableRow
