import { _isEmpty } from 'utils/lodash'

import { queryParamsHelper } from 'utils/index'
import { status } from 'constants/commonOptions'

export const playlistQueryParamsHelper = params =>
  queryParamsHelper(
    params,
    ['tag', 'group', 'mediaFeatureNames', 'status'],
    [],
    ['playlistType']
  )

export const deviceQueryParamsHelper = params => {
  const location =
    params.location &&
    Array.isArray(params.location) &&
    params.location.map(({ label }) => label)

  return queryParamsHelper(
    {
      ...params,
      ...(!!location && { location }),
      ...(!!params.firmware && { exact: 1 })
    },
    ['group', 'tag', 'status'],
    [],
    ['mediaFeature', 'deviceTypeId']
  )
}

const prepareMediaStatusParams = ({ statusValues, params }) => {
  const allowedStatusValues = statusValues.filter(value =>
    [status.Active, status.Inactive].includes(value)
  )

  if (
    (statusValues.includes(status.Active) &&
      statusValues.includes(status.Inactive)) ||
    !allowedStatusValues.length
  ) {
    params.status = ''
  } else if (allowedStatusValues.length) {
    params.status = allowedStatusValues.join(',')
  }

  if (statusValues.includes(status.Disabled)) {
    params.featureEnabled = '0'
  } else {
    params.featureEnabled = '1'
  }

  if (statusValues.includes(status.Expired)) {
    params.isExpired = '1'
  } else {
    params.isExpired = '0'
  }
}

export const mediaQueryParamsHelper = paramsData => {
  const params = { ...paramsData }

  if (_isEmpty(params.status)) {
    params.featureEnabled = '1'
    params.isExpired = '0'
  }

  if (!!params.status && typeof params.status === 'string') {
    const statusValues = params.status.split(',')

    prepareMediaStatusParams({ statusValues, params })
  }

  if (params.status?.length && Array.isArray(params.status)) {
    const statusValues = params.status.map(({ value }) => value)

    prepareMediaStatusParams({ statusValues, params })
  }

  return queryParamsHelper(
    {
      ...params
    },
    ['tag', 'group'],
    [],
    ['featureId', 'approvedStatus', 'status']
  )
}

export const devicePreviewParamsHelper = params => {
  const location =
    params.location?.length &&
    Array.isArray(params.location) &&
    params.location.map(({ label }) => label)

  return queryParamsHelper({ ...params, ...(!!location && { location }) }, [
    'tag',
    'group'
  ])
}

export const accountLoggingParamsHelper = ({
  userFirstName,
  userLastName,
  ...restParams
}) =>
  queryParamsHelper({
    ...restParams,
    userName: `${userFirstName || ''}${
      userFirstName && userLastName ? ' ' : ''
    }${userLastName || ''}`
  })
