import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import clientPackageSaga from 'sagas/clientPackageSaga'

const actions = [
  types.POST_CLIENT_PACKAGE_SUCCESS,
  types.PUT_CLIENT_PACKAGE_SUCCESS
]

const clientPackageInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, clientPackageSaga.invalidateClientPackageTags)
  )

export default clientPackageInvalidateCache
