export const columns = {
  action: '*action',
  componentId: 'componentId',
  componentType: 'componentType',
  createdAt: 'createdAt',
  title: 'title',
  user: 'user'
}

export const columnsWidth = [
  ...Object.values(columns).map(column => ({ [column]: 150 })),
  { [columns.title]: 300 },
  { [columns.user]: 300 }
]

export const initialColumns = [
  { id: columns.componentId, label: 'Id', align: 'flex-start', display: true },
  { id: columns.title, label: 'Title', align: 'flex-start', display: true },
  { id: columns.componentType, label: 'Type', align: 'center', display: true },
  { id: columns.action, label: 'Action', align: 'center', display: true },
  { id: columns.createdAt, label: 'Date', align: 'center', display: true },
  { id: columns.user, label: 'User', align: 'center', display: true }
]

export const actionTypes = {
  deleted: 'deleted',
  created: 'created',
  updated: 'updated'
}
