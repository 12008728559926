import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  oldName: ''
}

const customParametersSlice = createSlice({
  name: 'customParameters',
  initialState,
  reducers: {
    setOldName: (state, action) => {
      state.oldName = action.payload
    }
  }
})

export const { setOldName } = customParametersSlice.actions

export default customParametersSlice.reducer
