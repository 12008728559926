import { put, call, delay } from 'redux-saga/effects'
import { ipService, userService } from '../services'

import * as types from '../actions'
import { handleUnauthorized } from '../utils'
import { storageClear } from '../utils/localStorage'

function* getDetails() {
  try {
    for (let i = 0; i <= 2; i++) {
      try {
        const response = yield call(userService.getDetails)
        yield put({ type: types.GET_USER_DETAILS_SUCCESS, payload: response })
        return
      } catch (e) {
        if (i === 2) {
          throw e
        }
        delay(1000)
      }
    }
  } catch (error) {
    yield put({ type: types.GET_USER_DETAILS_ERROR, payload: error })
    storageClear()
    handleUnauthorized()
  }
}

function* getOriginalUserDetails() {
  try {
    const response = yield call(userService.getOriginalUserDetails)
    yield put({
      type: types.GET_ORIGINAL_USER_DETAILS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_ORIGINAL_USER_DETAILS_ERROR, payload: error })
  }
}

function* putDetails(action) {
  try {
    yield call(userService.putDetails, action.payload)
    yield put({
      type: types.PUT_USER_DETAILS_SUCCESS,
      payload: action.hideNotification ? {} : { status: 'success' }
    })
    yield put({ type: types.GET_USER_DETAILS })
  } catch (error) {
    yield put({ type: types.PUT_USER_DETAILS_ERROR, payload: error })
  }
}

function* getUserIp() {
  try {
    for (let i = 0; i <= 2; i++) {
      try {
        const response = yield call(ipService.getIp)
        yield put({
          type: types.GET_USER_IP_SUCCESS,
          payload: response
        })
        return
      } catch (e) {
        if (i === 2) {
          throw e
        }
        delay(1000)
      }
    }
  } catch (error) {
    yield put({ type: types.GET_USER_IP_ERROR })
  }
}

function* getProfile() {
  try {
    const response = yield call(userService.getProfile)
    yield put({ type: types.GET_USER_PROFILE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_USER_PROFILE_ERROR, payload: error })
  }
}

function* putRegionalSettings(action) {
  try {
    yield call(userService.putRegionalSettings, action.payload)
    yield put({
      type: types.PUT_REGIONAL_SETTINGS_SUCCESS,
      payload: action.hideNotification ? {} : { status: 'success' }
    })
    yield put({ type: types.GET_USER_DETAILS })
  } catch (error) {
    yield put({ type: types.PUT_REGIONAL_SETTINGS_ERROR, payload: error })
  }
}

function* regeneratePIN({ userId, onSuccess, onError }) {
  try {
    const response = yield call(userService.regeneratePIN, userId)
    if (onSuccess) onSuccess(response?.pinCode)
  } catch (error) {
    if (onError) onError(error)
  }
}

function* postUserBulk({ payload }) {
  try {
    yield call(userService.postUserBulk, payload)
    yield put({
      type: types.POST_USER_BULK_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_USER_BULK_ERROR, payload: error })
  }
}

const userSaga = {
  getDetails,
  putDetails,
  getUserIp,
  getOriginalUserDetails,
  getProfile,
  putRegionalSettings,
  regeneratePIN,
  postUserBulk
}

export default userSaga
