import { takeLatest } from 'redux-saga/effects'

import * as types from 'actions'
import emailNotificationSaga from 'sagas/emailNotificationSaga'

const actions = [
  types.POST_EMAIL_NOTIFICATION_ITEM_SUCCESS,
  types.PUT_EMAIL_NOTIFICATION_ITEM_SUCCESS
]

const emailNotificationsInvalidateCache = () =>
  actions.map(action =>
    takeLatest(action, emailNotificationSaga.emailNotificationsInvalidateCache)
  )

export default emailNotificationsInvalidateCache
