import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment/moment'

import { secToMin, timeOptions } from 'utils/time'
import { getNextRecurringReboot, getTimezone } from 'utils/deviceUtils'
import { MEDIA_DATE_TIME_FORMAT } from 'constants/dateTimeFormats'
import {
  DEFAULT_REBOOT_AFTER_UNIT,
  DEFAULT_REBOOT_AFTER_VALUE
} from 'constants/reboot'
import RebootForm from './RebootForm'
import { allWorkingDays } from 'constants/report'
import { permissionNames } from 'constants/index'
import usePermissions from 'hooks/api/usePermissions'

const DevicePower = ({
  device,
  handleClose,
  rebootReducer,
  getDeviceReboot,
  putDeviceReboot,
  putDevicePeriodicalReboot,
  clearGetDeviceRebootInfo,
  clearPutDeviceRebootInfo,
  putRebootReducer,
  tabs
}) => {
  const { getPermissionByName } = usePermissions()

  const [rebootData, setRebootData] = useState({})
  const [initialRebootValues, setInitialRebootValues] = useState({})

  const deviceRebootPermission = useMemo(
    () => ({
      update: getPermissionByName(
        permissionNames.CLIENT_DEVICE_REBOOT_UPDATE,
        permissionNames.SYSTEM_DEVICE_REBOOT_UPDATE,
        permissionNames.ENTERPRISE_DEVICE_REBOOT_UPDATE
      )
    }),
    [getPermissionByName]
  )

  const handleSubmit = useCallback(
    values => {
      const rebootAction = values.rebootChoice
        ? putDeviceReboot
        : putDevicePeriodicalReboot

      rebootAction({ id: device.id, data: values })
    },
    [device.id, putDeviceReboot, putDevicePeriodicalReboot]
  )

  useEffect(() => {
    if (device?.id) {
      getDeviceReboot(device?.id)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (rebootReducer.response) {
      const { response } = rebootReducer
      const { rebootDetails, periodicalRebootSettings } = response

      let nextReboot = null
      const oneTimeRebootAt = !!rebootDetails.rebootAt
        ? moment(rebootDetails.rebootAt)
        : null

      if (periodicalRebootSettings) {
        const nextRecurringRebootAt = getNextRecurringReboot({
          rebootDays: periodicalRebootSettings.rebootDay,
          rebootTime: periodicalRebootSettings.rebootTime.split(':')
        })

        if (
          oneTimeRebootAt &&
          nextRecurringRebootAt &&
          (oneTimeRebootAt?.isAfter(moment()) ||
            nextRecurringRebootAt?.isAfter(moment()))
        ) {
          nextReboot = oneTimeRebootAt?.isAfter(nextRecurringRebootAt)
            ? nextRecurringRebootAt.format(MEDIA_DATE_TIME_FORMAT)
            : oneTimeRebootAt.format(MEDIA_DATE_TIME_FORMAT)
        } else if (
          oneTimeRebootAt?.isAfter(moment()) &&
          !nextRecurringRebootAt
        ) {
          nextReboot = oneTimeRebootAt.format(MEDIA_DATE_TIME_FORMAT)
        } else if (
          nextRecurringRebootAt?.isAfter(moment()) &&
          !oneTimeRebootAt
        ) {
          nextReboot = nextRecurringRebootAt.format(MEDIA_DATE_TIME_FORMAT)
        }
      } else if (oneTimeRebootAt && oneTimeRebootAt?.isAfter(moment())) {
        nextReboot = oneTimeRebootAt.format(MEDIA_DATE_TIME_FORMAT)
      }

      setRebootData({
        ...response,
        currentDeviceTime: moment().utcOffset(getTimezone(device)),
        nextReboot
      })

      const newFormValues = {
        ...rebootDetails,
        rebootAt: rebootDetails.rebootAt
          ? moment(`${rebootDetails.rebootAt}`, MEDIA_DATE_TIME_FORMAT).format(
              MEDIA_DATE_TIME_FORMAT
            )
          : moment().format(MEDIA_DATE_TIME_FORMAT),
        rebootAfterUnit: DEFAULT_REBOOT_AFTER_UNIT,
        rebootAfterValue:
          secToMin(rebootDetails.rebootAfterValue) ||
          secToMin(DEFAULT_REBOOT_AFTER_VALUE),
        rebootEveryValue:
          periodicalRebootSettings?.rebootTime ||
          // nosemgrep
          timeOptions[Math.floor(Math.random() * timeOptions.length)].value,
        rebootWorkingDays:
          periodicalRebootSettings?.rebootDay.split('|') || allWorkingDays
      }

      setInitialRebootValues(newFormValues)
    } else if (rebootReducer.error) {
      clearGetDeviceRebootInfo()
    }
    // eslint-disable-next-line
  }, [rebootReducer])

  return (
    <RebootForm
      handleClose={handleClose}
      data={rebootData}
      initialRebootValues={initialRebootValues}
      disabled={!deviceRebootPermission.update}
      device={device}
      namePrefix="reboot"
      detailsPath="rebootDetails"
      onSubmit={handleSubmit}
      onClear={clearPutDeviceRebootInfo}
      putReducer={putRebootReducer}
      tabs={tabs}
      backendData={rebootReducer.response}
    />
  )
}

export default DevicePower
